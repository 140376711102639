import { FluidObject } from "gatsby-image"
import buildImageUrl from "./imageUrl"
import forceHttps from "./forceHttps"

export const DEFAULT_FLUID_MAX_WIDTH = 800

const sizeMultipliersFluid = [0.25, 0.5, 1, 1.5, 2, 3]

interface FluidArgs {
  maxWidth?: number
  maxHeight?: number
  aspectRatio?: number
  sizes?: string
  quality?: number
}

export function getFluidGatsbyImage(
  url: string,
  args: FluidArgs
): FluidObject | null {
  if (!url) return null

  const { maxHeight = 0, aspectRatio, quality } = args
  const maxWidth = args.maxWidth || DEFAULT_FLUID_MAX_WIDTH

  const defaultSizes = `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`

  // Apply size multipliers to max width; create a list of strings w/ URLs & width
  // descriptors to be used for the image's srcSet
  const sources = sizeMultipliersFluid.map((scale) => {
    const width = Math.round(maxWidth * scale)
    let height = null

    if (maxHeight) height = Math.round(maxHeight * scale)
    if (aspectRatio) height = Math.round(width / aspectRatio)
    const sizeUrl = buildImageUrl(url, {
      width,
      ...(height && { height }),
      ...(quality && { quality })
    })

    return `${sizeUrl} ${width}w`
  })

  // Use maxWidth and maxHeight as the size for the main (fallback) src
  const urlBuilderParams = {
    width: maxWidth,
    ...(maxHeight && { height: maxHeight }),
    ...(quality && { quality })
  }

  // Set main src height based on desired aspect ratio if provided
  if (aspectRatio) {
    urlBuilderParams.height = Math.round(maxWidth / aspectRatio)
  }

  const src = buildImageUrl(url, urlBuilderParams)

  // Failure to build URL here means none of the srcSet URLs were successfully built
  // either, so return null since there's nothing meaningful to output
  if (!src) return null

  // Default aspect ratio if one isn't specified; try to calculate using provided
  // maxHeight argument; assume 1:1 as last resort (aspect ratio is required in
  // Gatsby Image props)
  const calculatedAspectRatio = maxHeight ? maxWidth / maxHeight : 1

  return {
    aspectRatio: aspectRatio || calculatedAspectRatio,
    src,
    srcSet: sources.join(",\n"),
    sizes: args.sizes || defaultSizes
  }
}

export const forceHttpsGatsbyImage = (image: FluidObject): FluidObject => {
  const src = forceHttps(image.src)
  const srcSet = forceHttps(image.srcSet)

  return {
    ...image,
    src,
    srcSet
  }
}
