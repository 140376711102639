export type LocaleCode = "en-US" | string

export type Locale = {
  path: string
  locale: string
  default?: boolean
}

export type Locales = Record<LocaleCode, Locale>

export const locales: Locales = {
  "en-US": {
    path: "",
    locale: "English",
    default: true
  }
}
