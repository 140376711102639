import React, {
  createRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
  PropsWithChildren
} from "react"
import classNames from "classnames"
import { PlaylistItem } from "../../types/playlist-item"
import { List, ListItemVideo } from "../list/list"
import { Icon } from "../icon/icon"
import { Button } from "../button/button"
import styles from "./playlist.module.scss"

export interface PlaylistProps<T> {
  className?: string
  initialPlayingItemId: string
  items: PlaylistItem<T>[]
  onItemClick: (id: string) => void
  progress?: number
  playlistTitleOnMobile: string
}

export function Playlist<T>({
  className,
  initialPlayingItemId,
  items,
  onItemClick,
  progress,
  playlistTitleOnMobile
}: PropsWithChildren<PlaylistProps<T>>): JSX.Element {
  const itemRefs = useMemo(
    () =>
      Array.from({ length: items.length }).map(() =>
        createRef<HTMLDivElement>()
      ),
    [items.length]
  )

  const [playingItemId, setPlayingItemId] =
    useState<string>(initialPlayingItemId)

  const [isPristine, setPristine] = useState<boolean>(true)

  const [isMobilePlaylistOpen, setMobilePlaylistOpen] = useState<boolean>(false)

  const playingItemIndex = useMemo(
    () => items.findIndex(({ mediaId }) => mediaId === playingItemId),
    [items, playingItemId]
  )

  const scrollToCurrentItem = useCallback(() => {
    const currentItem = itemRefs[playingItemIndex].current

    currentItem?.scrollIntoView?.({ block: "center" })
  }, [itemRefs, playingItemIndex])

  // Scroll the playlist to the initially-selected item on page load
  useEffect(() => {
    scrollToCurrentItem()
    window.scroll(0, 0)
  }, [scrollToCurrentItem])

  useEffect(() => {
    if (!isPristine) {
      onItemClick?.(playingItemId)
      setMobilePlaylistOpen(false)
    }
  }, [isPristine, playingItemId, onItemClick])

  useEffect(() => {
    if (isPristine && playingItemId !== initialPlayingItemId) {
      setPristine(false)
    }
  }, [initialPlayingItemId, isPristine, playingItemId])

  // Scroll to currently-selected item when mobile menu is opened
  useEffect(() => {
    if (isMobilePlaylistOpen) scrollToCurrentItem()
  }, [isMobilePlaylistOpen, scrollToCurrentItem])

  const handleMobileButtonClick = useCallback(() => {
    setMobilePlaylistOpen(!isMobilePlaylistOpen)
  }, [isMobilePlaylistOpen])

  return (
    <div className={classNames(styles.playlist, className)}>
      <div
        className={styles.mobileOpenButton}
        onClick={handleMobileButtonClick}
      >
        <div className={styles.inner}>
          <span>
            Viewing {playingItemIndex + 1} of {items.length}
          </span>
          <Icon variant="16-right-arrow" />
        </div>
      </div>
      <div
        className={classNames(
          styles.wrapper,
          isMobilePlaylistOpen && styles.mobilePlaylistOpen
        )}
      >
        <div className={styles.header}>
          <div className={styles.inner}>
            <div className={styles.headerText}>
              <span>{playlistTitleOnMobile}</span>
              <span className={styles.itemCount}>
                {playingItemIndex + 1} / {items.length}
              </span>
            </div>
            <Button icon="16-cross" onClick={handleMobileButtonClick} />
          </div>
        </div>
        <List variant="teaching-series" className={styles.content} size="fluid">
          {items.map(({ mediaId, slug, ligCode, ...itemProps }, index) => (
            <ListItemVideo
              key={`${index}-${slug}`}
              id={mediaId || ""}
              ligCode={ligCode}
              isPlaying={mediaId === playingItemId}
              onClick={setPlayingItemId}
              progress={progress}
              ref={itemRefs[index]}
              itemNumber={index + 1}
              isFirstItem={mediaId === items[0].mediaId}
              {...itemProps}
            />
          ))}
        </List>
      </div>
    </div>
  )
}
