import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import classNames from "classnames"
import { externalLink } from "../../core/constants"
import { Icon } from "../icon/icon"
import { Link } from "../link/link"
import { legalLinks, socialLinks } from "./footer-constants"
import styles from "./footer.module.scss"

export const FooterTertiary: FunctionComponent = () => (
  <div className={styles.tertiary}>
    <p className={styles.employment}>
      <FormattedMessage
        id="footer-join-cta"
        values={{
          link: (
            <Link to="https://careers.ligonier.org">
              <FormattedMessage id="footer-employment-opportunities" />
            </Link>
          )
        }}
      />
    </p>
    <div className={styles.socialLinks}>
      {socialLinks.map(({ text, icon, url, key }) => (
        <a
          className={styles.socialLink}
          href={url}
          title={text}
          key={key}
          {...externalLink}
        >
          <Icon className={styles.socialIcon} variant={icon} />
        </a>
      ))}
    </div>
    <ul className={classNames(styles.list, styles.legalLinks)}>
      {legalLinks.map(({ url, key }) => (
        <li className={styles.legalItem} key={key}>
          <Link className={styles.legalLink} to={url}>
            <FormattedMessage id={key} />
          </Link>
        </li>
      ))}
    </ul>
    <div className={styles.copyright}>
      &copy;{new Date().getFullYear()} Ligonier Ministries
    </div>
  </div>
)
