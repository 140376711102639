import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react"
import classNames from "classnames"
import styles from "./tint.module.scss"

const fadeDuration = parseInt(styles.fadeDuration, 10)

export interface TintProps {
  className?: string
  defaultIsVisible?: boolean
  onClick?: () => void
  onHide?: () => void
  onShow?: () => void
  dismissable?: boolean
}

export const Tint = forwardRef<HTMLDivElement, TintProps>(
  (
    {
      className,
      defaultIsVisible = false,
      dismissable = true,
      onClick,
      onHide,
      onShow
    },
    forwardedRef
  ) => {
    const [isVisible, setIsVisible] = useState<boolean>(defaultIsVisible)
    const showTimeout = useRef(0)
    const hideTimeout = useRef(0)
    const handleClick = useCallback(() => {
      if (!dismissable) return
      setIsVisible(false)
      onClick?.()

      if (onHide) {
        hideTimeout.current = window.setTimeout(onHide, fadeDuration)
      }
    }, [onClick, onHide, dismissable])

    useEffect(() => {
      if (isVisible) {
        onShow?.()

        return (): void => {
          clearTimeout(hideTimeout.current)
        }
      }

      setIsVisible(true)

      if (onShow) {
        showTimeout.current = window.setTimeout(onShow, fadeDuration)

        return (): void => {
          clearTimeout(showTimeout.current)
          clearTimeout(hideTimeout.current)
        }
      }

      return (): void => {
        clearTimeout(hideTimeout.current)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onShow])

    return (
      <div
        className={classNames(
          styles.tint,
          className,
          isVisible && styles.visible
        )}
        onClick={handleClick}
        ref={forwardedRef}
      />
    )
  }
)

Tint.displayName = "Tint"
