import React, { FunctionComponent, useMemo } from "react"
import { FormattedMessage } from "react-intl"
import { Teacher } from "../../../../types/teacher"
import { PathPrefix } from "../../../../core/constants"
import { Link } from "../../../../components/link/link"
import styles from "./teaching-serie-header.module.scss"

interface TeachingSerieTeachersProps {
  teachers: Teacher[]
}

const fewLimit = 3

export const TeachingSerieTeachers: FunctionComponent<
  TeachingSerieTeachersProps
> = ({ teachers }) => {
  const teacherCount = teachers.length
  const othersCount = teacherCount - (fewLimit - 1)

  const teachersWithLinks = useMemo(
    () =>
      teachers.map((teacher) => (
        <Link
          key={teacher.slug}
          to={`${PathPrefix.Teachers}/${teacher.slug}`}
          className={styles.teacherLink}
        >
          {teacher.name}
        </Link>
      )),
    [teachers]
  )

  const showAllTeachers = teacherCount <= fewLimit
  const messageId = showAllTeachers
    ? "teaching-series-author-few"
    : "teaching-series-author-many"
  const teachersList = showAllTeachers
    ? teachersWithLinks
    : teachersWithLinks.slice(0, fewLimit - 1)

  return (
    <FormattedMessage
      id={messageId}
      values={{
        teachers: teachersList,
        othersCount
      }}
    />
  )
}
