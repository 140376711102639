import React, {
  forwardRef,
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react"
import { createPortal } from "react-dom"
import classNames from "classnames"
import { ModalOverlay } from "./modal-overlay"
import { ModalBody } from "./modal-body"
import styles from "./modal.module.scss"

const fadeDuration = parseInt(styles.fadeDuration, 10)

export type ModalPosition = {
  x: number
  y: number
}

export interface ModalProps {
  className?: string
  onClose?: () => void
  dismissable?: boolean
  position?: ModalPosition
  withTint?: boolean
  fullscreenMobile?: boolean
  overlayClassName?: string
}

export const Modal = forwardRef<HTMLDivElement, PropsWithChildren<ModalProps>>(
  (
    {
      className,
      children,
      onClose,
      dismissable = true,
      fullscreenMobile = true,
      position,
      withTint = true,
      overlayClassName
    },
    forwardedRef
  ) => {
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const tintRef = useRef<HTMLDivElement>(null)
    const hideTimeout = useRef(0)

    const handleTintHide = useCallback(() => {
      hideTimeout.current = window.setTimeout(() => onClose?.(), fadeDuration)
    }, [onClose])

    const handleModalClose = useCallback(() => {
      if (!dismissable) return
      setIsVisible(false)

      if (tintRef.current) {
        tintRef.current.click()
      } else {
        hideTimeout.current = window.setTimeout(() => onClose?.(), fadeDuration)
      }
    }, [onClose, dismissable])

    const handleTintClick = useCallback(() => {
      setIsVisible(false)
    }, [])

    const handleOverlayClick = useCallback(() => {
      if (!dismissable) return

      if (!withTint) {
        setIsVisible(false)
        hideTimeout.current = window.setTimeout(() => onClose?.(), fadeDuration)
      }
    }, [onClose, withTint, dismissable])

    useEffect(() => {
      setIsVisible(true)
    }, [])

    const usePortal = !position
    const newOverlayClassName = position && styles.positioned

    return usePortal ? (
      createPortal(
        <ModalOverlay
          className={overlayClassName}
          onClick={handleOverlayClick}
          ref={tintRef}
          withTint={withTint}
          onTintClick={handleTintClick}
          onTintHide={handleTintHide}
          dismissable={dismissable}
          fullscreenMobile={fullscreenMobile}
        >
          <ModalBody
            className={className}
            isVisible={isVisible}
            ref={forwardedRef}
            onClose={handleModalClose}
            position={position}
            dismissable={dismissable}
            fullscreenMobile={fullscreenMobile}
          >
            {children}
          </ModalBody>
        </ModalOverlay>,
        document.body
      )
    ) : (
      <Fragment>
        <ModalOverlay
          className={classNames(newOverlayClassName, overlayClassName)}
          onClick={handleOverlayClick}
          ref={tintRef}
          withTint={withTint}
          onTintClick={handleTintClick}
          onTintHide={handleTintHide}
          dismissable={dismissable}
        />
        <ModalBody
          className={className}
          isVisible={isVisible}
          ref={forwardedRef}
          onClose={handleModalClose}
          position={position}
          dismissable={dismissable}
        >
          {children}
        </ModalBody>
      </Fragment>
    )
  }
)

Modal.displayName = "Modal"
