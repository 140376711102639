import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { Icon } from "../icon/icon"
import { AvatarList, AvatarListItemProps } from "../avatar/avatar-list"
import { ListItem, ListItemProps } from "./list-item"
import styles from "./list.module.scss"

export interface ListItemConferenceProps extends ListItemProps {
  date: string
  image?: string
  title: string
  speakers: AvatarListItemProps[]
  onClick?: MouseEventHandler
}

export const ListItemConference: FunctionComponent<ListItemConferenceProps> = ({
  className,
  date,
  disabled = false,
  image,
  speakers,
  title,
  url,
  onClick
}) => (
  <ListItem
    className={classNames(styles.listItemConference, className)}
    disabled={disabled}
    url={url}
    onClick={onClick}
  >
    <div className={styles.content}>
      <div className={styles.subheader}>
        <Icon variant="12-calendar" className={styles.icon} />
        <span className={styles.date}>{date}</span>
      </div>
      <h2 className={styles.header}>{title}</h2>
      <div className={styles.subheader}>
        <AvatarList avatars={speakers} size="xs" limit={5} />
      </div>
    </div>
    <div className={styles.side}>
      <div
        className={classNames(styles.image, !image && styles.placeholder)}
        style={{
          backgroundImage: `url(${
            image || "/assets/image-placeholders/list-logo.svg"
          })`
        }}
      />
    </div>
  </ListItem>
)
