import { useQuery } from "react-query"
import recombee from "recombee-js-api-client/dist/recombee-api-client"
import { nanoid } from "nanoid"
import { RelatedResources } from "@src-types/resource"
import { ContentfulSeriesType } from "@src-types/graphql"

const client = new recombee.ApiClient(
  process.env.GATSBY_RECOMBEE_PRODUCT_DATABASE,
  process.env.GATSBY_RECOMBEE_ACCESS_TOKEN
)

type Maybe<T> = T | null | undefined

export type RecombeeResource =
  | "article"
  | "collection"
  | "devotional"
  | "event"
  | "podcastEpisode"
  | "podcastShow"
  | "post"
  | "product"
  | "question"
  | "series"
  | "sermon"
  | "teacher"
  | "mediaResource"
  | "guide"

interface RecombeeArgs {
  itemId?: string | null
  idsToRemove?: Array<string>
  userId?: string | null
  count?: number
}

interface ArgsDetail {
  userId: string | null
  itemId: string | null
}

type RecommendationType = "items-to-users" | "items-to-items"

type RecombeeLogic =
  | "recombee:default"
  | "recombee:homepage"
  | "recombee:personal"
  | "recombee:popular"
  | "recombee:recently-viewed"

export type RecommendationScenario =
  | "More-From-This-Topic"
  | "More-From-This-Teacher"
  | "Additional-Featured-Products-Recommendations"
  | "Recently-Viewed-Products"

type RecommendationsKey<T> = { [P in RecommendationScenario]?: T }

type FilterType = RecommendationsKey<string | undefined>

type LogicType = RecommendationsKey<RecombeeLogic>

export interface QueryArgs {
  returnProperties: boolean
  filter?: string
  logic: RecombeeLogic
  scenario: RecommendationScenario
}

interface RecombeeItem {
  productHandle: string
  featuredImage: string
  title: string
}

interface RecentProduct {
  id: string
  handle: string
  image: string
  title: string
}

export interface RecombeeValues {
  articleType: Maybe<string>
  authors: Array<Maybe<string>>
  availableForSale: boolean | null
  category: Maybe<string>
  collectionHandle: Maybe<string>
  compareAtPrice: Maybe<string>
  entryTitle: Maybe<string>
  externalUrl: Maybe<string>
  featuredImage: Maybe<string>
  format: Maybe<string>
  handle: Maybe<string>
  image: Maybe<string>
  price: Maybe<string>
  primaryAuthor: Maybe<string>
  primaryAuthorImage: Maybe<string>
  primaryScriptureReferenceBook: Maybe<string>
  primaryScriptureReferenceChapter: Maybe<string>
  primaryTopic: Maybe<string>
  productHandle: Maybe<string>
  productVariantId: Maybe<string>
  seriesHandle: Maybe<string>
  seriesType: ContentfulSeriesType | undefined
  teacherNames: Array<Maybe<string>>
  teachers: Array<Maybe<string>>
  title: Maybe<string>
  type: RecombeeResource | "product"
}

export type RecombeeRelatedResource = RelatedResources & {
  content?: RecombeeResponse
}

export interface RecombeeRecommendations {
  values: RecombeeValues
  id: string
}

export interface RecombeeResponse {
  recomms: RecombeeRecommendations[]
}

export function useRecombeeRecentlyViewed(args: RecombeeArgs): any {
  const queryRes = useQuery<any, Error>(
    ["recombeeRecentlyViewed", args.userId],
    // () => fetchRecentProducts(args),
    () => fetchRecommendations("Recently-Viewed-Products", args),
    {
      staleTime: 0
    }
  )

  if (queryRes.isLoading) {
    return { isLoading: true }
  }

  if (queryRes.error) {
    return { error: queryRes.error }
  }

  const products =
    queryRes?.data?.recomms?.reduce(
      (
        acc: Array<RecentProduct>,
        { values, id }: { id: string; values: RecombeeItem }
      ) => {
        if (values.productHandle && values.featuredImage) {
          return [
            ...acc,
            {
              id,
              handle: values.productHandle,
              image: values.featuredImage,
              title: values.title
            }
          ]
        }

        return acc
      },
      []
    ) || []

  return { data: products }
}

const queryRecombee = async (
  recommendationType: RecommendationType,
  args: RecombeeArgs,
  scenario: RecommendationScenario,
  filter?: string,
  logic: RecombeeLogic = "recombee:default"
): Promise<Pick<RecombeeResponse, "recomms">> => {
  const { itemId, userId, count = 3 } = args
  let res: RecombeeResponse

  let queryArgs: QueryArgs = {
    returnProperties: true,
    logic,
    scenario
  }

  if (filter) {
    queryArgs = { filter, ...queryArgs }
  }

  try {
    switch (recommendationType) {
      case "items-to-items":
        res = await client.send(
          new recombee.RecommendItemsToItem(itemId, userId, count, queryArgs)
        )
        break

      case "items-to-users":
        res = await client.send(
          new recombee.RecommendItemsToUser(userId, count, queryArgs)
        )
        break
    }

    return res
  } catch (e) {
    throw new Error(e)
  }
}

const defaultFitler = [
  `('showInSearch' == null )`,
  `('showInSearch' == true )`,
  `('availableForSale' == null )`,
  `('availableForSale' == true )`
].join(" or ")

const recombeeFilters: FilterType = {
  "More-From-This-Teacher": [
    `('primaryAuthor' == context_item["primaryAuthor"])`,
    `('primaryAuthor' != null )`,
    `('type' != "product")`
  ].join(" and "),

  "More-From-This-Topic": [
    `('primaryTopic' == context_item["primaryTopic"])`,
    `('primaryTopic' != null )`,
    `('type' != "product")`
  ].join(" and "),

  "Additional-Featured-Products-Recommendations": [
    `('type' == "product")`
  ].join(" and ")
}

const recombeeLogic: LogicType = {
  "Recently-Viewed-Products": "recombee:recently-viewed"
}

export const fetchRecommendations = async (
  scenario: RecommendationScenario,
  args: RecombeeArgs,
  debug = false
): Promise<RecombeeResponse | null> => {
  let { userId } = args
  const { idsToRemove } = args

  const filter =
    `(${defaultFitler})` +
    (recombeeFilters[scenario] ? ` and  (${recombeeFilters[scenario]})` : "") +
    (idsToRemove
      ? ` and ('itemId' not in {"${idsToRemove.join('" , "')}"})`
      : "")

  const logic = recombeeLogic[scenario] || "recombee:default"

  if (typeof window === "undefined") {
    return null
  }

  if (!userId) {
    const localStorageKey = "recombee:anonUserId"
    const localStorageAnonomousId = localStorage.getItem(localStorageKey)

    if (localStorageAnonomousId) {
      userId = localStorageAnonomousId
    } else {
      const anonomousIdValue = nanoid()

      localStorage.setItem(localStorageKey, anonomousIdValue)

      userId = anonomousIdValue
    }
  }

  if (debug) {
    console.log(
      "recommendation: ",
      `
        scenario: ${scenario}
        args: ${JSON.stringify(args)}
        filter: ${JSON.stringify(recombeeFilters[scenario])}
        logic: ${JSON.stringify(recombeeLogic[scenario] || "recombee:default")}
      `
    )
  }

  if (!scenario || !args) {
    return null
  }

  const makeQuery = async (
    recommendationType: RecommendationType,
    scenario: RecommendationScenario
  ) =>
    await queryRecombee(
      recommendationType,
      { ...args, userId },
      scenario,
      filter,
      logic
    )

  switch (scenario) {
    case "More-From-This-Topic":
    case "More-From-This-Teacher":
      return await makeQuery("items-to-items", scenario)

    case "Additional-Featured-Products-Recommendations":
    case "Recently-Viewed-Products":
      return await makeQuery("items-to-users", scenario)

    default:
      return null
  }
}

//break this out into a util function. Isn't a hook really.
export const addDetailView = async (args: ArgsDetail) => {
  const { userId, itemId } = args

  try {
    if (!userId || !itemId) return null

    const res = await client.send(new recombee.AddDetailView(userId, itemId))

    return res
  } catch (e) {
    throw new Error(e)
  }
}
