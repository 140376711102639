import React, { FunctionComponent } from "react"
import { useIntl } from "react-intl"
import classNames from "classnames"
import styles from "./callouts.module.scss"
import EmailSignUp from "@components/EmailSignUp"
import { Icon } from "@components/icon/icon"

export * from "./callout"

export type CalloutsVariant = "cream" | "frost"

export interface CalloutsProps {
  className?: string
  variant?: CalloutsVariant
}

export const Callouts: FunctionComponent<CalloutsProps> = ({
  className,
  variant = "frost"
}) => {
  const { formatMessage } = useIntl()

  return (
    <div className={classNames(styles.callouts, styles[variant], className)}>
      <section className={classNames(styles.callout, className)}>
        <div className={styles.iconContainer}>
          <Icon className={styles.icon} variant={`24-mail`} />
          <h3 className={styles.heading}>
            {formatMessage({ id: "callouts-newsletter-title" })}
          </h3>
        </div>
        <EmailSignUp className={styles.emailSignUp} />
      </section>
    </div>
  )
}
