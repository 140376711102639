import React, { FunctionComponent } from "react"
import { NavigationSection } from "../../features/navigation/navigation-types"
import { Link } from "../link/link"
import { FooterGroup, FooterGroupRow } from "./footer-group"
import { footerSectionAdditions } from "./footer-section-additions"
import styles from "./footer.module.scss"

interface FooterGroupsProps {
  sections: NavigationSection[]
}

const phonePrefix = "tel:"
const phoneSeparator = ": "

export const FooterGroups: FunctionComponent<FooterGroupsProps> = ({
  sections
}) => (
  <div className={styles.groups}>
    {sections.map((section) => {
      const AdditionalSectionComponent = footerSectionAdditions[section.name]

      return (
        <FooterGroup key={section.name} title={section.name}>
          {section.links.map((link) => {
            const prefix = link.url.includes(phonePrefix)
              ? link.name.split(phoneSeparator)[0] + phoneSeparator
              : undefined

            return (
              <FooterGroupRow key={link.name}>
                {prefix && <span className={styles.prefix}>{prefix}</span>}
                <Link
                  className={styles.textLink}
                  target={"_self"}
                  to={link.url}
                >
                  {link.meta && <div className={styles.meta}>{link.meta}</div>}
                  {prefix ? link.name.replace(prefix, "") : link.name}
                </Link>
              </FooterGroupRow>
            )
          })}
          {AdditionalSectionComponent && <AdditionalSectionComponent />}
        </FooterGroup>
      )
    })}
  </div>
)
