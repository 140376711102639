import React, { FunctionComponent, useContext } from "react"
import classNames from "classnames"
import { useSelector } from "@xstate/react"
import { NavigationSection } from "../../features/navigation/navigation-types"
import { Callouts } from "../callouts/callouts"
import { BreadcrumbResource } from "../breadcrumbs/breadcrumbs"
import { FooterTertiary } from "./footer-tertiary"
import { FooterGroups } from "./footer-groups"
import { FooterLanguages } from "./footer-languages"
import styles from "./footer.module.scss"
import { FooterBreadcrumbs } from "./footer-breadcrumbs"
import FooterMinistryPartner from "./footer-ministry-partner"
import { ContentfulSubfooter, Maybe } from "graphql/types"
import { AuthContext } from "@contexts/authContext"
import { CalloutsMP } from "@components/callouts-mp/callouts-mp"

export interface FooterProps {
  basePath?: string
  breadcrumbs?: BreadcrumbResource[]
  className?: string
  sections: NavigationSection[]
  subfooter?: Maybe<ContentfulSubfooter>
}

export const Footer: FunctionComponent<FooterProps> = ({
  basePath,
  breadcrumbs,
  className,
  sections,
  subfooter
}) => {
  const { service: authMachine } = useContext(AuthContext)
  const isMinistryPartner = useSelector(authMachine, ({ context }) => {
    return context.isMinistryPartner
  })

  return (
    <footer className={classNames(styles.footer, className)}>
      {subfooter && !isMinistryPartner && (
        <FooterMinistryPartner subfooter={subfooter} />
      )}
      {isMinistryPartner ? (
        <CalloutsMP
          heading={subfooter?.partnerCalloutHeading || ""}
          subheading={subfooter?.partnerCalloutSubheading || ""}
          buttonText={subfooter?.partnerCalloutButtonText || ""}
          link={subfooter?.partnerCalloutButtonLink || ""}
        />
      ) : (
        <Callouts />
      )}
      {breadcrumbs && <FooterBreadcrumbs breadcrumbs={breadcrumbs} />}
      <FooterGroups sections={sections} />
      <FooterLanguages basePath={basePath} />
      <div className={styles.divider} />
      <FooterTertiary />
    </footer>
  )
}
