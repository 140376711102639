import React from "react"
import classNames from "classnames"
import Image, { FluidObject } from "gatsby-image"
import BaseLayout from "./base-layout"
import styles from "./year-end-video.module.scss"
import commonStyles from "./common-styles.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface YearEndVideoProps {
  headline: string
  buttonText: string
  buttonLink: string
  imageFluid: FluidObject
}

export default function YearEndVideo(props: YearEndVideoProps) {
  const { headline, buttonText, buttonLink, imageFluid } = props

  const reactMarkdownProps = {
    disallowedElements: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
    unwrapDisallowed: true
  }

  return (
    <BaseLayout
      className={classNames(
        commonStyles.largeWrapper,
        commonStyles.extraLargeWrapper,
        styles.container
      )}
      infoClassName={commonStyles.largeInfo}
      mediaClassName={commonStyles.largeMediaWrapper}
      imageShouldLink
      image={
        <Image
          fluid={{ ...imageFluid, aspectRatio: 480 / 270 }}
          className={styles.image}
        />
      }
      headline={
        <h2 className={commonStyles.title}>
          <Markdown content={headline} {...reactMarkdownProps} />
        </h2>
      }
      buttonLink={buttonLink}
      buttonText={buttonText}
    />
  )
}
