import { useContext } from "react"
import { SearchContext } from "../search-context"
import { defaultSearchState } from "../search-constants"

interface UseSearch extends SearchContext {
  resetSearchState(): void
}

//this hook is not used on search-panel.tsx. ClearRefinements from "react-instantsearch-dom"
//is used instead.
export const useSearch = (): UseSearch => {
  const context = useContext(SearchContext)
  const resetSearchState = (): void =>
    context.setSearchState(defaultSearchState)

  return {
    ...context,
    resetSearchState
  }
}
