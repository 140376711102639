import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { Button } from "../button/button"
import { donateUrl } from "@core/constants"

export interface HeaderDonateProps {
  className?: string
}

export const HeaderDonate: FunctionComponent<HeaderDonateProps> = ({
  className
}) => (
  <Button
    className={className}
    to={`${donateUrl}?utm_medium=donate+button&utm_source=ligonier`}
    variant="secondary"
    size="sm"
  >
    <FormattedMessage id="header-donate" />
  </Button>
)
