export const isFridayEST = (): boolean => {
  const offset = -5 // EST is GMT-5
  const currentDate = new Date()
  const utc = currentDate.getTime() + currentDate.getTimezoneOffset() * 60000
  const est = new Date(utc + 3600000 * offset)

  const estDate = est.toDateString()

  return estDate.startsWith("Fri")
}
