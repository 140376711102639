import React, {
  FunctionComponent,
  MouseEventHandler,
  useState,
  useCallback
} from "react"
import classNames from "classnames"
import { Icon } from "../icon/icon"
import { Link } from "../link/link"
import styles from "./menu.module.scss"

export interface MenuGroupProps {
  className?: string
  defaultOpen?: boolean
  title: string
  url?: string
  onClickLink?: MouseEventHandler<HTMLAnchorElement>
}

export const MenuGroup: FunctionComponent<MenuGroupProps> = ({
  children,
  className,
  defaultOpen = false,
  onClickLink,
  title,
  url
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  return (
    <section
      className={classNames(styles.group, !isOpen && styles.close, className)}
    >
      <header className={styles.header} onClick={toggleOpen}>
        <div className={styles.heading}>
          {url ? (
            <Link
              className={styles.headingDesktop}
              to={url}
              onClick={onClickLink}
            >
              {title}
            </Link>
          ) : (
            <span className={styles.headingDesktop}>{title}</span>
          )}
          <span className={styles.headingMobile}>{title}</span>
        </div>
        <Icon className={styles.arrow} variant="16-right-arrow" />
      </header>
      <div className={styles.content}>{children}</div>
    </section>
  )
}

export interface MenuGroupRowProps {
  className?: string
  divider?: boolean
}

export const MenuGroupRow: FunctionComponent<MenuGroupRowProps> = ({
  children,
  className,
  divider
}) => (
  <div className={classNames(styles.row, divider && styles.divider, className)}>
    {children}
  </div>
)
