import classNames from "classnames"
import React from "react"
import { FluidObject } from "gatsby-image"
import BaseLayout from "./base-layout"
import commonStyles from "./common-styles.module.scss"
import styles from "./year-end-simple.module.scss"
import { Markdown } from "@components/markdown/markdown"
import PolaroidCard from "@components/PolaroidCard/polaroid-card"

export interface YearEndSimpleProps {
  headline: string
  subhead?: string
  buttonText: string
  buttonLink: string
  imageFluid: FluidObject
}

export default function YearEndSimple(props: YearEndSimpleProps) {
  const { headline, subhead, buttonText, buttonLink, imageFluid } = props

  const reactMarkdownProps = {
    disallowedElements: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
    unwrapDisallowed: true
  }

  return (
    <BaseLayout
      className={classNames(commonStyles.largeWrapper, styles.container)}
      infoClassName={commonStyles.largeInfo}
      mediaClassName={commonStyles.largeMediaWrapper}
      image={
        imageFluid && (
          <PolaroidCard image={imageFluid} className={styles.polariodImage} />
        )
      }
      headline={
        <h2 className={classNames(commonStyles.title)}>
          <Markdown content={headline} {...reactMarkdownProps} />
        </h2>
      }
      subhead={
        subhead ? (
          <h3 className={commonStyles.subtitle}>
            <Markdown content={subhead} {...reactMarkdownProps} />
          </h3>
        ) : undefined
      }
      buttonLink={buttonLink}
      buttonText={buttonText}
    />
  )
}
