import React, { FunctionComponent } from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import remarkGfm from "remark-gfm"
import remarkBreaks from "remark-breaks"
import { replaceYoutubeIframe } from "@utils/cheerio/replaceWithLiteYoutube"

interface MarkdownProps {
  content: string
  className?: string
}

export const Markdown: FunctionComponent<MarkdownProps> = ({
  className,
  content = "",
  ...reactMarkdownProps
}) => {
  const parsedContent = replaceYoutubeIframe(content)

  return (
    <ReactMarkdown
      className={className}
      rehypePlugins={[rehypeRaw as any]}
      remarkPlugins={[remarkGfm as any, remarkBreaks]}
      {...reactMarkdownProps}
    >
      {parsedContent}
    </ReactMarkdown>
  )
}
