import React from "react"
import classNames from "classnames"
import { FluidObject } from "gatsby-image"
import BaseLayout from "./base-layout"
import styles from "./year-end-quote.module.scss"
import commonStyles from "./common-styles.module.scss"
import PolaroidCard from "@components/PolaroidCard/polaroid-card"
import { Markdown } from "@components/markdown/markdown"

export interface YearEndQuoteProps {
  quoteText: string
  quoteAttribution: string
  subhead: string
  buttonText: string
  buttonLink: string
  imageFluid: FluidObject
}

export default function YearEndQuote(props: YearEndQuoteProps) {
  const {
    quoteText,
    quoteAttribution,
    buttonText,
    buttonLink,
    imageFluid,
    subhead
  } = props

  const reactMarkdownProps = {
    disallowedElements: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
    unwrapDisallowed: true
  }

  return (
    <BaseLayout
      className={classNames(
        commonStyles.largeWrapper,
        styles.container,
        !imageFluid && styles.containerWOImage
      )}
      headerClassName={classNames(
        !subhead && styles.headerWOSubhead,
        !imageFluid && styles.headerWOImage
      )}
      infoClassName={classNames(
        commonStyles.largeInfo,
        !imageFluid && styles.quoteInfoWOImage
      )}
      mediaClassName={commonStyles.largeMediaWrapper}
      image={
        imageFluid && (
          <PolaroidCard image={imageFluid} className={styles.polariodImage} />
        )
      }
      headline={
        <div>
          <blockquote
            className={classNames(
              commonStyles.title,
              styles.quote,
              !subhead && styles.quoteWOSubhead,
              !imageFluid && styles.quoteWOImage,
              !imageFluid && !subhead && styles.quoteWOSubheadAndImage
            )}
          >
            &ldquo;
            <Markdown content={quoteText} {...reactMarkdownProps} />
            &rdquo;
          </blockquote>
          <div
            className={classNames(
              styles.attribute,
              !imageFluid && styles.attributeWOImage
            )}
          >
            <div
              className={classNames(
                styles.attributeLine,
                !imageFluid && styles.hide
              )}
            />
            <Markdown content={quoteAttribution} {...reactMarkdownProps} />
          </div>
        </div>
      }
      subhead={
        subhead ? (
          <h3 className={commonStyles.subtitle}>
            <Markdown content={subhead} {...reactMarkdownProps} />
          </h3>
        ) : undefined
      }
      buttonLink={buttonLink}
      buttonText={buttonText}
    />
  )
}
