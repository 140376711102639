import * as cheerio from "cheerio"

export const replaceYoutubeIframe = (html: string) => {
  const $ = cheerio.load(html, null, false)

  const youtubeIframes = $("iframe[src^='https://www.youtube.com']")

  if (youtubeIframes.length < 1) return html

  youtubeIframes.each(function () {
    const attributes = $(this).attr() || {}
    const { pathname, search } = new URL(attributes.src || "")
    const youtubeId = pathname.slice(pathname.lastIndexOf("/") + 1)

    $(this).replaceWith(
      `<lite-youtube videoid=${youtubeId} params=${search.slice(
        1
      )}></lite-youtube>`
    )
  })

  return $.html().replaceAll("&gt; ", "> ")
}
