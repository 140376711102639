import { useState, useEffect } from "react"
import produce from "immer"
import { useInView } from "react-intersection-observer"

export const useImage = (src?: string | string[], lazy?: boolean) => {
  const sources = Array.isArray(src) ? src : [src]

  const initialLoadingStates = Array(sources.length).fill(true)

  const [loadingStates, setLoadingStates] = useState(initialLoadingStates)
  const [error, setError] = useState(false)

  const { ref: lazyRef, inView } = useInView()

  const loadImages = () => {
    sources.forEach((source, sourceIdx) => {
      const image = new Image()

      const idxLoadedRecipe = produce((draft) => {
        draft[sourceIdx] = false
      })

      image.onload = () => {
        setLoadingStates(idxLoadedRecipe)
      }

      image.onerror = () => {
        setLoadingStates(idxLoadedRecipe)
        setError(true)
      }

      image.src = source || ""
    })
  }

  // For non-lazy-loaded images, load the image on 1st render
  useEffect(() => {
    if (!lazy) loadImages()
  }, [])

  // For lazy-loaded images, load the image when element comes into view
  useEffect(() => {
    if (inView && lazy) loadImages()
  }, [inView])

  const loading = loadingStates.some(Boolean)

  return { loading, loadingStates, error, lazyRef }
}
