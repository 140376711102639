import React, {
  forwardRef,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useMemo
} from "react"
import classNames from "classnames"
import { Button } from "../button/button"
import { ModalPosition } from "./modal"
import styles from "./modal.module.scss"

export interface ModalBodyProps {
  className?: string
  isVisible: boolean
  onClose: () => void
  position?: ModalPosition
  dismissable?: boolean
  fullscreenMobile?: boolean
}

export const ModalBody = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ModalBodyProps>
>(
  (
    {
      children,
      className,
      isVisible,
      dismissable = true,
      fullscreenMobile = true,
      onClose,
      position
    },
    forwardedRef
  ) => {
    const handleClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
    }, [])

    const modalStyle = useMemo(() => {
      if (!position) {
        return {}
      }

      return {
        left: `${position.x}px`,
        top: `${position.y}px`
      }
    }, [position])

    return (
      <div
        className={classNames(
          styles.modal,
          isVisible && styles.visible,
          className,
          {
            [styles.forceWindowStyle]: !fullscreenMobile
          }
        )}
        onClick={handleClick}
        ref={forwardedRef}
        style={modalStyle}
      >
        {dismissable === true && (
          <Button
            icon="16-cross"
            className={styles.closeButton}
            onClick={onClose}
          />
        )}
        <div className={styles.content}>{children}</div>
      </div>
    )
  }
)

ModalBody.displayName = "ModalBody"
