import { useState } from "react"
import {
  FetchSuggestions,
  SearchResult,
  Suggestion
} from "../../../components/search-box/search-box-types"
import { noSuggestions } from "../../../components/search-box/search-box-constants"
import {
  searchClient,
  querySuggestionIndexName,
  mainSearchIndexName
} from "../search-constants"
import {
  mapAlgoliaSearchResults,
  mapAlgoliaSuggestions
} from "../mappers/algolia-mappers"

interface UseSearchSuggestions {
  suggestions: Suggestion[]
  querySuggestions: Suggestion[]
  fetchSuggestions: FetchSuggestions
  clearSuggestions(): void
}

export const useSearchSuggestions = (): UseSearchSuggestions => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>(noSuggestions)
  const [querySuggestions, setQuerySuggestions] =
    useState<Suggestion[]>(noSuggestions)

  const fetchSuggestions = async (query: string): Promise<void> => {
    // Index for dropdown search results (i.e. suggested resources)
    const searchResultsIndex = searchClient.initIndex(mainSearchIndexName)

    // Index for dropdown query suggestions (i.e. search term autocomplete)
    const suggestionsIndex = searchClient.initIndex(querySuggestionIndexName)

    // Query suggestions (autocomplete)
    const rawSuggestions = await suggestionsIndex.search(query, {
      hitsPerPage: 5
    })

    // Search results (suggested resources)
    const rawSearchResults = await searchResultsIndex.search<SearchResult>(
      query,
      {
        hitsPerPage: 5,
        attributesToRetrieve: [
          "type",
          "fields",
          "title",
          "primaryVariation",
          "handle"
        ],
        clickAnalytics: true
      }
    )

    if (rawSearchResults.hits.length) {
      setSuggestions(
        mapAlgoliaSearchResults({
          hits: rawSearchResults.hits,
          indexName: rawSearchResults.index,
          queryID: rawSearchResults?.queryID
        }) as Suggestion[]
      )
    }

    if (rawSuggestions.hits.length) {
      setQuerySuggestions(mapAlgoliaSuggestions(rawSuggestions))
    }
  }

  const clearSuggestions = (): void => setSuggestions(noSuggestions)

  return {
    suggestions,
    querySuggestions,
    fetchSuggestions,
    clearSuggestions
  }
}
