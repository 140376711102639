import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Spinner } from "../spinner/spinner"
import { Button, ButtonProps } from "./button"
import styles from "./spinner-button.module.scss"

export interface SpinnerButtonProps extends ButtonProps {
  processing: boolean
}

export const SpinnerButton: FunctionComponent<SpinnerButtonProps> = ({
  className,
  processing,
  children,
  variant = "primary",
  ...buttonProps
}) => (
  <Button
    {...buttonProps}
    variant={variant}
    disabled={buttonProps.disabled || processing}
    className={classNames(className, styles.spinnerButton, styles[variant], {
      [styles.processing]: processing,
      [styles.disabled]: buttonProps.disabled
    })}
  >
    <span className={styles.text}>{children}</span>
    {processing && <Spinner />}
  </Button>
)
