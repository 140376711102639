import React, {
  Children,
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  cloneElement,
  useMemo,
  useState
} from "react"
import classNames from "classnames"
import { TabItemProps } from "./tab-item"
import styles from "./tabs.module.scss"

export * from "./tab-item"

export type TabVariant =
  | "centered"
  | "horizontal"
  | "vertical"
  | "alternative"
  | "responsive"

export type TabSpacing = "md" | "lg"

export interface TabsProps {
  initialTab?: number
  className?: string
  contentClassName?: string
  variant?: TabVariant
  tabSpacing?: TabSpacing
  tabsClassName?: string
  title?: string
  titleClassName?: string
  subtitle?: string
}

export const Tabs: FunctionComponent<TabsProps> = ({
  initialTab = 0,
  children,
  className,
  contentClassName,
  variant = "horizontal",
  tabSpacing = "lg",
  tabsClassName,
  title,
  titleClassName,
  subtitle
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(initialTab)

  const tabItems = useMemo((): ReactNode => {
    return Children.map(children, (child, index) => {
      if (child) {
        const elementChild = child as ReactElement<TabItemProps>
        const isActiveTab = index === activeTabIndex

        return cloneElement<TabItemProps>(elementChild, {
          className: classNames(
            elementChild.props.className,
            isActiveTab && styles.activeTab
          ),
          key: index,
          label: elementChild.props.label,
          onClick: () => setActiveTabIndex(index)
        })
      }
    })
  }, [activeTabIndex, children])

  const activeTabContent = useMemo((): ReactNode | null => {
    const childrenArray = Children.toArray(children)
    const activeTab = childrenArray[activeTabIndex] as ReactElement<
      PropsWithChildren<TabsProps>
    >

    if (activeTab?.props.children) {
      return activeTab.props.children
    }

    return null
  }, [children, activeTabIndex])

  const wrapperClassNames = classNames(
    styles.wrapper,
    styles[variant],
    className
  )
  const tabsClassNames = classNames(
    styles.tabs,
    styles[tabSpacing],
    tabsClassName
  )
  const contentClassNames = classNames(styles.content, contentClassName)
  const titleClassNames = classNames(styles.title, titleClassName)

  return (
    <div className={wrapperClassNames}>
      <div className={titleClassNames}>
        {title && <h3>{title}</h3>}
        {subtitle && <h4>{subtitle}</h4>}
      </div>
      <ul className={tabsClassNames}>{tabItems}</ul>
      <div className={contentClassNames}>{activeTabContent}</div>
    </div>
  )
}
