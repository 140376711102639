import React, { FunctionComponent } from "react"
import { genericRecommendationMapper } from "./mappers"
import { CardResource } from "@components/card/card-resource"
import { RecombeeValues } from "@hooks/useRecombee"

interface CardRecommendationProps {
  content: RecombeeValues
}

export const CardRecommendation: FunctionComponent<CardRecommendationProps> = ({
  content
}) => {
  return <CardResource {...genericRecommendationMapper(content)} />
}
