import { ShareSocialMedia } from "./share"

const baseUrls: Record<ShareSocialMedia, string> = {
  facebook: "http://www.facebook.com/share.php?u=",
  twitter: "http://twitter.com/intent/tweet?text=",
  linkedin: "https://www.linkedin.com/sharing/share-offsite/?url="
}

export const getShareUrl = (
  url: string,
  socialMedia: ShareSocialMedia,
  text = ""
): string => {
  const shareUrl = text ? `${encodeURIComponent(text)}+${url}` : url

  return baseUrls[socialMedia] + shareUrl
}
