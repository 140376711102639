import React, {
  FunctionComponent,
  MouseEventHandler,
  MouseEvent,
  useCallback
} from "react"
import classNames from "classnames"
import styles from "./tabs.module.scss"

export interface TabItemProps {
  className?: string
  label: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
}

export const TabItem: FunctionComponent<TabItemProps> = ({
  className,
  label,
  onClick
}) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()
      onClick?.(event)
    },
    [onClick]
  )

  return (
    <li className={classNames(styles.tabItem, className)}>
      <a role="tab" onClick={handleClick}>
        {label}
      </a>
    </li>
  )
}
