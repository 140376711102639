import React, { FunctionComponent, useState, useEffect } from "react"
import classNames from "classnames"
import styles from "./attribution.module.scss"
import { Button } from "@components/button/button"
import { Link } from "@components/link/link"
import { Icon } from "@components/icon/icon"
import { Markdown } from "@components/markdown/markdown"

export interface AttributionProps {
  imageSrc: string
  body: string
  buttonLink: string
  buttonText: string
  isExternal?: boolean
  className?: string
}

export const AttributionWrapper: FunctionComponent<{ className?: string }> = ({
  children,
  className
}) => (
  <div className={classNames(styles.wrapperOuter, className)}>
    <div className={styles.wrapperInner}>{children}</div>
  </div>
)

export const Attribution: FunctionComponent<AttributionProps> = ({
  imageSrc,
  body,
  buttonLink,
  buttonText,
  isExternal = false,
  className,
  children
}) => {
  const [externalDomain, setExternalDomain] = useState("")

  useEffect(() => {
    if (isExternal && buttonLink.includes("http")) {
      const domain = new URL(buttonLink)

      setExternalDomain(domain.hostname.replace("www.", ""))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const externalLinkButtonProps = isExternal
    ? { target: "_blank", rel: "no-referrer" }
    : {}

  return (
    <AttributionWrapper className={className}>
      <aside
        className={classNames(styles.attribution, {
          [styles.withSibling]: Boolean(children)
        })}
      >
        <div className={styles.imageWrapper}>
          <Link
            className={styles.imageLink}
            to={buttonLink}
            {...externalLinkButtonProps}
          >
            <img
              className={styles.image}
              alt="Attribution image"
              src={imageSrc}
            />
          </Link>
        </div>
        <Markdown className={styles.attributionText} content={body} />
        <span className={styles.attributionCta}>
          <Button
            className={styles.attributionButton}
            to={buttonLink}
            variant="secondary"
            size="sm"
            {...externalLinkButtonProps}
          >
            {buttonText}
          </Button>
          {externalDomain && (
            <div className={styles.externalDomain}>
              <span>{externalDomain}</span>
              <Icon
                className={styles.externalLinkIcon}
                variant="12-external-link"
              />
            </div>
          )}
        </span>
      </aside>
      {children && <div className={styles.childrenContainer}>{children}</div>}
    </AttributionWrapper>
  )
}
