import React from "react"
import { PlaylistItem } from "../../types/playlist-item"
import { PlayerContent } from "./player"

export interface PlayerSchemaProps<T> {
  item: PlaylistItem<T>
  playerContent?: PlayerContent
}

export function PlayerSchema<T>({
  item,
  playerContent
}: PlayerSchemaProps<T>): JSX.Element {
  const data = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    name: playerContent?.title,
    description: playerContent?.description,
    thumbnailUrl: item.image,
    duration: item.meta ? `PT${item.meta.replace(":", "M")}S` : 0
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  )
}
