import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { FluidObject } from "gatsby-image"
import { ItemImage } from "../item-image/item-image"
import { Button } from "../button/button"
import { Link } from "../link/link"
import styles from "./generic-sidebar-cta.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface GenericSidebarCTAProps {
  buttonLabel?: string
  buttonUrl: string
  className?: string
  subHeadingClassName?: string
  heading?: string
  image?: FluidObject
  subheading?: string
  variant?: "textButton" | "regularButton"
  disableDesktopStyles?: boolean
}

type SidebarButtonProps = Pick<
  GenericSidebarCTAProps,
  "buttonLabel" | "buttonUrl" | "variant"
>

const SidebarButton: FunctionComponent<SidebarButtonProps> = ({
  buttonLabel,
  buttonUrl,
  variant
}) => {
  if (!buttonLabel) return null

  if (variant === "regularButton") {
    return (
      <Button
        variant="primary"
        to={buttonUrl}
        className={styles.wrappingButton}
      >
        <Markdown content={buttonLabel} />
      </Button>
    )
  }

  if (buttonUrl)
    return (
      <Link to={buttonUrl} className={styles.textLink}>
        <Markdown content={buttonLabel} />
      </Link>
    )

  return (
    <div className={styles.textLink}>
      <Markdown content={buttonLabel} />
    </div>
  )
}

export const GenericSidebarCTA: FunctionComponent<GenericSidebarCTAProps> = ({
  buttonLabel,
  buttonUrl,
  className,
  subHeadingClassName,
  heading,
  image,
  subheading,
  variant = "regularButton",
  children,
  disableDesktopStyles
}) => {
  return (
    <Link
      to={buttonUrl}
      className={classNames(
        styles.genericSidebarCTA,
        {
          [styles.disableDesktopStyles]: disableDesktopStyles,
          [styles.centered]: !subheading
        },
        className
      )}
    >
      <ItemImage className={styles.image} fluid={image} />
      <div className={styles.headingAndSubheadingWrapper}>
        {heading && <Markdown className={styles.heading} content={heading} />}
        {subheading && (
          <Markdown
            className={classNames(styles.subheading, subHeadingClassName)}
            content={subheading}
          />
        )}
      </div>
      {children && <div className={styles.children}>{children}</div>}
      <div className={styles.linkContainer}>
        <SidebarButton
          buttonLabel={buttonLabel}
          buttonUrl={buttonUrl}
          variant={variant}
        />
      </div>
    </Link>
  )
}
