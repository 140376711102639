import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Disc } from "../disc/disc"
import styles from "./avatar.module.scss"
import { AvatarProps } from "./avatar"
import { Skeleton } from "@components/skeleton/skeleton"
import skeletonStyles from "@components/skeleton/skeleton.module.scss"

interface AvatarSkeletonProps extends AvatarProps {
  namePosition?: "right" | "top"
}

export const AvatarSkeleton: FunctionComponent<AvatarSkeletonProps> = ({
  size = "md",
  namePosition = "right",
  nameVariant = "caps",
  className,
  showName = false,
  ...props
}) => {
  return (
    <Disc
      className={classNames(
        styles.avatar,
        styles[size],
        skeletonStyles.background,
        className
      )}
      content=" "
      size={size}
      {...props}
    >
      {showName && (
        <span
          className={classNames(
            styles.name,
            styles[size],
            styles[namePosition],
            styles[nameVariant]
          )}
        >
          <Skeleton />
        </span>
      )}
    </Disc>
  )
}
