import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styles from "./footer.module.scss"
import { ContentfulSubfooter } from "graphql/types"
import { Button } from "@components/button/button"

type FooterMinistryPartnerProps = {
  subfooter: ContentfulSubfooter
}

export default function FooterMinistryPartner({
  subfooter
}: FooterMinistryPartnerProps) {
  const image = getImage(subfooter.image?.gatsbyImageData)

  return (
    <div className={styles.footerMpWrapper}>
      <div className={styles.imageContainer}>
        {image && (
          <GatsbyImage
            className={styles.image}
            image={image}
            alt={subfooter.image?.title || ""}
          />
        )}
      </div>

      <div className={styles.footerMpContainer}>
        <h2 className={styles.title}>{subfooter.title}</h2>

        <div className={styles.footerMpSections}>
          {subfooter.sections?.map((section) => {
            if (!section) return null

            const gatsbyIcon = getImage(section.icon?.gatsbyImageData)
            const icon = section.icon?.file?.url

            return (
              <div key={section?.id} className={styles.footerMpSection}>
                {gatsbyIcon ? (
                  <GatsbyImage
                    image={gatsbyIcon}
                    alt={section.icon?.title || ""}
                    className={styles.image}
                  />
                ) : (
                  icon && (
                    <img
                      className={styles.image}
                      src={icon}
                      alt={section.icon?.title || ""}
                    />
                  )
                )}
                <div className={styles.sectionContent}>
                  <div>
                    <h3 className={styles.sectionTitle}>{section.title}</h3>
                    <p className={styles.sectionSubtitle}>
                      {section.subtitle?.subtitle || ""}
                    </p>
                  </div>
                  <Button
                    to={section.buttonUrl || ""}
                    className={styles.sectionButton}
                  >
                    {section.buttonText}
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
