import React, { FunctionComponent } from "react"
import { Helmet } from "react-helmet"
import ReactPlayerLoader from "@brightcove/react-player-loader"
import { VideoJsPlayer, VideoJsPlayerOptions } from "video.js"
import { v4 as uuidv4 } from "uuid"
import { VideoEventProps } from "../../core/segment/types"
import { useSegmentEvent } from "../../hooks/use-segment-event"
import style from "./player.module.scss"
import { accountId, playerEmbed, playerId } from "./player-constants"
import { ImagePlaceholders } from "@core/constants"

export interface PlayerContent {
  title?: string
  description?: string
  program?: string
}

interface PlayerProps {
  className?: string
  videoId: string
  content?: PlayerContent
}

const sessionId = uuidv4()

/* All plugins installed (script & style tags that get loaded on frontend/clientside)
 * in Brightcove dashboard: https://studio.brightcove.com/products/videocloud/players/players/GmUsBqClI
 *
 * List of installed plugins:
 *   - Chromecast Receiver (official Brightcove plugin)
 *   - bc-airplay (official Brightcove plugin)
 *   - Variable Playback Speed (official Brightcove plugin)
 *   - Videojs seek button (community plugin): https://github.com/mister-ben/videojs-seek-buttons
 *
 * Plugin settings will be initialized within the frontend codebase as shown below
 */
const playerOptions: VideoJsPlayerOptions = {
  playbackRates: [1, 1.25, 1.5, 1.75, 2],
  fluid: true,
  plugins: {
    seekButtons: {
      forward: 10,
      back: 10
    }
  }
}

export const Player: FunctionComponent<PlayerProps> = ({
  className,
  videoId,
  content
}) => {
  const { triggerEvent } = useSegmentEvent()

  const getPlaybackData = (player: VideoJsPlayer): Partial<VideoEventProps> => {
    const position = Math.floor(player.currentTime())
    const total_length = Math.floor(player.duration())
    const percent_complete = `${
      position === 0 ? 0 : Math.round((position / total_length) * 100)
    }%`

    return {
      content_asset_id: videoId,
      session_id: sessionId,
      position,
      total_length,
      percent_complete
    }
  }

  const successHandler = (player: VideoJsPlayer) => {
    player.on("play", () => {
      const playbackData = getPlaybackData(player)

      // If the user has not played this yet and just pressed play.
      if (player.played().length === 0 && content) {
        triggerEvent({ ...playbackData, ...content }, "Video Started")
      }

      // The user has played this and is resuming.
      if (player.played().length && content) {
        triggerEvent({ ...playbackData, ...content }, "Video Resumed")
      }
    })

    player.on("pause", () => {
      const playbackData = getPlaybackData(player)

      triggerEvent({ ...playbackData, ...content }, "Video Paused")
    })

    player.on("ended", () => {
      const playbackData = getPlaybackData(player)

      if (content) {
        triggerEvent({ ...playbackData, ...content }, "Video Completed")
      }
    })
  }

  const ImageOverlay: FunctionComponent = () => (
    <>
      <Helmet>
        <link
          key="crawlers-styles"
          href="/crawlers.css"
          type="text/css"
          rel="stylesheet"
        />
      </Helmet>
      <img
        src={ImagePlaceholders.card}
        className={`${style.imageOverlay}
        enableForCrawlersOnly`}
      />
    </>
  )

  return (
    <div className={className}>
      <ImageOverlay />
      <ReactPlayerLoader
        accountId={accountId}
        playerId={playerId}
        embedId={playerEmbed}
        videoId={videoId}
        id={`player-${sessionId}`}
        options={{
          ...playerOptions,
          suppressNotSupportedError: true
        }}
        onSuccess={({ ref: player }: { ref: VideoJsPlayer }) =>
          successHandler(player)
        }
        onFailure={() => {
          console.log("The video player has failed to load")
        }}
      />
    </div>
  )
}
