import classNames from "classnames"
import React, { ChangeEvent, FormEvent, useReducer } from "react"
import isEmail from "validator/lib/isEmail"
import styles from "./email-sign-up.module.scss"
import { useSegmentEvent } from "@hooks/use-segment-event"
import { Icon } from "@components/icon/icon"

export interface EmailSignUpProps {
  className?: string
  placeholder?: string
}

interface Reducer {
  error?: null | boolean
  inputValue?: string
  submitted?: boolean
}

export default function EmailSignUp({
  className,
  placeholder
}: EmailSignUpProps) {
  const { triggerEvent } = useSegmentEvent()
  const [state, setState] = useReducer(
    (s: Reducer, a: Reducer) => ({ ...s, ...a }),
    {
      error: null,
      inputValue: "",
      submitted: false
    }
  )

  function signUp(e: FormEvent) {
    e.preventDefault()

    const input = state.inputValue || ""

    if (input.length <= 0 || !isEmail(input)) {
      setState({ error: true })

      return
    }

    const eventParams = {
      email: state.inputValue
    }

    triggerEvent(eventParams, "Sign Up")

    setState({ submitted: true })
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    if (state.error) {
      setState({ error: null })
    }

    setState({ inputValue: e.target?.value })
  }

  const input = state.inputValue || ""

  return (
    <div className={classNames(styles.emailWrapper, className)}>
      {state.submitted ? (
        <button className={styles.submittedButton}>
          <Icon className={styles.iconCheck} variant={`16-check`} />
          Your email was successfully submitted.
        </button>
      ) : (
        <form onSubmit={signUp} className={styles.emailForm}>
          <div className={styles.inputWrapper}>
            <input
              type="text"
              value={input}
              onChange={onChange}
              className={classNames(
                styles.emailInput,
                state.error && styles.error
              )}
              placeholder={placeholder || `Enter your email address.`}
            />
            {state.error && (
              <Icon className={styles.iconError} variant={`12-info`} />
            )}
          </div>
          <button
            type="submit"
            className={classNames(
              "emailButton",
              styles.emailButton,
              state.error && styles.disabled
            )}
            disabled={!!state.error}
          >
            Submit
          </button>
        </form>
      )}
    </div>
  )
}
