import React, { ReactElement } from "react"
import { Configure } from "react-instantsearch-dom"
import qs from "qs"
import omit from "lodash/omit"
import {
  SearchRefinementList,
  SearchConfig,
  SearchState,
  SearchIndexes
} from "./search-types"
import {
  mainSearchIndexName,
  defaultSearchState,
  defaultSearchIndexes
} from "./search-constants"

const prettifyUrlFragment = (text: string): string =>
  text.split(" ").map(encodeURIComponent).join("+")

export const getFilteredIndexes = (ids: string[]): SearchIndexes => {
  return defaultSearchIndexes.filter((index) => ids.includes(index.id))
}

const getSearchIndexId = (searchIndexValue?: string): string | null => {
  const searchIndex = defaultSearchIndexes.find(
    (index) => index.value === searchIndexValue
  )

  return searchIndex ? searchIndex.id : null
}

export const getSearchIndexValue = (
  searchIndexId?: string,
  allowedIndexes?: SearchIndexes
): string => {
  const searchIndex = (allowedIndexes || defaultSearchIndexes).find(
    (index) => index.id === searchIndexId
  )

  return searchIndex ? searchIndex.value : mainSearchIndexName
}

function createQueryString(
  state: SearchState,
  localDefaultSearchIndex?: string
): string {
  const indexValue = getSearchIndexValue(localDefaultSearchIndex)

  const initialStateObject = {
    page: state.page !== 1 ? state.page : null,
    query: state.configure.query
      ? prettifyUrlFragment(state.configure.query)
      : null,
    limit:
      state.configure.hitsPerPage !== defaultSearchState.configure.hitsPerPage
        ? state.configure.hitsPerPage
        : null,
    sort: state.sortBy !== indexValue ? getSearchIndexId(state.sortBy) : null
  }

  const stateObject = Object.entries(state.refinementList || {}).reduce(
    (acc, [filter, values]) =>
      values && values.length > 0
        ? {
            ...acc,
            [filter]: values.map(prettifyUrlFragment).join("|")
          }
        : acc,
    initialStateObject
  )

  const stringifiedStateObject = qs.stringify(stateObject, {
    encode: false,
    skipNulls: true
  })

  return stringifiedStateObject ? "?" + stringifiedStateObject : ""
}

export const searchStateToUrl = (
  location: Location,
  searchState: SearchState,
  localDefaultSearchIndex: string
): string =>
  `${location.pathname}${createQueryString(
    searchState,
    localDefaultSearchIndex
  )}`

export const queryStringToSearchState = (
  queryString: string,
  searchIndexId?: string,
  allowedIndexes?: SearchIndexes
): SearchState => {
  const stateObject = {
    sort: searchIndexId || "featured",
    page: defaultSearchState.page,
    limit: defaultSearchState.configure.hitsPerPage,
    query: defaultSearchState.configure.query,
    ...qs.parse(queryString?.slice(1))
  }

  const configure: SearchConfig = {
    hitsPerPage: Number(stateObject.limit),
    query: stateObject.query
  }
  const refinementListObject: Record<string, string> = omit(stateObject, [
    "sort",
    "page",
    "limit",
    "query"
  ]) as Record<string, string>

  const refinementList: SearchRefinementList = Object.entries(
    refinementListObject
  ).reduce(
    (acc, [filter, values]) => ({ ...acc, [filter]: values.split("|") }),
    {}
  )

  return {
    configure,
    page: Number(stateObject.page),
    refinementList,
    sortBy: getSearchIndexValue(stateObject.sort, allowedIndexes)
  }
}

// NOTE: This is here only to help testing if the widget gets "rendered"
//       It's a non-DOM component, so we can't test it with RTL, and mocking
//       "react-instantsearch-dom" is... problematic.
export const configure = (searchConfig: SearchConfig): ReactElement => (
  <Configure {...searchConfig} clickAnalytics />
)
