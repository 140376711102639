require("isomorphic-fetch")

import { useQuery } from "react-query"
import {
  calculateAvailability,
  AvailabilityAndPrice
} from "../features/store/product-availability-source"
import { productByHandle } from "@utils/shopify-storefront"
import { shortDateStringToDate } from "@utils/date"
import { ShopifyProduct, ShopifyVariant } from "@src-types/shopify"

export interface ShopifyProductResponse {
  isLoading?: boolean
  error?: Error
  data?: ShopifyProductHook
}

export interface ShopifyProductHook extends AvailabilityAndPrice {
  product: ShopifyProduct
}

function useShopifyProduct(handle: string): ShopifyProductResponse {
  const queryRes = useQuery<any, Error>(
    ["product", handle],
    () => productByHandle(handle),
    {
      staleTime: 60 * 5000 // 5 minute
    }
  )

  if (queryRes.isLoading) {
    return { isLoading: true }
  }

  if (queryRes.error) {
    console.log(`useShopifyProduct Error: `, queryRes.error)

    return { error: queryRes.error }
  }

  const product = queryRes?.data

  if (!product) {
    return { error: Error(`No product found`) }
  }

  const variants = product?.variants?.edges?.map(
    ({ node }: { node: ShopifyVariant }) => node
  )
  const collections = product?.collections?.edges.map(
    ({ node }: { node: { title: string } }) => node
  )
  const variant = variants?.[0]

  if (!product || !variant) {
    return { error: Error("Product or Variant do not exist") }
  }

  const publicationDate = shortDateStringToDate(
    product.publicationDate?.value || ""
  )

  const backorderCustomDate = shortDateStringToDate(
    product.backorderCustomDate?.value || ""
  )

  const productSource = {
    availableForSale: variant.availableForSale,
    currentlyNotInStock: variant.currentlyNotInStock,
    quantityAvailable: variant.quantityAvailable,
    requiresShipping: variant.requiresShipping,
    allowPreorder: product.allowPreorder && product.allowPreorder.value,
    price: variant.price?.amount,
    compareAtPrice: variant.compareAtPrice?.amount,
    publicationDate: publicationDate?.getTime?.()
  }

  const availability = calculateAvailability(productSource)

  const result: ShopifyProductHook = {
    availability,
    price: productSource.price,
    compareAtPrice: productSource.compareAtPrice,
    product: {
      ...product,
      publicationDate,
      backorderCustomDate,
      // Remove format from product's title since it's normally displayed separately
      title: product.title?.split?.("—")?.[0]?.trim?.() || product.title,
      variants,
      defaultTitle: collections?.[0]?.title || ""
    }
  }

  return { data: result }
}

export { useShopifyProduct }
