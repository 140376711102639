import React, { FunctionComponent } from "react"
import {
  BreadcrumbResource,
  Breadcrumbs
} from "../../../../components/breadcrumbs/breadcrumbs"
import { ShareAndLikeButton } from "../../../../components/share-and-like-button/share-and-like-button"
import { Teacher } from "../../../../types/teacher"
import { PathPrefix } from "../../../../core/constants"
import { TeachingSerieTeachers } from "./teaching-serie-teachers"
import styles from "./teaching-serie-header.module.scss"

interface TeachingSerieHeaderProps {
  contentfulId: string
  title: string
  subtitle: string
  topics: BreadcrumbResource[]
  teachers: Teacher[]
  slug: string
}

export const TeachingSerieHeader: FunctionComponent<
  TeachingSerieHeaderProps
> = ({ contentfulId, title, subtitle, topics, teachers, slug }) => (
  <header className={styles.header}>
    <div className={styles.content}>
      <Breadcrumbs items={topics} size="sm" />
      <h1 className={styles.title}>{title}</h1>
      <h5 className={styles.subtitle}>{subtitle}</h5>
    </div>
    <div className={styles.teachers}>
      <TeachingSerieTeachers teachers={teachers} />
      <ShareAndLikeButton
        shareUrl={`${PathPrefix.Series}/${slug}`}
        className={styles.share}
        id={contentfulId}
        contentType="series"
      />
    </div>
  </header>
)
