export const copy = (text: string): boolean => {
  const range = document.createRange()
  const selection = document.getSelection()
  const node = document.createElement("span")

  let success = false

  if (!selection || !document.execCommand) {
    return false
  }

  try {
    selection.removeAllRanges()

    node.textContent = text
    node.style.position = "fixed"
    node.style.top = "0px"
    node.style.clip = "rect(0, 0, 0, 0)"
    node.style.whiteSpace = "pre"
    node.style.webkitUserSelect = "text"
    node.style.msUserSelect = "text"
    node.style.userSelect = "text"

    document.body.appendChild(node)
    range.selectNodeContents(node)
    selection.addRange(range)

    const successful = document.execCommand("copy")

    if (!successful) {
      throw new Error("copy command was unsuccessful")
    }

    success = true
  } catch (error) {
    console.error("Unable to copy text: ", error)
  } finally {
    selection.removeAllRanges()
    document.body.removeChild(node)
  }

  return success
}
