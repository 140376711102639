import React, { FunctionComponent } from "react"
import classNames from "classnames"
import styles from "./footer.module.scss"

export interface FooterGroupProps {
  title: string
  className?: string
}

export const FooterGroup: FunctionComponent<FooterGroupProps> = ({
  title,
  children,
  className
}) => (
  <section className={classNames(styles.group, className)}>
    <h3 className={styles.heading}>{title}</h3>
    {children}
  </section>
)

export interface FooterGroupRowProps {
  className?: string
}

export const FooterGroupRow: FunctionComponent<FooterGroupRowProps> = ({
  children,
  className
}) => <div className={classNames(styles.row, className)}>{children}</div>
