import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { Icon, IconVariant } from "../icon/icon"
import styles from "./social-button.module.scss"
import { Button } from "./button"

export type SocialButtonVariant =
  | "facebook"
  | "instagram"
  | "twitter"
  | "youtube"
  | "email"
  | "linkedin"

export interface SocialButtonProps {
  disabled?: boolean
  className?: string
  variant: SocialButtonVariant
  onClick?: MouseEventHandler<HTMLButtonElement>
  to?: string
}

export const variants: Record<SocialButtonVariant, IconVariant> = {
  facebook: "20-facebook",
  instagram: "20-instagram",
  twitter: "20-x-twitter",
  youtube: "20-youtube",
  email: "40-email",
  linkedin: "40-linkedin"
}

export const title: Record<SocialButtonVariant, string> = {
  facebook: "Facebook",
  instagram: "Instagram",
  twitter: "Twitter",
  youtube: "YouTube",
  email: "Email",
  linkedin: "LinkedIn"
}

export const SocialButton: FunctionComponent<SocialButtonProps> = ({
  className,
  disabled = false,
  variant,
  onClick,
  ...props
}) => (
  <Button
    className={classNames(
      styles.button,
      styles[variant],
      disabled && styles.disabled,
      className
    )}
    disabled={disabled}
    title={title[variant]}
    onClick={onClick}
    {...props}
  >
    <Icon className={styles.icon} variant={variants[variant]} />
  </Button>
)
