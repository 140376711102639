import React, {
  FunctionComponent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react"
import classNames from "classnames"
import { copy } from "./helpers"
import styles from "./copy-to-clipboard.module.scss"

export interface CopyToClipboardProps {
  className?: string
  onCopy?: (success: boolean, text?: string | undefined) => void
  resetTime?: number
  text: string
}

export const CopyToClipboard: FunctionComponent<CopyToClipboardProps> = ({
  className,
  onCopy,
  resetTime = 2000,
  text
}) => {
  const [copied, setCopied] = useState<boolean>(false)
  const timerRef = useRef(0)

  const handleButtonClick = useCallback(
    (event: MouseEvent): void => {
      event.preventDefault()

      if (copied) {
        onCopy?.(false)

        return
      }

      const copyResult = copy(text)

      if (!copyResult) {
        onCopy?.(false)

        return
      }

      setCopied(true)

      onCopy?.(true, text)

      timerRef.current = window.setTimeout(() => setCopied(false), resetTime)
    },
    [copied, onCopy, resetTime, text]
  )

  useEffect(() => {
    return (): void => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [timerRef])

  return (
    <div className={classNames(styles.container, className)}>
      <span className={styles.text} title={text}>
        {text}
      </span>
      <button className={styles.button} onClick={handleButtonClick}>
        {copied ? "Copied" : "Copy"}
      </button>
    </div>
  )
}
