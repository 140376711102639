import { FluidObject } from "gatsby-image"
import { EventFragment, ContentfulTeacher } from "../../../../graphql/types"
import { mapTeachers } from "../../../core/mappers/map-teachers"
import { Event } from "../event-types"
import { formatDatePeriod } from "../../../i18n/dates"

export interface EventListQuery {
  nodes: EventFragment[]
}

export const mapSourceEventListToView = (query: EventListQuery): Event[] =>
  query.nodes.map((node) => {
    const speakers = node.speakers
      ? mapTeachers(node.speakers as Partial<ContentfulTeacher>[])
      : []

    return {
      title: node.title!,
      location: node.location || "",
      type: node.type!,
      date: formatDatePeriod(node.startDate!, node.endDate || ""),
      url: node.url || "",
      image: node.image?.fluid?.src || "",
      imageFluid: node.image?.fluid as FluidObject,
      speakers
    }
  })
