import algolia, { SearchClient } from "algoliasearch"
import aa from "search-insights"
import { InsightsClient } from "react-instantsearch-core"
import {
  SearchFilters,
  SearchIndexes,
  SearchState,
  SearchConfig
} from "./search-types"

export const mainSearchIndexName =
  process.env.GATSBY_ALGOLIA_MAIN_SEARCH_INDEX || "new_ligonier_org_master"

export const alphabeticalSearchIndexName =
  process.env.GATSBY_ALGOLIA_ALPHABETICAL_SEARCH_INDEX ||
  "new_ligonier_org_master_alphabetical"

export const newestSearchIndexName =
  process.env.GATSBY_ALGOLIA_NEWEST_SEARCH_INDEX ||
  "new_ligonier_org_master_newest"

export const oldestSearchIndexName =
  process.env.GATSBY_ALGOLIA_OLDEST_SEARCH_INDEX ||
  "new_ligonier_org_master_oldest"

export const priceAscSearchIndexName =
  process.env.GATSBY_ALGOLIA_PRICE_ASC_SEARCH_INDEX ||
  "new_ligonier_org_master_price_asc"

export const priceDescSearchIndexName =
  process.env.GATSBY_ALGOLIA_PRICE_DESC_SEARCH_INDEX ||
  "new_ligonier_org_master_price_desc"

export const scriptureSearchIndexName =
  process.env.GATSBY_ALGOLIA_SCRIPTURE_SEARCH_INDEX ||
  "new_ligonier_org_master_scripture"

export const querySuggestionIndexName =
  process.env.GATSBY_ALGOLIA_QUERY_SUGGESTION_INDEX ||
  "new_ligonier_org_master_query_suggestions"

export const ALGOLIA_APP_ID = process.env.GATSBY_ALGOLIA_APP_ID || "C738AGBP0J"
export const ALGOLIA_API_KEY =
  process.env.GATSBY_ALGOLIA_API_KEY || "3b345d66277a6fee94f498e6932558a2"
const algoliaClient = algolia(ALGOLIA_APP_ID, ALGOLIA_API_KEY)

// Patch client to disable analytics for searches w/ no query (e.g. category pages)
export const searchClient: SearchClient = {
  ...algoliaClient,
  search(requests) {
    const newRequests = requests.map((request) => {
      // Disable analytics for the request if no query.
      // Also set facetingAfterDistinct=true. We split Algolia records with large
      // fields into multiple records, each containing a chunk of the field,
      // and the facet counts include each matching chunk without this
      // setting, which makes the counts too high.
      if (request.params && !request.params.query?.length) {
        return {
          ...request,
          params: {
            ...request.params,
            analytics: false,
            facetingAfterDistinct: true
          }
        }
      }

      return {
        ...request,
        params: {
          ...request.params,
          facetingAfterDistinct: true
        }
      }
    })

    return algoliaClient.search(newRequests)
  }
}

aa("init", {
  appId: ALGOLIA_APP_ID,
  apiKey: ALGOLIA_API_KEY,
  useCookie: true // Generate a user token and store it in a cookie
})
export const algoliaAnalytics = aa as InsightsClient

export const defaultSearchFilters: SearchFilters = [
  {
    title: "search-filter-type",
    attribute: "type"
  },
  {
    title: "search-filter-teachers",
    attribute: "fields.teachers.fields.shortName"
  },
  {
    title: "search-filter-topics",
    attribute: "fields.primaryTopic.fields.name"
  },
  {
    attribute: "fields.primaryScriptureReference.start.book",
    title: "search-filter-scripture"
  }
]

export const defaultSearchIndexes: SearchIndexes = [
  {
    id: "newest",
    label: "search-sort-newest",
    value: newestSearchIndexName
  },
  {
    id: "oldest",
    label: "search-sort-oldest",
    value: oldestSearchIndexName
  },
  {
    id: "featured",
    label: "search-sort-featured",
    value: mainSearchIndexName
  },
  {
    id: "alphabetical",
    label: "search-sort-alphabetical",
    value: alphabeticalSearchIndexName
  },
  {
    id: "price_asc",
    label: "search-sort-price-asc",
    value: priceAscSearchIndexName
  },
  {
    id: "price_desc",
    label: "search-sort-price-desc",
    value: priceDescSearchIndexName
  },
  {
    id: "scripture",
    label: "search-sort-scripture",
    value: scriptureSearchIndexName
  }
]

export const defaultSearchStateConfig: SearchConfig = {
  hitsPerPage: 24,
  query: ""
}

export const defaultSearchState: SearchState = {
  page: 1,
  configure: defaultSearchStateConfig,
  sortBy: mainSearchIndexName,
  refinementList: {}
}
