import { MetadataFragment } from "../../../graphql/types"
import { PartialMetadata } from "../../types/metadata"
import { TranslateableString } from "../../types/intl"

export interface MetadataOptions {
  title?: string | null
  description?: TranslateableString | string | null
  image?: string | null
  url?: string | null
}

export const mapMetadata = (
  metadata?: MetadataFragment | null,
  defaults?: MetadataOptions | null,
  overrides?: MetadataOptions
): PartialMetadata => {
  const result: PartialMetadata = {}

  if (defaults) {
    if (defaults.title) result.title = defaults.title
    if (defaults.description) result.description = defaults.description

    if (defaults.image) {
      result.openGraphImage = defaults.image
    }

    if (defaults.url) result.openGraphUrl = defaults.url
  }

  if (metadata) {
    if (metadata.title) {
      result.title = metadata.title
      result.useTitleTemplate = false
    }

    if (metadata.description) result.description = metadata.description

    if (metadata.image?.fixed?.src) {
      result.openGraphImage = metadata.image.fixed.src
    }
  }

  if (overrides) {
    if (overrides.title) {
      result.title = overrides.title
      result.useTitleTemplate = false
    }

    if (overrides.description) result.description = overrides.description
    if (overrides.image) result.openGraphImage = overrides.image
    if (overrides.url) result.openGraphUrl = overrides.url
  }

  return result
}
