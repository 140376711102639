import React, { FunctionComponent, Fragment } from "react"
import { useIntl, FormattedMessage } from "react-intl"
import {
  BreadcrumbResource,
  Breadcrumbs
} from "../../../../components/breadcrumbs/breadcrumbs"
import { AuthorLink } from "../../../../components/author-link/author-link"
import { ShareAndLikeButton } from "../../../../components/share-and-like-button/share-and-like-button"
import { TeachingSeries } from "../../teaching-series-types"
import { Tabs, TabItem } from "../../../../components/tabs/tabs"
import styles from "./teaching-serie-details.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface TeachingSerieProps {
  contentfulId: string
  teachingSerie: TeachingSeries
  parents?: BreadcrumbResource[]
}

export const TeachingSerie: FunctionComponent<TeachingSerieProps> = ({
  contentfulId,
  teachingSerie,
  parents
}) => {
  const { formatMessage } = useIntl()

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.details}>
          {parents && (
            <Breadcrumbs
              items={parents}
              size="sm"
              className={styles.videoTopics}
            />
          )}
          <AuthorLink
            {...teachingSerie.currentVideo.teacher}
            className={styles.desktopAuthor}
          />
          <div className={styles.titleWrapper}>
            <h2 className={styles.title}>{teachingSerie.currentVideo.title}</h2>
            <ShareAndLikeButton id={contentfulId} contentType="mediaResource" />
          </div>
          <Tabs
            className={styles.tabs}
            contentClassName={styles.tabContent}
            initialTab={0}
            tabSpacing="md"
            variant="alternative"
          >
            <TabItem
              label={formatMessage({ id: "teaching-series-description" })}
            >
              <div>
                <Markdown
                  className={styles.description}
                  content={
                    teachingSerie.currentVideo.additionalData.description
                  }
                />
                {teachingSerie.currentVideo.additionalData.transcript && (
                  <Fragment>
                    <div className={styles.transcript}>
                      <FormattedMessage id="teaching-series-transcript" />
                    </div>
                    <Markdown
                      className={styles.description}
                      content={
                        teachingSerie.currentVideo.additionalData.transcript
                      }
                    />
                  </Fragment>
                )}
              </div>
            </TabItem>
            <TabItem label={formatMessage({ id: "teaching-series-about" })}>
              <Markdown content={teachingSerie.about} />
            </TabItem>
          </Tabs>
        </div>
      </div>
    </section>
  )
}
