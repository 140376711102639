import {
  NavigationItemFragment,
  EventFragment
} from "../../../../graphql/types"
import {
  NavigationSection,
  NavigationLink,
  NavigationType
} from "../navigation-types"

interface Event extends EventFragment {
  date: string
}

export const mapSourceNavigationToView = (
  queryResult: NavigationItemFragment,
  events: Event[]
): NavigationSection[] => {
  if (!Array.isArray(queryResult.sections)) {
    return []
  }

  return queryResult.sections.reduce<NavigationSection[]>(
    (sectionList, section) => {
      if (!section || !section.links) {
        return sectionList
      }

      if (section.url?.includes("event")) {
        sectionList.push({
          name: section.name!,
          mobileName: section.mobileName,
          isDefault: !!section.isDefault,
          url: section.url || undefined,
          links: (events || []).reduce<NavigationLink[]>((linkList, link) => {
            const { date } = link

            if (!link || !link.title || !link.url) {
              return linkList
            }

            const type = link.type as NavigationType

            if (type === "Divider") {
              linkList[linkList.length - 1].divider = true

              return linkList
            }

            const record: NavigationLink = {
              name: link.title,
              url: link.url
            }

            if (date) {
              record.meta = date
            }

            linkList.push(record)

            return linkList
          }, [])
        })

        return sectionList
      }

      const links = section.links.reduce<NavigationLink[]>((linkList, link) => {
        if (!link || !link.name || !link.url) {
          return linkList
        }

        const type = link.type as NavigationType

        if (type === "Divider") {
          linkList[linkList.length - 1].divider = true

          return linkList
        }

        const record: NavigationLink = {
          name: link.name,
          url: link.url
        }

        linkList.push(record)

        return linkList
      }, [])

      if (section.url?.includes("store")) {
        sectionList.push({
          name: section.name!,
          mobileName: section.mobileName,
          isDefault: !!section.isDefault,
          url: section.url || undefined,
          links,
          promotionTitle: section.promotionTitle || undefined,
          promotionSubtitle: section.promotionSubtitle || undefined,
          promotionProduct: section.promotionProduct || undefined
        })

        return sectionList
      }

      sectionList.push({
        name: section.name!,
        mobileName: section.mobileName,
        isDefault: !!section.isDefault,
        url: section.url || undefined,
        links
      })

      return sectionList
    },
    []
  )
}
