import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { useIntl } from "react-intl"
import Image, { FluidObject } from "gatsby-image"
import { PathPrefix, Store } from "../../core/constants"
import { Icon } from "../icon/icon"
import { Link } from "../link/link"
import { ResourceType, resourceIconVariant } from "../../types/resource"
import { TranslateableString } from "../../types/intl"
import { Avatar } from "../avatar/avatar"
import { getStringTranslator } from "../../i18n/helpers"
import styles from "./card-resource.module.scss"
import { getFluidGatsbyImage } from "@utils/gatsbyImage"
import { ContentfulSeriesType } from "@src-types/graphql"

export interface CardResourceProps {
  avatar?: string
  slug: string
  url?: string
  title: string
  type?: ResourceType
  image?: string
  imageFluid?: FluidObject
  subtitle?: TranslateableString
  time?: number | null
  titleTag?: keyof JSX.IntrinsicElements
  typeForIcon?: ResourceType
  seriesType?: ContentfulSeriesType
}
const cardUrl: Record<ResourceType, string> = {
  article: PathPrefix.Articles,
  guide: PathPrefix.Guides,
  collection: PathPrefix.Collections,
  conference: PathPrefix.Conferences,
  devotional: PathPrefix.Devotional,
  event: PathPrefix.Events,
  mediaResource: PathPrefix.MediaResource,
  podcastEpisode: PathPrefix.Podcasts,
  podcastShow: PathPrefix.Podcasts,
  post: PathPrefix.Post,
  product: Store,
  question: PathPrefix.QuestionsAnswered,
  series: PathPrefix.Series,
  sermon: PathPrefix.Sermon,
  video: PathPrefix.Video
}

export const CardResource: FunctionComponent<CardResourceProps> = ({
  avatar,
  image,
  imageFluid: imageFluidProp,
  slug,
  seriesType,
  url: urlProp,
  subtitle,
  time,
  title,
  type = "article",
  titleTag: Tag = "div",
  typeForIcon
}) => {
  const url = (() => {
    if (type === "series" || type === "mediaResource") {
      switch (seriesType) {
        case "Conference Messages":
          return `${cardUrl["conference"]}/${slug}`

        case "Teaching Series":
        default:
          return `${cardUrl["series"]}/${slug}`
      }
    }

    switch (type) {
      case "video":
      case "event":
        return slug

      default:
        return `${cardUrl[type]}/${slug}`
    }
  })()
  const intl = useIntl()
  const translateString = getStringTranslator(intl)

  const imageFluid =
    imageFluidProp || getFluidGatsbyImage(image || "", { maxWidth: 800 })

  return (
    <Link className={styles.cardResource} to={urlProp || url}>
      <div className={styles.content}>
        <Tag className={styles.title}>
          <span>{title}</span>
        </Tag>
        <div className={styles.meta}>
          <span className={styles.iconWrapper}>
            <Icon
              variant={resourceIconVariant[typeForIcon || type]}
              className={styles.icon}
            />
          </span>
          <div className={styles.subtitle}>
            {subtitle && translateString(subtitle)}
          </div>
          {time ? (
            <span className={styles.time}>{!!time && `${time}MIN`}</span>
          ) : (
            <span className={styles.time} />
          )}
        </div>
      </div>
      {imageFluid ? (
        <Image className={styles.image} fluid={imageFluid} />
      ) : (
        <div
          className={classNames(
            styles.image,
            image ? styles.fallback : styles.placeholder
          )}
          style={{
            backgroundImage: `url(${
              image || "/assets/image-placeholders/card-logo.svg"
            })`
          }}
        />
      )}
      {avatar && (
        <Avatar className={styles.avatar} image={avatar} outline size="sm" />
      )}
    </Link>
  )
}
