import React from "react"
import { FluidObject } from "gatsby-image"
import styles from "./testimonials.module.scss"
import PolaroidCard from "@components/PolaroidCard/polaroid-card"
import { Markdown } from "@components/markdown/markdown"

interface Testimonial {
  image: FluidObject
  quote: string
  attribution: string
}

interface TestimonialsProps {
  testimonials: Array<Testimonial>
}

export default function Testimonials({ testimonials }: TestimonialsProps) {
  if (!testimonials?.length) return null
  const reactMarkdownProps = {
    disallowedElements: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
    unwrapDisallowed: true
  }

  return (
    <section className={styles.container}>
      {testimonials.map(({ image, quote, attribution }, i) => (
        <div className={styles.testimonial} key={i}>
          <PolaroidCard image={image} className={styles.image} />
          <blockquote className={styles.quote}>
            &ldquo;
            <Markdown content={quote} {...reactMarkdownProps} />
            &rdquo;
          </blockquote>
          <div className={styles.attribution}>
            <span>&mdash;&nbsp;</span>
            <Markdown
              content={attribution.replace("\n", "  \n")}
              {...reactMarkdownProps}
            />
          </div>
        </div>
      ))}
    </section>
  )
}
