import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react"
import { FormattedMessage } from "react-intl"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import classNames from "classnames"
import { Button } from "../button/button"
import { MenuWrapper } from "../../features/navigation/components/menu-wrapper"
import { SearchBoxVariant } from "../search-box/search-box"
import { HeaderSearch } from "./header-search"
import { HeaderAuth } from "./header-auth"
import { HeaderCart } from "./header-cart"
import { HeaderDonate } from "./header-donate-button"
import styles from "./header.module.scss"
import { usePageScroll } from "@components/page/page"

export type HeaderVariant = "cream" | "white" | "clear"

export interface HeaderProps {
  className?: string
  hideMenu?: boolean
  hideSearch?: boolean
  searchPlaceholder?: string
  variant?: HeaderVariant
  cartIcon?: boolean
  isInfoIconShown?: boolean
}

const menuDuration = parseInt(styles.menuDuration, 10)
const scrollThreshold = -50

const searchVariant: Record<HeaderVariant, SearchBoxVariant> = {
  cream: "white",
  white: "gray",
  clear: "outline"
}

const searchVariantScrolled: Record<HeaderVariant, SearchBoxVariant> = {
  cream: "white",
  white: "gray",
  clear: "gray"
}

export const Header: FunctionComponent<HeaderProps> = ({
  className,
  hideMenu,
  hideSearch,
  searchPlaceholder,
  variant = "white",
  cartIcon
}) => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isMenuAnimating, setMenuAnimating] = useState(false)
  const [isSearchOpen, setSearchOpen] = useState(false)
  const menuTimeout = useRef(0)

  const headerElementRef = useRef<HTMLElement>(null)

  const { scrollEnabled } = usePageScroll()

  let scrollBarGap = 0

  if (headerElementRef.current && typeof window !== "undefined") {
    scrollBarGap = window.innerWidth - headerElementRef.current.clientWidth
  }

  const onMenuAnimated = useCallback(() => {
    setMenuAnimating(false)
  }, [])

  const toggleMenu = useCallback(() => {
    setMenuOpen(!isMenuOpen)
    setMenuAnimating(true)
    menuTimeout.current = window.setTimeout(onMenuAnimated, menuDuration)
  }, [isMenuOpen, onMenuAnimated])

  const openSearch = useCallback(() => {
    setSearchOpen(true)
  }, [])

  const closeSearch = useCallback(() => {
    setSearchOpen(false)
  }, [])

  useEffect(() => {
    return (): void => {
      clearTimeout(menuTimeout.current)
    }
  }, [])

  useScrollPosition(
    ({ currPos }) => {
      setIsScrolled(currPos.y < scrollThreshold)
    },
    [],
    undefined,
    false,
    50
  )

  useLayoutEffect(() => {
    if (window.scrollY > -1 * scrollThreshold) {
      setIsScrolled(true)
    }
  }, [])

  return (
    <header
      ref={headerElementRef}
      style={{
        ...(!scrollEnabled && { width: `calc(100% - ${scrollBarGap}px)` })
      }}
      className={classNames(
        styles.header,
        styles[variant],
        isMenuOpen && styles.menuOpen,
        isMenuAnimating && styles.menuAnimating,
        isSearchOpen && styles.searchOpen,
        isScrolled && styles.scrolled,
        className
      )}
    >
      <div className={styles.container}>
        {!hideMenu && (
          <button className={styles.menuButton} onClick={toggleMenu}>
            <FormattedMessage
              id={isMenuOpen ? "header-close" : "header-menu"}
            />
          </button>
        )}
        <Button className={styles.logoButton} icon="24-logo" to="/" />
      </div>
      <div className={styles.container}>
        <HeaderSearch
          hide={hideSearch}
          close={closeSearch}
          placeholder={searchPlaceholder}
          variant={
            isScrolled || isMenuOpen
              ? searchVariantScrolled[variant]
              : searchVariant[variant]
          }
          hasBodyScrollLock={true}
        />
      </div>
      <div className={styles.container}>
        {!hideSearch && (
          <Button
            className={styles.openSearchButton}
            icon="16-search"
            size="md"
            onClick={openSearch}
          />
        )}
        <HeaderCart forceShow={isMenuOpen} cartIcon={cartIcon} />
        <HeaderAuth accountButtonClassName={styles.authButton} />
        <HeaderDonate className={styles.donateButton} />
      </div>
      <div
        className={classNames(
          styles.menuWrapper,
          isMenuOpen && styles.menuOpen,
          isMenuAnimating && styles.menuAnimating
        )}
      >
        <MenuWrapper className={styles.menu} onClickLink={toggleMenu} />
        <div className={styles.tint} onClick={toggleMenu} />
      </div>
    </header>
  )
}
