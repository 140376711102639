import React, { FunctionComponent } from "react"
import classNames from "classnames"
import styles from "./callouts-mp.module.scss"
import { Icon } from "@components/icon/icon"
import { Button } from "@components/button/button"

export type CalloutsMPVariant = "cream" | "frost"

export interface CalloutsMPProps {
  className?: string
  variant?: CalloutsMPVariant
  heading: string
  subheading: string
  link: string
  buttonText: string
}

export const CalloutsMP: FunctionComponent<CalloutsMPProps> = ({
  className,
  variant = "frost",
  heading,
  subheading,
  link,
  buttonText
}) => {
  return (
    <div className={classNames(styles.callouts, styles[variant], className)}>
      <section className={styles.callout}>
        <Icon className={styles.icon} variant={`24-donate`} />
        <div className={styles.body}>
          <h3 className={styles.heading}>{heading}</h3>
          <p className={styles.subheading}>{subheading}</p>
        </div>
        <Button to={link} variant="secondary">
          {buttonText}
        </Button>
      </section>
    </div>
  )
}
