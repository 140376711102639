import React, { FunctionComponent } from "react"
import classNames from "classnames"
import styles from "./overlay-with-message.module.scss"
import { Spinner } from "@components/spinner/spinner"

export interface OverlayWithMessageProps {
  image: string | undefined
  text?: string
  showSpinner?: boolean
}

export const OverlayWithMessage: FunctionComponent<OverlayWithMessageProps> = ({
  image,
  text,
  showSpinner
}) => {
  return (
    <div
      className={classNames(styles.wrapper)}
      style={{ backgroundImage: `url("${image}")` }}
      data-testid="OverlayWithMessageArtwork"
    >
      <div className={styles.overlay} data-testid="OverlayWithMessage" />
      <div className={styles.container}>
        <p data-testid="OverlayWithMessageText" className={styles.info}>
          {showSpinner ? <Spinner /> : text}
        </p>
      </div>
    </div>
  )
}
