import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Avatar as AvatarInterface } from "../../types/avatar"
import { Disc, DiscProps, DiscSize } from "../disc/disc"
import styles from "./avatar.module.scss"
import { defaultAvatarUrl } from "@core/constants"

export type AvatarSize = DiscSize

export type AvatarNameVariant = "caps" | "link"

export interface AvatarProps
  extends AvatarInterface,
    Omit<DiscProps, "content" | "title"> {
  nameVariant?: AvatarNameVariant
  showName?: boolean
}

export const Avatar: FunctionComponent<AvatarProps> = ({
  className,
  initials,
  name,
  nameVariant = "caps",
  showName = false,
  size = "md",
  image,
  ...props
}) => {
  const avatarUrl = image || defaultAvatarUrl

  return (
    <div className={classNames(styles.avatarContainer, className)}>
      <Disc
        className={classNames(
          styles.avatar,
          styles[size],
          initials && styles.initials
        )}
        content={initials}
        title={name}
        size={size}
        image={avatarUrl}
        {...props}
      />
      {name && showName && (
        <span
          className={classNames(styles.name, styles[size], styles[nameVariant])}
        >
          {name}
        </span>
      )}
    </div>
  )
}
