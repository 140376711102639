import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { Avatar } from "../avatar/avatar"
import { ListItem, ListItemProps } from "./list-item"
import styles from "./list.module.scss"

export interface ListItemTeacherProps extends ListItemProps {
  image?: string
  meta: string
  title: string
  onClick?: MouseEventHandler
}

export const ListItemTeacher: FunctionComponent<ListItemTeacherProps> = ({
  image,
  className,
  meta,
  disabled = false,
  url,
  title,
  onClick,
  isHighlighted
}) => (
  <ListItem
    className={classNames(styles.listItemTeacher, className)}
    disabled={disabled}
    url={url}
    onClick={onClick}
    isHighlighted={isHighlighted}
  >
    <div className={styles.avatar}>
      <Avatar image={image} size="lg" />
    </div>
    <div className={styles.content}>
      <div className={styles.header}>{title}</div>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw as any]}
        className={styles.description}
      >
        {meta}
      </ReactMarkdown>
    </div>
  </ListItem>
)
