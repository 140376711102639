import React, { Fragment, FunctionComponent, useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import classNames from "classnames"
import { navigate } from "gatsby"
import { TeachingSerieHeader } from "../teaching-serie-header/teaching-serie-header"
import {
  TeachingSeries,
  TeachingSeriesVideoData
} from "../../teaching-series-types"
import { PlayerWithPlaylist } from "../../../../components/player-with-playlist/player-with-playlist"
import { TeachingSerie } from "../teaching-serie-details/teaching-serie-details"
import { TeachingCourse } from "../teaching-course/teaching-course"
import { PathPrefix } from "../../../../core/constants"
import { PlaylistItem } from "../../../../types/playlist-item"
import {
  RelatedProductGroups,
  RelatedProducts
} from "../../../../components/related-products/related-products"
import { BaseProductVariant } from "../../../store/store-types"
import { PlayerContent } from "../../../../components/player/player"
import { ContentLockServiceProvider } from "../../../../contexts/service-context"
import { ContentLockBootstrapDataKey } from "../../../../machines/contentLockMachine.machine"
import styles from "./teaching-serie-page.module.scss"
import recomStyles from "@components/recommendations/recommendations.module.scss"
import { Recommendations } from "@components/recommendations/recommendations"

export interface TeachingSeriePageProps {
  contentfulId: string
  paidContentVariants?: BaseProductVariant[] | null
  relatedProducts: RelatedProductGroups | null
  seriesContentfulId: string
  teachingSerie: TeachingSeries
  mediaId?: string
}

export const TeachingSeriePage: FunctionComponent<TeachingSeriePageProps> = ({
  teachingSerie,
  paidContentVariants,
  relatedProducts,
  contentfulId,
  seriesContentfulId
}) => {
  const { formatMessage } = useIntl()

  const items = useMemo(() => {
    return teachingSerie.playlist.map((item, i) => ({
      ...item,
      id: item.mediaId,
      meta: item.meta || "",
      showLock: !teachingSerie.freeStream && i !== 0
    }))
  }, [teachingSerie.playlist, teachingSerie.freeStream])

  const onItemClick = useCallback(
    (item: PlaylistItem<TeachingSeriesVideoData>) => {
      const url = `${PathPrefix.Series}/${teachingSerie.slug}/${item.slug}`

      navigate(url)
    },
    [teachingSerie.slug]
  )

  const playerContent: PlayerContent = {
    title: teachingSerie.currentVideo.title || "",
    description: teachingSerie.metadata.description as string,
    program: teachingSerie.title
  }

  const { teacher, primaryTopic } = teachingSerie.currentVideo

  return (
    <ContentLockServiceProvider
      contentLockKey={ContentLockBootstrapDataKey.teachingSerie}
    >
      <Fragment>
        <TeachingSerieHeader
          {...teachingSerie}
          contentfulId={seriesContentfulId}
        />
        <PlayerWithPlaylist
          mediaId={teachingSerie.currentVideo.mediaId}
          image={teachingSerie.currentVideo.image || teachingSerie.image}
          items={items}
          onItemClick={onItemClick}
          paidContentVariants={paidContentVariants || null}
          playerContent={playerContent}
          playlistTitleOnMobile={teachingSerie.title}
          playlistTeachers={teachingSerie.teachers}
        />
        <TeachingSerie
          teachingSerie={teachingSerie}
          contentfulId={contentfulId}
          parents={teachingSerie.topics}
        />
        {relatedProducts && (
          <RelatedProducts
            products={relatedProducts}
            renderLabel={(label) =>
              formatMessage({ id: `store-product-productType-${label}` })
            }
          />
        )}
        {teachingSerie.courseLink && (
          <TeachingCourse
            courseLink={teachingSerie.courseLink}
            image={teachingSerie.courseImage || teachingSerie.image}
            className={classNames(relatedProducts && styles.teachingCourse)}
          />
        )}
        <div className={recomStyles.relatedResourcesWrapper}>
          {teacher?.slug && (
            <Recommendations
              recombeeConfig={{
                type: "teacher",
                slug: teacher.slug,
                idsToRemove: [teachingSerie.currentVideo.contentfulId || ""],
                itemId: teachingSerie.contentfulId
              }}
              title={teacher.name || ""}
              overline="More from this teacher"
              body={teacher.shortBio}
              link={`${PathPrefix.Teachers}/${teacher.slug}`}
            />
          )}
          {primaryTopic?.slug && (
            <Recommendations
              recombeeConfig={{
                type: "topic",
                slug: primaryTopic.slug,
                idsToRemove: [teachingSerie.currentVideo.contentfulId || ""],
                itemId: teachingSerie.contentfulId
              }}
              title={primaryTopic.name || ""}
              overline="More on this topic"
              body={primaryTopic?.description?.description}
              link={`${PathPrefix.Topics}/${primaryTopic.slug}`}
            />
          )}
        </div>
      </Fragment>
    </ContentLockServiceProvider>
  )
}
