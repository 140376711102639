import { Maybe, VariantWithProductFragment } from "../../../../graphql/types"
import { RelatedProductGroups } from "../../../components/related-products/related-products"
import { ProductDetails } from "../store-types"
import { mapProductDetailsToRelatedProduct } from "./store-common-mappers"
import { mapVariantDetailsToView } from "./product-details-mapper"

export type ProductGroupingFunction = (product: ProductDetails) => string

const defaultGroupingFunction: ProductGroupingFunction = (product) =>
  product.type || ""

interface VariantWithProductFragmentMap {
  [key: string]: VariantWithProductFragment
}

export const mapProductListToRelatedProductsView = (
  nodes: Maybe<VariantWithProductFragment>[] | null | undefined,
  groupingFunction: ProductGroupingFunction = defaultGroupingFunction,
  shouldConsolidateByParent?: boolean
): RelatedProductGroups | null => {
  if (!nodes || !nodes.length) return null

  const consolidatedMap: VariantWithProductFragmentMap = nodes.reduce(
    (map, currentProduct) => {
      const parentId = currentProduct?.product?.id

      if (!currentProduct || !parentId) return map

      if (!map[parentId]) map[parentId] = currentProduct

      return map
    },
    {} as VariantWithProductFragmentMap
  )

  const products = shouldConsolidateByParent
    ? Object.values(consolidatedMap)
    : nodes

  const mappedProducts = (products as VariantWithProductFragment[]).map(
    mapVariantDetailsToView
  )
  // TODO: Re-enable (or reconsider) this when we have better data set
  // .filter((p) => p.teachers.length && p.image)
  // .map(mapProductDetailsToRelatedProduct)

  const result: RelatedProductGroups = {}

  mappedProducts.forEach((product) => {
    const groupName = groupingFunction(product)

    if (!groupName) return
    if (!result[groupName]) result[groupName] = []

    result[groupName].push(mapProductDetailsToRelatedProduct(product))
  })

  return result
}
