import React, {
  FunctionComponent,
  MouseEvent,
  useCallback,
  useRef,
  useState,
  useLayoutEffect,
  useMemo
} from "react"
import classNames from "classnames"
import { clamp } from "../../core/utils"
import styles from "./progress.module.scss"

export interface ProgressProps {
  className?: string
  onClick?: (value: number) => void
  value: number
}

export const Progress: FunctionComponent<ProgressProps> = ({
  className,
  onClick,
  value
}) => {
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const [wrapperPositionLeft, setWrapperPositionLeft] = useState(0)
  const progressRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (onClick && progressRef.current) {
      const boundingRect = progressRef.current.getBoundingClientRect()!

      setWrapperWidth(boundingRect.width)
      setWrapperPositionLeft(boundingRect.left)
    }
  }, [onClick])

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const newValue = (event.clientX - wrapperPositionLeft) / wrapperWidth

      onClick?.(newValue)
    },
    [onClick, wrapperPositionLeft, wrapperWidth]
  )

  const width = useMemo(
    () => Math.round(clamp(value, 0, 1) * 100) + "%",
    [value]
  )

  return (
    <div
      className={classNames(
        styles.progress,
        onClick && styles.interactive,
        className
      )}
      onClick={handleClick}
      ref={progressRef}
    >
      <span className={styles.bar} style={{ width }} />
      <span className={styles.track} />
    </div>
  )
}
