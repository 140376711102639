import { createContext } from "react"
import { noop } from "../../core/utils"
import { noSuggestions } from "../../components/search-box/search-box-constants"
import { Suggestion } from "../../components/search-box/search-box-types"
import { defaultSearchState } from "./search-constants"
import { SearchState } from "./search-types"

export interface SearchContext {
  clearSuggestions(): void
  fetchSuggestions(query: string): void
  searchState: SearchState
  setSearchState(searchState: SearchState): void
  suggestions: Suggestion[]
  querySuggestions: Suggestion[]
}

export const SearchContext = createContext<SearchContext>({
  clearSuggestions: noop,
  fetchSuggestions: noop,
  searchState: defaultSearchState,
  setSearchState: noop,
  suggestions: noSuggestions,
  querySuggestions: noSuggestions
})
