/*
  ADDING FLAGS:

  - 1: Insert a new feature flag in FlagIndexAccessors
      a: use same value for key & value
      b: collapse hyphenated LaunchDarkly flag keys to camelCase: content-lock -> contentLock
      c: useFeatureFlag hook will throw console.error for you if the key does not exist in LD :)

  - 2: Create an enum for the possible feature flag values
      a: Same thing... identical key/value pair
      b: Same thing... collapse hyphenated stuff
      c: ...working on validating value if they does not exist in LD :)

  - 3; Add new flag as property to FeatureFlag type
      a: use FlagIndexAccessors Symbol as reference for namespace
      b: value is: keyof typeof {your newly enum of possible values}
*/

// 1.
/* ============= FEATURE FLAG KEYS ============= */

export enum FeatureFlagIndexAccessors {
  contentLock = "contentLock",
  storeCheckout = "storeCheckout",
  myAccountDashboard = "myAccountDashboard"
}

// 2.
/* ============= FEATURE FLAG VALUES ============= */

export enum ContentLockValues {
  contentLockOn = "contentLockOn",
  contentLockOff = "contentLockOff",
  contentLockMaintenance = "contentLockMaintenance"
}

export enum StoreCheckoutValues {
  storeCheckoutOn = "storeCheckoutOn",
  storeCheckoutOff = "storeCheckoutOff"
}

export enum MyAccountDashboardValues {
  myAccountDashboardOn = "myAccountDashboardOn",
  myAccountDashboardOff = "myAccountDashboardOff"
}

/* ============= FEATURE FLAG TYPES ============= */

// 3.
export interface FeatureFlags {
  [FeatureFlagIndexAccessors.contentLock]: keyof typeof ContentLockValues
  [FeatureFlagIndexAccessors.storeCheckout]: keyof typeof StoreCheckoutValues
  [FeatureFlagIndexAccessors.myAccountDashboard]: keyof typeof MyAccountDashboardValues
}

export type FeatureFlagKeys = keyof typeof FeatureFlagIndexAccessors // contentLock | attribution | ...

/*
    please ignore for now. Leaving this here as a reminder.
    This object can be used to create a utility function
    that would validate the values given back from LaunchDarkly
*/

// export const FeatureFlagValues: { [P: string]: Record<symbol, string> } = {
//   [FeatureFlagIndexAccessors.contentLock]: ContentLockValues,
//   [FeatureFlagIndexAccessors.attribution]: AttributionValues
// }
