import React, {
  FunctionComponent,
  MouseEventHandler,
  useCallback,
  MouseEvent
} from "react"
import { navigate } from "gatsby"
import classNames from "classnames"
import { BackgroundImage } from "../background-image/background-image"
import styles from "./disc.module.scss"
import buildImageUrl from "@utils/imageUrl"

export type DiscSize = "xs" | "sm" | "md" | "lg" | "xl"

const DiscActualSize = {
  xs: parseInt(styles.discSizeXS),
  sm: parseInt(styles.discSizeSM),
  md: parseInt(styles.discSizeMD),
  lg: parseInt(styles.discSizeLG),
  xl: parseInt(styles.discSizeXL)
}

export interface DiscProps {
  className?: string
  content?: string
  image?: string
  link?: string
  onClick?: MouseEventHandler
  outline?: boolean
  title?: string
  size?: DiscSize
}

export const Disc: FunctionComponent<DiscProps> = ({
  className,
  children,
  content,
  image,
  link,
  onClick,
  outline = false,
  size = "md",
  title
}) => {
  const handleClick = useCallback(
    (event: MouseEvent) => {
      onClick?.(event)
      link && navigate(link)
    },
    [link, onClick]
  )

  let dimension = DiscActualSize?.[size] || 96

  // Take pixel density of user's device into account
  // Loads images at suitable resolutions for Retina displays, etc.
  if (typeof window !== "undefined") {
    // Round DPR to avoid requesting images w/ decimal dimensions
    const pixelRatio = Math.round(window.devicePixelRatio) || 2

    dimension = dimension * pixelRatio
  }

  const imageUrl = buildImageUrl(image || "", {
    width: dimension,
    height: dimension,
    quality: size === "xl" ? 75 : 50
  })

  return (
    <BackgroundImage
      wrapperClassName={classNames(
        outline ? styles.outlineDisc : styles.disc,
        styles[size],
        {
          [styles.content]: Boolean(content),
          [styles.image]: Boolean(image),
          [styles.interactive]: Boolean(onClick) || Boolean(link),
          [styles.outline]: outline
        },
        className
      )}
      innerClassName={styles.innerImage}
      data-content={content}
      src={imageUrl}
      title={title}
      onClick={handleClick}
    >
      {children}
    </BackgroundImage>
  )
}
