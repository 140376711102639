import { MouseEventHandler } from "react"
import { AlgoliaRecordFields, Type } from "../../../types/algolia"
import {
  QuerySuggestion,
  SearchResult
} from "../../../components/search-box/search-box-types"
import { PathPrefix } from "../../../core/constants"
import { algoliaAnalytics } from "../search-constants"
import { resultsMapper } from "./type-mappers"

export interface AlgoliaHit {
  objectID: string
}

export type AlgoliaQuerySuggestion = AlgoliaHit

export interface AlgoliaSearchResult extends AlgoliaHit {
  fields?: AlgoliaRecordFields
  type?: Type
}

export const mapAlgoliaSuggestion = ({
  objectID
}: AlgoliaQuerySuggestion): QuerySuggestion => ({
  text: objectID,
  url: `${PathPrefix.Search}?query=${objectID}`
})

export const mapAlgoliaSearchResult = (
  result: AlgoliaSearchResult
): SearchResult | null => {
  if (!result.type) {
    return null
  }

  if (result.type === "product" || result.type === "productCollection") {
    return resultsMapper[result.type](result)
  }

  return resultsMapper[result.type]
    ? resultsMapper[result.type](result.fields)
    : null
}

export const mapAlgoliaSuggestions = (input: {
  hits: AlgoliaQuerySuggestion[]
}): QuerySuggestion[] => input.hits.map(mapAlgoliaSuggestion)

export const mapAlgoliaSearchResults = (input: {
  hits: AlgoliaSearchResult[]
  indexName?: string
  queryID?: string
}): SearchResult[] =>
  input.hits.reduce<SearchResult[]>((results, hit, index) => {
    const insightClick = () => {
      console.log("INSIGHT CLICK")

      if (input.indexName && input.queryID) {
        algoliaAnalytics("clickedObjectIDsAfterSearch", {
          index: input.indexName,
          eventName: "Search Item Clicked",
          queryID: input.queryID,
          objectIDs: [hit.objectID],
          positions: [index + 1]
        })
      }
    }

    const result = mapAlgoliaSearchResult(hit)

    if (result) {
      result.onClick = insightClick
      results.push(result)
    }

    return results
  }, [])
