import { BreadcrumbResource } from "../../components/breadcrumbs/breadcrumbs"
import { PathPrefix } from "../constants"

export interface TopicNode {
  name: string
  slug: string
}

export const mapTopics = (
  parentNodes: TopicNode[],
  currentNode?: TopicNode
): BreadcrumbResource[] =>
  [...parentNodes, currentNode].filter(Boolean).map((node) => ({
    label: node!.name!,
    link: `${PathPrefix.Topics}/${node!.slug}`
  }))
