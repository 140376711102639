import React, { ReactElement, useEffect, useState } from "react"
import { Interpreter } from "xstate"
import {
  ContentLockBootstrapDataKey,
  ContentLockMachineProps,
  Events
} from "../machines/contentLockMachine.machine"
import { ServiceContext as TeachingSerieServiceContext } from "@features/teaching-series/templates/teaching-serie-template"
import { ServiceContext as ConferenceServiceContext } from "@features/conferences/templates/conference-detail-template"
import { ServiceContext as DailyVideoServiceContext } from "@features/daily-video/templates/daily-video-template"

export type ContentLockServiceProviderProps = {
  contentLockKey: ContentLockBootstrapDataKey
  children: ReactElement
}

export interface IServiceContext {
  contentLockService: Interpreter<
    ContentLockMachineProps,
    any,
    Events,
    {
      value: any
      context: ContentLockMachineProps
    }
  >
  canViewPremiumSeries?: boolean
  isLoading?: boolean
  alwaysUnlocked?: boolean
}

interface IContext {
  contextKey: ContentLockBootstrapDataKey | null
  ContentLockContext: React.Context<IServiceContext> | null
}

const ServiceContext = React.createContext<IContext>({
  contextKey: null,
  ContentLockContext: null
})

function ContentLockServiceProvider({
  contentLockKey,
  ...props
}: ContentLockServiceProviderProps) {
  const [contentLockServiceContext, setContentLockServiceContext] =
    useState<React.Context<IServiceContext> | null>(null)

  useEffect(() => {
    if (contentLockKey && !contentLockServiceContext) {
      if (
        contentLockKey === ContentLockBootstrapDataKey.conference &&
        ConferenceServiceContext
      ) {
        setContentLockServiceContext(
          ConferenceServiceContext as React.Context<IServiceContext>
        )
      } else if (
        contentLockKey === ContentLockBootstrapDataKey.teachingSerie &&
        TeachingSerieServiceContext
      ) {
        setContentLockServiceContext(
          TeachingSerieServiceContext as React.Context<IServiceContext>
        )
      } else if (
        contentLockKey === ContentLockBootstrapDataKey.dailyVideo &&
        DailyVideoServiceContext
      ) {
        setContentLockServiceContext(
          DailyVideoServiceContext as React.Context<IServiceContext>
        )
      }
    }
  }, [contentLockKey, contentLockServiceContext])

  if (!contentLockServiceContext) {
    return null
  }

  return (
    <ServiceContext.Provider
      value={{
        ContentLockContext: contentLockServiceContext,
        contextKey: contentLockKey
      }}
      {...props}
    />
  )
}

const useContentLockServiceContext = () => React.useContext(ServiceContext)

export { ContentLockServiceProvider, useContentLockServiceContext }
