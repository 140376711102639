import { CardResourceProps } from "@components/card/card-resource"
import { RecombeeResource, RecombeeValues } from "@hooks/useRecombee"
import { ResourceType } from "@src-types/resource"

type ResourceUnion = Exclude<
  RecombeeResource,
  "mediaResource" | "teacher" | "event"
>

export const genericRecommendationMapper = (
  input: RecombeeValues
): CardResourceProps => {
  let type: ResourceType
  let typeForIcon: ResourceType

  switch (input.type) {
    case "mediaResource":
      type = "mediaResource"
      typeForIcon = "video"
      break

    default:
      type = input.type as ResourceUnion
      typeForIcon = input.type as ResourceUnion
      break
  }

  const CardResourceProps = {
    avatar: input.primaryAuthorImage || "",
    image: input.image || "",
    slug: input.handle || "",
    seriesType: input.seriesType,
    subtitle: input.primaryAuthor || "",
    time: null,
    title: input.entryTitle || "",
    type,
    typeForIcon,
    url: input.externalUrl || ""
  }

  return CardResourceProps
}
