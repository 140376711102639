import React, { FunctionComponent, MouseEventHandler } from "react"
import styles from "./list.module.scss"
import { Icon } from "@components/icon/icon"
import { ResourceType } from "@src-types/resource"
import { Link } from "@components/link/link"
import { Markdown } from "@components/markdown/markdown"

export interface ListItemPodcatEpisodesProps {
  title: string
  description: string
  url: string
  type: ResourceType
  meta: string
  secondaryMeta: string | string[]
  onClick?: MouseEventHandler
}

export const ListItemPodcastEpisodes: FunctionComponent<
  ListItemPodcatEpisodesProps
> = ({ title, description, url, meta, secondaryMeta }) => {
  return (
    <li className={styles.listItemPodcastEpisode}>
      <Link className={styles.episodeLink} to={url}>
        <h4 className={styles.episodeTitle}>{title}</h4>
        <div className={styles.episodeMeta}>
          {meta && <div className={styles.meta}>{meta}</div>}
          {secondaryMeta && (
            <div className={styles.secondaryMeta}>{secondaryMeta}</div>
          )}
          <Icon className={styles.episodeIcon} variant="12-audio" />
        </div>
        <Markdown content={description} className={styles.episodeDescription} />
      </Link>
    </li>
  )
}
