import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import classNames from "classnames"
import { Event } from "../../features/events/event-types"
import { Link } from "../link/link"
import { Icon } from "../icon/icon"
import { Button } from "../button/button"
import { EventsListItem } from "./event-list-item"
import { FeaturedEvent } from "./featured-event"
import styles from "./events-list.module.scss"

export type EventListVariant = "primary" | "secondary"

export interface EventsListProps {
  list: Event[]
  variant?: EventListVariant
  className?: string
  fullList?: boolean
  featuredEvent?: Event | null
  displayFeaturedEvent?: boolean
}

export const EventsList: FunctionComponent<EventsListProps> = ({
  list,
  variant = "primary",
  className,
  fullList = false,
  featuredEvent,
  displayFeaturedEvent
}) => {
  if (list.length === 0) {
    return null
  }

  const prepareEvents = (events: Event[]) => {
    // if there is a featured event, we need to remove it from the list
    if (featuredEvent && displayFeaturedEvent) {
      return events.filter((event) => event.url !== featuredEvent.url)
    } else if (events.length > 1) {
      // since the first entry is used as the featured event if none is provided
      // we need to remove it from the list
      return events.slice(1)
    } else {
      return []
    }
  }

  const otherItems = prepareEvents(list)

  const footerClassName = classNames(styles.footer, {
    // Use negative margin if there are items in list above footer
    [styles.offsetTop]: Boolean(otherItems.length)
  })

  const item = featuredEvent && displayFeaturedEvent ? featuredEvent : list[0]

  return (
    <div className={styles[variant]}>
      {!fullList && (
        <header className={styles.header}>
          <div className={styles.wrapper}>
            <div className={styles.content}>
              <span className={styles.label}>
                <FormattedMessage id="events-upcoming-events" />
              </span>
              <Link to="/events">
                <FormattedMessage id="events-see-more" />
                <Icon variant="16-right-arrow" />
              </Link>
            </div>
          </div>
        </header>
      )}
      <FeaturedEvent item={item} className={className} variant={variant} />
      {otherItems.map((item) => (
        <EventsListItem key={item.title} item={item} variant={variant} />
      ))}
      {!fullList && (
        <footer className={footerClassName}>
          <Button variant="secondary" size="sm" to="/events">
            <FormattedMessage id="events-see-more" />
          </Button>
        </footer>
      )}
    </div>
  )
}
