import { SeriesFragment } from "graphql/types"

export function getPageTitle(
  details: SeriesFragment,
  currentMediaId: string
): string {
  const seriesName = details.name
  let teacher
  let resourceName

  const resource = details.mediaResources?.find(
    (resource) => currentMediaId === resource?.id
  )

  if (resource) {
    resourceName = resource.name
    teacher =
      resource.teachers?.length === 1
        ? resource.teachers && resource.teachers[0]?.shortName
        : resource.teachers &&
          resource.teachers[0]?.shortName + " and Various Teachers"
  }

  const pageTitle = resource
    ? resourceName + (teacher ? " by " + teacher : "") + " from " + seriesName
    : ""

  return pageTitle
}
