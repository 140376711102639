import { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  HeaderNavigationQuery,
  ContentfulSubfooter
} from "../../../../graphql/types"
import { mapSourceNavigationToView } from "../mappers/navigation-mapper"

export const useHeaderNavigation = () => {
  const queryResult: HeaderNavigationQuery = useStaticQuery(graphql`
    query HeaderNavigation {
      contentfulSubfooter {
        partnerCalloutMenuButtonLink
        partnerCalloutHeading
        partnerCalloutSubheading
        partnerCalloutButtonText
      }
      allContentfulNavigation(filter: { location: { eq: "header" } }) {
        edges {
          node {
            ...NavigationItem
          }
        }
      }
      allEvent(limit: 6, sort: { fields: [startDate], order: ASC }) {
        nodes {
          startDate
          date: startDate(formatString: "MMM. DD, YYYY")
          endDate
          title
          url
          type
        }
      }
    }
  `)

  return useMemo(() => {
    const { allContentfulNavigation, allEvent, contentfulSubfooter } =
      queryResult

    const navigationNode = allContentfulNavigation.edges[0].node

    const sections = mapSourceNavigationToView(navigationNode, allEvent.nodes)

    return { sections, subfooter: contentfulSubfooter as ContentfulSubfooter }
  }, [queryResult])
}
