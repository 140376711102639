import React, { FunctionComponent } from "react"
import { useFooterNavigation } from "../hooks/use-footer-navigation"
import { Footer } from "../../../components/footer/footer"
import { BreadcrumbResource } from "../../../components/breadcrumbs/breadcrumbs"

export interface FooterWrapperProps {
  basePath?: string
  breadcrumbs?: BreadcrumbResource[]
  className?: string
}

export const FooterWrapper: FunctionComponent<FooterWrapperProps> = (props) => {
  const { sections, subfooter } = useFooterNavigation()

  return (
    <Footer
      {...props}
      sections={sections}
      breadcrumbs={props.breadcrumbs}
      subfooter={subfooter}
    />
  )
}
