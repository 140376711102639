import { TopicListFragment, TopicFragment } from "../../../../graphql/types"
import { TopicState } from "../state/topic-state-types"
import { TopLevelTopic } from "../topic-types"
import { ProductTopics } from "./product-topics-mapper"

export const mapSourceTopicsToView = (
  queryResult: TopicListFragment,
  productTopics: ProductTopics
): TopicState => {
  const rootTopicIndex: Record<string, number> = {}

  return queryResult.edges.reduce<TopicState>(
    (state, { node }) => {
      if (!node.topicCode || !node.name || !node.slug || !node.topicCode) {
        return state
      }

      state.topicMap[node.id!] = node.name

      const topicCodeArray = node.topicCode!.split(".")

      const item = {
        name: node.name,
        slug: node.slug
      }

      if (topicCodeArray.length === 1) {
        rootTopicIndex[node.topicCode] = state.topicLists.length
        state.topicLists.push({
          ...item,
          items: []
        })
      } else {
        const count =
          (node.article?.length || 0) +
          (node.q_a?.length || 0) +
          (node.sermon?.length || 0) +
          (node.devotional?.length || 0) +
          (node.series?.length || 0) +
          (node.collection?.length || 0) +
          (node.podcast_show?.length || 0) +
          (node.podcast_episode?.length || 0) +
          (node.media_resource?.length || 0) +
          (productTopics[node.topicCode] || 0)

        const rootTopicCode = topicCodeArray[0]

        const parentIndex = rootTopicIndex[rootTopicCode]

        const parentNode = state.topicLists[parentIndex]

        if (parentNode) {
          parentNode.items.push({
            ...item,
            count,
            tier: topicCodeArray.length
          })
        }
      }

      return state
    },
    {
      topicLists: [],
      topicMap: {}
    }
  )
}

export const mapTopLevelTopic = ({
  name,
  topicCode,
  slug
}: TopicFragment): TopLevelTopic => ({
  name: name || "",
  topicCode: topicCode || "",
  slug: slug || ""
})

export const mapTopLevelTopics = (
  topicFragments: TopicFragment[]
): TopLevelTopic[] => topicFragments.map(mapTopLevelTopic)
