import React, { FunctionComponent, useCallback, useState } from "react"
import styles from "./toast.module.scss"

const TOAST_HIDE_DELAY = 5000

export interface ToastProps {
  message: string | JSX.Element
  actionText?: string
  action?: () => void
}

export const Toast: FunctionComponent<ToastProps> = ({
  message,
  actionText,
  action
}) => {
  const [isOpen, setIsOpen] = useState(true)

  if (isOpen) {
    setTimeout(() => setIsOpen(false), TOAST_HIDE_DELAY)
  }

  const handleClick = useCallback(() => {
    setIsOpen(false)
    action?.()
  }, [action])

  if (!isOpen) {
    return null
  }

  return (
    <div className={styles.toast}>
      <div className={styles.text}>{message}</div>
      {action && actionText && (
        <div className={styles.action} onClick={handleClick}>
          {actionText}
        </div>
      )}
    </div>
  )
}
