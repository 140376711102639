import { useStaticQuery, graphql } from "gatsby"

export const useGlobalPromotion = () => {
  const queryResult = useStaticQuery(graphql`
    {
      contentfulGlobalPromotion {
        title {
          title
        }
        description {
          description
        }
        linkText {
          linkText
        }
        linkUrl
        useButtonStyle
        image {
          fluid(maxWidth: 300) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  `)

  return queryResult?.contentfulGlobalPromotion
}
