import React from "react"
import { YearEndContent } from "../../home-page-types"
import YearEndSimple, { YearEndSimpleProps } from "./year-end-simple"
import YearEndCountdown, { YearEndCountdownProps } from "./year-end-countdown"
import YearEndVideo, { YearEndVideoProps } from "./year-end-video"
import YearEndQuote, { YearEndQuoteProps } from "./year-end-quote"
import YearEndTestimonials, {
  YearEndTestimonialsProps
} from "./year-end-testimonials"

interface HomePageYearEndProps {
  data: YearEndContent
}

export default function HomePageYearEnd({ data }: HomePageYearEndProps) {
  const { headerType, ...headerProps } = data

  switch (headerType) {
    case "simple":
      return <YearEndSimple {...(headerProps as YearEndSimpleProps)} />
    case "video":
      return <YearEndVideo {...(headerProps as YearEndVideoProps)} />
    case "quote":
      return <YearEndQuote {...(headerProps as YearEndQuoteProps)} />
    case "testimonies":
      return (
        <YearEndTestimonials {...(headerProps as YearEndTestimonialsProps)} />
      )
    case "countdown":
      return <YearEndCountdown {...(headerProps as YearEndCountdownProps)} />
    default:
      return null
  }
}
