import React, { Fragment, FunctionComponent } from "react"

export interface HighlightProps {
  className?: string
  firstOnly?: boolean
  pattern?: string
  text: string
}

export const Highlight: FunctionComponent<HighlightProps> = ({
  className,
  firstOnly,
  pattern,
  text
}) => {
  if (!text) return null

  if (!pattern) return <Fragment>{text}</Fragment>

  let shouldHighlight = true

  const regexPattern = pattern.replace(/[^\w\s]/g, "")
  const regex = new RegExp(`(${regexPattern})`, "i")

  if (!regex.test(text)) return <Fragment>{text}</Fragment>

  return (
    <Fragment>
      {text.split(regex).map((part, index) => {
        const highlight = regex.test(part) && shouldHighlight
        const Tag = highlight ? "mark" : "span"

        if (highlight && firstOnly) shouldHighlight = false

        return part ? (
          <Tag className={highlight ? className : undefined} key={index}>
            {part}
          </Tag>
        ) : null
      })}
    </Fragment>
  )
}
