import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { firstItems } from "../../core/utils"
import { Disc, DiscProps } from "../disc/disc"
import { Avatar, AvatarProps } from "./avatar"
import styles from "./avatar.module.scss"
import { formatAuthorNames } from "@utils/author-names"

export type AvatarListItemProps = Pick<
  AvatarProps,
  "image" | "initials" | "name" | "onClick"
>

export interface AvatarListProps extends Pick<DiscProps, "size"> {
  avatars: AvatarListItemProps[]
  listClassName?: string
  itemClassName?: string
  namesClassName?: string
  limit?: number
  showNames?: boolean
  backgroundColor?: string
}

const getAvatarsForDisplay = (
  avatars: AvatarListItemProps[],
  limit: number
) => {
  const { first, rest } = firstItems(avatars, limit)

  const overflow = rest.length ? `+${rest.length}` : null

  return { avatars: first, overflow }
}

export const AvatarList: FunctionComponent<AvatarListProps> = ({
  avatars: avatarsProp,
  listClassName,
  itemClassName,
  namesClassName: namesClassNameProp,
  limit = 8,
  size = "md",
  showNames = false,
  backgroundColor
}) => {
  const { avatars, overflow } = getAvatarsForDisplay(avatarsProp, limit)
  const namesText = formatAuthorNames(avatarsProp)

  const listClassNames = classNames(styles.list, styles[size], listClassName)
  const itemClassNames = classNames(styles.item, styles[size], itemClassName)
  const namesClassNames = classNames(styles.name, namesClassNameProp)
  const backgroundStyle = backgroundColor ? { backgroundColor } : {}

  return (
    <div style={backgroundStyle} className={listClassNames}>
      {avatars.map((avatar, index) => (
        <Avatar
          key={index}
          outline
          className={itemClassNames}
          size={size}
          {...avatar}
        />
      ))}
      {overflow && (
        <Disc
          className={itemClassNames}
          content={overflow}
          size={size}
          outline
        />
      )}
      {showNames && <span className={namesClassNames}>{namesText}</span>}
    </div>
  )
}
