import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { Icon } from "../icon/icon"
import { ListItem, ListItemProps } from "./list-item"
import styles from "./list.module.scss"

export interface ListItemCollectionProps extends ListItemProps {
  image?: string
  meta: string
  subtitle?: string
  title: string | JSX.Element
  onClick?: MouseEventHandler
}

export const ListItemCollection: FunctionComponent<ListItemCollectionProps> = ({
  className,
  disabled = false,
  image,
  meta,
  subtitle,
  title,
  url,
  onClick,
  isHighlighted
}) => (
  <ListItem
    className={classNames(styles.listItemCollection, className)}
    disabled={disabled}
    url={url}
    tag="div"
    onClick={onClick}
    isHighlighted={isHighlighted}
  >
    <div className={styles.content}>
      <div className={styles.subheader}>
        <Icon variant="12-collection" className={styles.icon} />
        <span className={styles.items}>{meta}</span>
      </div>
      <h2 className={styles.header}>{title}</h2>
      <div className={styles.subheader}>{subtitle}</div>
    </div>
    <div className={styles.side}>
      <div
        className={classNames(
          styles.image,
          image && styles.collectionImage,
          !image && styles.collectionPlaceholder
        )}
        style={{
          backgroundImage: `url(${
            image || "/assets/list/list-item-collection.svg"
          })`
        }}
      />
    </div>
  </ListItem>
)
