import { Maybe, ProductVariantFragment } from "../../../../graphql/types"
import { ProductVariant, VariantImages } from "../store-types"
import { mapAdditionalInfo } from "./product-details-mapper"

export const mapProductVariantToView = (
  variant: ProductVariantFragment
): ProductVariant => {
  const price = variant?.price || ""
  const compareAtPrice = variant?.compareAtPrice || ""
  const title = variant!.title || ""

  return {
    id: variant.realVariantId || "",
    storefrontId: variant.storefrontId || "",
    title,
    titleTag: variant.titleTag || undefined,
    color: variant?.color || undefined,
    format: variant?.format || undefined,
    features: mapAdditionalInfo(variant.additionalInfo),
    price,
    compareAtPrice,
    image: variant.image || undefined,
    images: variant.images as VariantImages,
    barcode: variant.barcode || "",
    handle: variant.handle || "",
    pageCount: variant.pageCount ? parseInt(variant.pageCount, 10) : undefined,
    publicationDate: variant.publicationDate || undefined,
    description: variant.description || undefined,
    descriptionTag: variant.descriptionTag || undefined,
    allowPreorder: Boolean(variant.allowPreorder),
    discontinued: Boolean(variant.discontinued),
    motivationCode: variant?.motivationCode || undefined,
    discountPrompt: variant?.discountPrompt || undefined,
    sku: variant.sku || "",
    language: variant?.language || undefined,
    embeddedContent: variant?.embeddedContent || undefined,
    vendor: variant?.vendor || undefined
  }
}

export const mapProductVariantsToView = (
  variants: Maybe<ProductVariantFragment>[]
): ProductVariant[] =>
  variants.map((v) => mapProductVariantToView(v as ProductVariantFragment))
