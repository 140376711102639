import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Button } from "../button/button"
import { Icon, IconVariant24 } from "../icon/icon"
import styles from "./callouts.module.scss"

export interface CalloutProps {
  icon: IconVariant24
  title: string
  body: string
  link: string
  url?: string
  className?: string
}

export const Callout: FunctionComponent<CalloutProps> = ({
  icon,
  title,
  body,
  link,
  url,
  className
}) => {
  return (
    <section className={classNames(styles.callout, className)}>
      <Icon className={styles.icon} variant={icon} />
      <h3 className={styles.heading}>{title}</h3>
      <p className={styles.body}>{body}</p>
      <Button
        to={url}
        variant="secondary"
        size="xs"
        className={styles.capsLink}
      >
        {link}
      </Button>
    </section>
  )
}
