import React, { FunctionComponent, MouseEventHandler } from "react"
import { useHeaderNavigation } from "../hooks/use-header-navigation"
import { Menu } from "../../../components/menu/menu"

export interface MenuWrapperProps {
  className?: string
  onClickLink?: MouseEventHandler<HTMLAnchorElement>
}

export const MenuWrapper: FunctionComponent<MenuWrapperProps> = (props) => {
  const { sections, subfooter } = useHeaderNavigation()

  return <Menu {...props} sections={sections} subfooter={subfooter} />
}
