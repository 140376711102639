import Client from "shopify-buy"
import { formatAuthorNames } from "./author-names"

const defaultClient = Client.buildClient({
  domain: process.env.GATSBY_STOREFRONT_DOMAIN!,
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN!
})

interface UpdatedVariant {
  quantity: number
  id: string
}

export interface CustomAttribute {
  key: string
  value: string
}

export interface MultipleAttributes {
  [key: string]: Array<CustomAttribute>
}

export interface LineItem {
  variantId: string
  quantity: number
  customAttributes?: Array<CustomAttribute>
  productType?: string
}

export const getCustomAttributes = (
  product: any,
  variant: any
): CustomAttribute[] => {
  const features = []

  if (product.teachers) {
    const authorNamesFeature = formatAuthorNames(product.teachers)

    features.push(authorNamesFeature)
  }

  if (variant.language || product.language) {
    // grab the variant's language first if it exist
    features.push(variant.language || product.language)
  }

  if (variant.title) features.push(variant.title) // format like "Hardcover"

  if (variant.color) features.push(variant.color)

  const attribute = [
    { key: "features", value: JSON.stringify(features) },
    { key: "title", value: product?.title || "" },
    { key: "quantityAvailable", value: `${variant?.quantityAvailable || 0}` },
    { key: "requiresShipping", value: `${variant?.requiresShipping}` },
    { key: "packageName", value: product?.packageName?.value || "" },
    { key: "packageVariant", value: product?.packageVariant?.value || "" },
    {
      key: "packageVariantQuantity",
      value: product?.packageVariantQuantity?.value || ""
    },
    {
      key: "packageVariantPrice",
      value: product?.packageVariantPrice?.value || ""
    },
    { key: "availability", value: `${product?.availability ?? "0"}` },
    {
      key: "publicationDate",
      value: product?.publicationDate?.toString?.() || ""
    },
    {
      key: "disableDropShadow",
      value: product?.disableDropShadow?.value || ""
    },
    {
      key: "handle",
      value: product?.handle
    }
  ].filter((att) => Boolean(att.value))

  return attribute
}

export const fetchProductByHandle = async (
  handle?: string,
  client?: any
): Promise<any> => {
  try {
    const product = await (client || defaultClient).product.fetchByHandle(
      handle || "blessed-hope-the-book-of-revelation-download"
    )

    return product
  } catch (e) {
    return e
  }
}

export const updateLineItem = async (
  checkoutId: string,
  updatedVariant: UpdatedVariant,
  client?: any
): Promise<any> => {
  if (!checkoutId || !updatedVariant) {
    throw new Error(`Must include a checkoutId and a variantId`)
  }

  const lineItemsToUpdate = [updatedVariant]

  try {
    const cart = await (client || defaultClient).checkout.updateLineItems(
      checkoutId,
      lineItemsToUpdate
    )

    if (cart.userErrors && cart.userErrors.length > 0) {
      const invalidId = cart.userErrors.find(
        ({ message }: { message: string }) => message === "Variant is invalid"
      )

      if (invalidId) {
        return { invalidId: true }
      } else {
        throw new Error(
          `There was a user error: ${JSON.stringify(cart.userErrors)}`
        )
      }
    }

    return cart
  } catch (e) {
    try {
      const error = JSON.parse(e.message)?.[0]

      if (
        error.message === "Line item with id  not found" ||
        error.message === "invalid id"
      ) {
        return { invalidId: true }
      }

      throw new Error(e)
    } catch (parseError) {
      throw new Error(`${parseError}`)
    }
  }
}

export const addLineItem = async (
  checkoutId: string | null,
  variantId: string | string[],
  customAttributes?: Array<CustomAttribute> | MultipleAttributes,
  client?: any
): Promise<any> => {
  if (!checkoutId || !variantId?.length) {
    throw new Error(`Must include a checkoutId and a variantId`)
  }

  const inputIds = typeof variantId === "string" ? [variantId] : variantId

  const lineItems = inputIds.map((id) => {
    const lineItem: LineItem = {
      variantId: id,
      quantity: 1
    }

    if (customAttributes) {
      lineItem.customAttributes = Array.isArray(customAttributes)
        ? customAttributes
        : customAttributes[id] || []
    }

    return lineItem
  })

  try {
    const cart = await (client || defaultClient).checkout.addLineItems(
      checkoutId,
      lineItems
    )

    if (cart.userErrors && cart.userErrors.length > 0) {
      const invalidId = cart.userErrors.find(
        ({ message }: { message: string }) => message === "Variant is invalid"
      )

      if (invalidId) {
        return { invalidId: true }
      } else {
        throw new Error(
          `There was a user error: ${JSON.stringify(cart.userErrors)}`
        )
      }
    }

    return cart
  } catch (e) {
    const error = JSON.parse(e.message)?.[0]

    if (error.message === "invalid id") {
      return { invalidId: true }
    }

    throw new Error(e)
  }
}

export const removeLineItem = async (
  checkoutId: string,
  lineItemId: string,
  client?: any
): Promise<any> => {
  if (!checkoutId || !lineItemId) {
    throw new Error(`Must include a checkoutId and a lineItemId`)
  }

  const lineItems = [lineItemId]

  try {
    const cart = await (client || defaultClient).checkout.removeLineItems(
      checkoutId,
      lineItems
    )

    if (cart.userErrors && cart.userErrors.length > 0) {
      const invalidId = cart.userErrors.find(
        ({ message }: { message: string }) => message === "Variant is invalid"
      )

      if (invalidId) {
        return { invalidId: true }
      } else {
        throw new Error(
          `There was a user error: ${JSON.stringify(cart.userErrors)}`
        )
      }
    }

    return cart
  } catch (e) {
    const error = JSON.parse(e.message)?.[0]

    if (error.message === "invalid id") {
      return { invalidId: true }
    }

    throw new Error(e)
  }
}

export const createCheckout = async (client?: any): Promise<any> => {
  try {
    return await (client || defaultClient).checkout.create()
  } catch (e) {
    throw new Error(e)
  }
}

export const fetchCheckout = async (
  checkoutId?: string | null,
  client?: any
): Promise<any> => {
  if (!checkoutId) {
    throw new Error(`Must include a checkoutId`)
  }

  try {
    const checkout = await (client || defaultClient).checkout.fetch(checkoutId)

    return checkout
  } catch (e) {
    if (
      e?.[0]?.message === `Variable $id of type ID! was provided invalid value`
    ) {
      return { invalidId: true }
    }

    throw new Error(e.message)
  }
}
