import React, { Fragment, FunctionComponent } from "react"
import { useIntl } from "react-intl"
import { BreadcrumbResource, Breadcrumbs } from "../breadcrumbs/breadcrumbs"
import styles from "./footer.module.scss"

export interface FooterBreadcrumbsProps {
  breadcrumbs: BreadcrumbResource[]
}

export const FooterBreadcrumbs: FunctionComponent<FooterBreadcrumbsProps> = ({
  breadcrumbs
}) => {
  const intl = useIntl()

  const translatedBreadcrumbs = breadcrumbs.map(({ label, link }) => {
    if (intl.messages?.[label]) {
      return {
        label: intl.formatMessage({ id: label }),
        link
      }
    }

    return { label, link }
  })

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: translatedBreadcrumbs.map(({ label, link }, index) => {
      const linkWithSlash = link?.startsWith("/") ? link : `/${link}`

      return {
        "@type": "ListItem",
        position: index + 1,
        name: label,
        item:
          index === breadcrumbs.length - 1
            ? undefined
            : `https://www.ligonier.org${linkWithSlash}`
      }
    })
  }

  return (
    <Fragment>
      <div className={styles.footerBreadcrumbs}>
        <div className={styles.breadcrumbsWrapper}>
          <Breadcrumbs items={translatedBreadcrumbs} />
        </div>
      </div>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
      />
    </Fragment>
  )
}
