import { ContentfulTeacher } from "../../../graphql/types"
import { Teacher } from "../../types/teacher"
import { PathPrefix } from "../constants"

export const mapSingleTeacher = (
  teacher: Partial<ContentfulTeacher>
): Teacher => ({
  name: `${teacher!.shortName}`,
  slug: teacher!.slug!,
  link: `${PathPrefix.Teachers}/${teacher!.slug!}`,
  image: teacher!.image ? teacher!.image.fluid?.src : undefined,
  shortBio: teacher!.shortBio ? teacher!.shortBio?.shortBio || "" : undefined
})

export const mapTeachers = (
  teacherNodes: Partial<ContentfulTeacher>[]
): Teacher[] => teacherNodes?.map(mapSingleTeacher) || []

// TODO: This assumes the array is not empty but seems to be used in places where it might be empty.
//       We should do something about it.
export const mapFirstTeacher = (
  teacherNodes: Partial<ContentfulTeacher>[]
): Teacher => mapSingleTeacher(teacherNodes[0])
