import { firstItems } from "../core/utils"

export type possibleNull<T> = T | null | undefined

export type FormatAuthorNamesInputItem =
  | possibleNull<string>
  | {
      name?: string
      shortName?: string
    }

const getName = (item: FormatAuthorNamesInputItem): string => {
  if (typeof item === "string") return item

  return item?.name || item?.shortName || ""
}

export const formatAuthorNames = (names: FormatAuthorNamesInputItem[] = []) => {
  const { first, rest } = firstItems<FormatAuthorNamesInputItem>(names, 1)

  const firstText = getName(first[0])

  const pieces = [firstText]

  if (rest.length) {
    const secondNameText = getName(rest[0])

    const restText = rest.length > 1 ? `${rest.length} others` : secondNameText

    pieces.push(restText)
  }

  return pieces.join(" & ")
}
