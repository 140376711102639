import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { BaseProductVariant } from "../../features/store/store-types"
import { Button } from "../button/button"
import { Link } from "../link/link"
import { Icon } from "../icon/icon"
import { Availability } from "../../features/store/product-availability-source"
import { Spinner } from "../spinner/spinner"
import { useShopifyProduct } from "../../hooks/useShopifyProduct"
import { getCustomAttributes } from "../../utils/shopify-buy"
import styles from "./blocked-content-message.module.scss"
import { MediaType } from "./media-type-switch"
import { useNewCart } from "@contexts/newCartContext"
import { Teacher } from "@src-types/teacher"
import { showCompareAtPrice } from "@core/prices"
import { formatPrice } from "@utils/formatPrice"
import { handleLogin } from "@contexts/authContext"

export interface BlockedContentMessageProps {
  image?: string
  className?: string
  paidContentVariants: BaseProductVariant[]
  onButtonClick: MouseEventHandler
  playlistTeachers: Teacher[]
  mediaType: MediaType
}

const Variant: FunctionComponent<any> = ({ item, teachers }) => {
  const [{ context }, sendCartEvent] = useNewCart()
  const { itemsBeingAdded } = context

  const { isLoading, error, data } = useShopifyProduct(item.handle)

  if (isLoading) {
    return <div>Loading..</div>
  }

  if (error) {
    console.error(`Problem with variant ${item.handle}`, error)

    return null
  }

  const shopProduct = data?.product
  const availability = data?.availability || Availability.unknown
  const defaultVariant = shopProduct?.variants?.[0]
  const price = data?.price || ""
  const compareAtPrice = data?.compareAtPrice || ""

  const itemIsBeingAdded =
    defaultVariant?.storefrontId || item.storefrontId
      ? itemsBeingAdded.includes(
          defaultVariant?.storefrontId || item.storefrontId
        )
      : false

  const addVariantToCart = async () => {
    const customAttributes = getCustomAttributes(
      {
        ...shopProduct,
        title:
          shopProduct?.parentCollection?.title ||
          item.title ||
          shopProduct?.title,
        teachers,
        availability
      },
      {
        color: shopProduct?.color?.value,
        language: shopProduct?.language?.value,
        title: shopProduct?.format?.value,
        quantityAvailable: defaultVariant?.quantityAvailable,
        requiresShipping: defaultVariant?.requiresShipping
      }
    )

    sendCartEvent("ADD_LINE_ITEM", {
      data: {
        variantId: defaultVariant?.storefrontId || item.storefrontId || "",
        customAttributes
      }
    })
  }

  return (
    <li>
      <span className={styles.variantType}>{item.title}</span>
      {showCompareAtPrice(price, compareAtPrice) && (
        <span className={styles.discountedPrice}>
          {formatPrice(compareAtPrice)}
        </span>
      )}
      <span className={styles.price}>{formatPrice(price || item.price)}</span>
      {itemIsBeingAdded ? (
        <span className={styles.addToCart}>
          <Spinner />
        </span>
      ) : (
        <span onClick={addVariantToCart} className={styles.addToCart}>
          <span>
            <FormattedMessage id="store-add-to-cart" />
          </span>
          <Icon variant="12-right-arrow" />
        </span>
      )}
    </li>
  )
}

export const BlockedContentMessage: FunctionComponent<
  BlockedContentMessageProps
> = ({
  className,
  image,
  onButtonClick,
  playlistTeachers,
  paidContentVariants,
  mediaType
}) => {
  const { instance: msalInstance } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  return (
    <div
      className={classNames(styles.blockedContent, className)}
      style={{ backgroundImage: `url("${image}")` }}
    >
      <div
        data-testid="BlockedContentMessageOverlay"
        className={styles.overlay}
      />
      <div className={styles.container}>
        <Button variant="primary" size="sm" onClick={onButtonClick}>
          <FormattedMessage
            id={`teaching-series-${
              mediaType === "video" ? "watch" : "listen"
            }-first-message`}
          />
        </Button>
        <div className={styles.info} data-testid="BlockedContentMessageText">
          <p>
            In addition to supporting the global outreach of Ligonier, Ministry{" "}
            Partners also have complete streaming access to our entire teaching{" "}
            series library.{" "}
            <Link
              className={styles.link}
              to="https://donate.ligonier.org/pledge/?utm_campaign=mpstreaming&utm_source=ligonierwebpaywall"
            >
              Learn more
            </Link>
            {!isAuthenticated && (
              <>
                {" "}
                or{" "}
                <Link
                  className={classNames(styles.authLink, styles.link)}
                  to=""
                  onClick={() => handleLogin(msalInstance)}
                >
                  sign in
                </Link>
              </>
            )}
            .
          </p>
          <p>Or purchase this series to unlock streaming for its messages.</p>
        </div>
        {!!paidContentVariants.length && (
          <ul className={styles.purchaseVariants}>
            {paidContentVariants.map((item) => {
              return (
                <Variant
                  teachers={playlistTeachers}
                  item={item}
                  key={item.id}
                />
              )
            })}
          </ul>
        )}
        <p className={styles.help}>
          <FormattedMessage id="teaching-series-need-help" />{" "}
          <Link to="/faqs/?fields.topic=Downloads">
            <FormattedMessage id="teaching-series-see-faq" />
          </Link>
        </p>
      </div>
    </div>
  )
}
