import React, { FunctionComponent } from "react"
import { useIntl } from "react-intl"
import { Tabs, TabItem } from "../tabs/tabs"
import { Carousel } from "../carousel/carousel"
import { Product } from "../product/product"
import { PathPrefix } from "../../core/constants"
import { RelatedProduct } from "../../types/related-product"
import styles from "./related-products.module.scss"

export type RelatedProductGroups = Record<string, RelatedProduct[]>

export interface RelatedProductsProps {
  products: RelatedProductGroups
  title?: string
  subtitle?: string
  renderLabel?: (label: string) => string
  limit?: number
}

export const RelatedProducts: FunctionComponent<RelatedProductsProps> = ({
  products,
  title = "store-related-products-header",
  subtitle,
  renderLabel = (label) => label,
  limit
}) => {
  const intl = useIntl()
  const { formatMessage } = intl

  return (
    <div className={styles.relatedProducts}>
      <Tabs
        title={formatMessage({ id: title })}
        subtitle={subtitle}
        variant="responsive"
        contentClassName={styles.tabsContentWrapper}
      >
        {Object.keys(products).map((productType) => {
          const productsToDisplay = products[productType].slice(
            0,
            limit || products[productType].length
          )

          return (
            <TabItem label={renderLabel(productType)} key={productType}>
              <div className={styles.tabContent}>
                <Carousel itemClassName={styles.carouselItem}>
                  {productsToDisplay.map((item) => (
                    <Product
                      key={item.title}
                      author={item.author ? item.author.name : ""}
                      authorLink={
                        item.author
                          ? `${PathPrefix.Teachers}/${item.author.slug}`
                          : ""
                      }
                      image={item.image}
                      handle={item.handle || ""}
                      url={item.link}
                      size="sm"
                      title={item.title}
                      format={item.format}
                      variant="flat"
                    />
                  ))}
                </Carousel>
              </div>
            </TabItem>
          )
        })}
      </Tabs>
    </div>
  )
}
