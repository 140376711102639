import { IntlShape } from "react-intl"
import { getScriptureTranslationId } from "../scripture-utils"
// import { Maybe } from "../../../../graphql/types"

type Maybe<T> = T | null

export interface ContentfulVerseReference {
  book?: string
  chapter?: number
  verse?: Maybe<number>
}

export interface ContentfulScriptureReference {
  start?: ContentfulVerseReference
  end?: ContentfulVerseReference
}

export interface VerseReference {
  book: string
  chapter: number
  verse: Maybe<number>
}

export interface ScriptureReference {
  start: VerseReference
  end: Partial<VerseReference>
  slug: string
}

const pluralBook = new RegExp(/^((p|P)salms)/)

/*NOTE:
  Contentful: at the moment scripture references can only be
  in this format: {Book} {Chapter}(number:number(-|,)number) 

  if this ever changes, we would need to update the RegExp
*/
const singularVerse = new RegExp(/(\d+((:\d+)|(:\d+(\W)\d+))?)$/)

export const formatSingularBook = (scriptureBook: string) => {
  scriptureBook = scriptureBook.trim()

  const isPluralBook = pluralBook.test(scriptureBook)

  if (isPluralBook) {
    scriptureBook = scriptureBook.replace(/^(p|P)salms/, "Psalm")
  }

  return scriptureBook
}

export const formatSingularScripture = (scriptureString: string): string => {
  scriptureString = scriptureString.trim()

  const whiteSpace = new RegExp(/\s+/)

  const pluralBookSingularScripture = new RegExp(
    pluralBook.source + whiteSpace.source + singularVerse.source
  )

  const isIncorrectPhrasing = pluralBookSingularScripture.test(scriptureString)

  if (isIncorrectPhrasing) {
    scriptureString = scriptureString.replace(/^(p|P)salms/, "Psalm")
  }

  return scriptureString
}

export const getBookLocaliser =
  (formatMessage: (descriptor: { id: string }) => string) =>
  (key: string): string =>
    formatMessage({ id: getScriptureTranslationId(key) })

const formatVerseReference = (
  { book, chapter, verse }: Partial<VerseReference>,
  getLocalisedBook: (key: string) => string
) => {
  let result = getLocalisedBook(book || "")

  if (chapter) result += ` ${chapter}`
  if (verse) result += `:${verse}`

  return result.trim()
}

// NOTE: The dashes here should be en-dashes, not em-dashes, hyphens or minuses
export const formatScriptureReference = (
  { start, end }: Pick<ScriptureReference, "start" | "end">,
  getLocalisedBook: (key: string) => string
): string => {
  let formattedText: string

  // format the book strings so we don't have unwanted '-' in the UI
  start.book = start.book?.replace(/-/g, " ")

  if (end) {
    end.book = end?.book?.replace(/-/g, " ")
  }

  if (!end || !end.book) {
    formattedText = `${formatVerseReference(start, getLocalisedBook)}`
  } else if (start.book !== end.book) {
    formattedText = `${formatVerseReference(
      start,
      getLocalisedBook
    )} – ${formatVerseReference(end, getLocalisedBook)}`
  } else if (start.verse && end.verse && start.chapter !== end.chapter) {
    formattedText = `${getLocalisedBook(start.book)} ${start.chapter}:${
      start.verse
    } – ${end.chapter}:${end.verse}`
  } else if (start.verse && end.verse && start.verse !== end.verse) {
    formattedText = `${getLocalisedBook(start.book)} ${start.chapter}:${
      start.verse
    }–${end.verse}`
  } else if (
    !start.verse &&
    !end.verse &&
    start.chapter &&
    end.chapter &&
    start.chapter !== end.chapter
  ) {
    formattedText = `${getLocalisedBook(start.book)} ${start.chapter}-${
      end.chapter
    }`
  } else {
    formattedText = formatVerseReference(start, getLocalisedBook)
  }

  return formatSingularScripture(formattedText)
}

export const formatScriptureReferenceSlug = ({
  start: { book, chapter }
}: Pick<ScriptureReference, "start">): string =>
  (chapter ? `${book}_${chapter}` : book || "").toLowerCase()

// NOTE: Trivial mapper - for consistency's sake only.
export const mapVerseReference = (
  input?: Partial<ContentfulVerseReference>
): Partial<VerseReference> => ({ ...input })

export const mapScriptureReference = (
  input: ContentfulScriptureReference
): ScriptureReference => {
  const partial = {
    start: mapVerseReference(input.start),
    end: mapVerseReference(input.end)
  }

  return {
    ...(partial as ScriptureReference),
    slug: formatScriptureReferenceSlug(partial as ScriptureReference)
  }
}

export const getTranslateableScripture =
  (scriptureReference: ScriptureReference) =>
  (intl: IntlShape): string =>
    formatScriptureReference(
      scriptureReference,
      getBookLocaliser(intl.formatMessage)
    )
