import { useState } from "react"
import { navigate } from "gatsby"
import isEqual from "lodash/isEqual"
import { SearchIndexes, SearchState } from "../search-types"
import { queryStringToSearchState, searchStateToUrl } from "../search-helpers"

interface UseSearchStateResult {
  handleSearchStateChange(newSearchState: SearchState): void
  searchState: SearchState
  setSearchState(newSearchState: SearchState): void
}

export function useSearchState(
  location: Location,
  searchIndexId: string,
  allowedIndexes?: SearchIndexes
): UseSearchStateResult {
  const [searchState, setSearchState] = useState<SearchState>(
    queryStringToSearchState(location?.search, searchIndexId, allowedIndexes)
  )

  const newState = queryStringToSearchState(
    location?.search,
    searchIndexId,
    allowedIndexes
  )

  if (!isEqual(searchState, newState)) {
    setSearchState(newState)
  }

  const handleSearchStateChange = (state: SearchState): void => {
    navigate(searchStateToUrl(location, state, searchIndexId), {
      replace: true
    })
  }

  return {
    handleSearchStateChange,
    searchState,
    setSearchState
  }
}
