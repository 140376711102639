import React, { FunctionComponent } from "react"
import { useQuery } from "react-query"
import styles from "./recommendations.module.scss"
import { Carousel } from "@components/carousel/carousel"
import { Link } from "@components/link/link"
import { CardRecommendation } from "@components/recommendations/recommendation-card"
import { Markdown } from "@components/markdown/markdown"
import { Skeleton } from "@components/skeleton/skeleton"
import { CardRecomendationsSkeleton } from "@components/card/card-recomendations-skeleton"
import { useAuth } from "@contexts/authContext"
import {
  fetchRecommendations,
  RecommendationScenario
} from "@hooks/useRecombee"

type RecombeeTypes = "topic" | "teacher"
export interface RecommendationsProps {
  recombeeConfig: {
    type: RecombeeTypes
    itemId?: string
    slug: string
    idsToRemove: string[]
  }
  title: string
  overline: string
  body?: string | null
  link: string
}

const recombeeTypeMap: Record<RecombeeTypes, RecommendationScenario> = {
  topic: "More-From-This-Topic",
  teacher: "More-From-This-Teacher"
}

export const Recommendations: FunctionComponent<RecommendationsProps> = ({
  recombeeConfig: config,
  title,
  overline,
  body,
  link
}) => {
  const [state] = useAuth()
  const { userDetails } = state.context
  const userId = userDetails?.sub

  const { isLoading, error, data } = useQuery(
    [`recommendations: ${config.type}-${config.itemId}-${userId}`],
    () =>
      fetchRecommendations(recombeeTypeMap[config.type], {
        itemId: config.itemId,
        userId,
        count: 3,
        idsToRemove: config.idsToRemove
      })
  )

  if (error) return null

  if (isLoading) {
    return (
      <div>
        <div className={styles.relatedResourcesSkeleton}>
          <div className={styles.skeletonTextContent}>
            <div className={styles.skeletonDescription}>
              <Skeleton />
              <h3 className={styles.title}>
                <div>
                  <Skeleton />
                </div>
              </h3>
              <div className={styles.skeletonBody && styles.body}>
                <Skeleton count={3} width={"100%"} />
              </div>
            </div>
          </div>
          <div className={styles.carouselSkeletonWrapper}>
            <div className={styles.carousel}>
              <CardRecomendationsSkeleton />
              <CardRecomendationsSkeleton />
              <CardRecomendationsSkeleton />
            </div>
          </div>
        </div>
        <div className={styles.border}></div>
      </div>
    )
  }

  const recombeeData = data?.recomms

  if (!recombeeData?.length) return null

  return (
    <div className={styles.relatedResources}>
      <div className={styles.textContent}>
        <div className={styles.description}>
          <p className={styles.label}>{overline}</p>
          <h3 className={styles.title}>
            <Link to={link}>{title}</Link>
          </h3>
          {body && <Markdown content={body} className={styles.body} />}
        </div>
      </div>
      <div className={styles.carouselWrapper}>
        <Carousel className={styles.carousel}>
          {recombeeData.map((content) => (
            <CardRecommendation key={content.id} content={content.values} />
          ))}
        </Carousel>
      </div>
    </div>
  )
}
