import React from "react"
import classNames from "classnames"
import styles from "./base-layout.module.scss"
import { Link } from "@components/link/link"
import { Button } from "@components/button/button"

export interface YearEndProps {
  headline: JSX.Element
  subhead?: JSX.Element
  buttonText: string
  buttonLink: string
  buttonSupportingCopy?: JSX.Element
  image?: JSX.Element
  imageShouldLink?: boolean
  className?: string
  infoClassName?: string
  mediaClassName?: string
  headerClassName?: string
  supportingCopyClassName?: string
}

export default function YearEndBaseLayout({
  headline,
  subhead,
  buttonText,
  buttonLink,
  buttonSupportingCopy,
  image,
  imageShouldLink,
  className,
  infoClassName,
  mediaClassName,
  headerClassName,
  supportingCopyClassName
}: YearEndProps) {
  const MediaComponent = imageShouldLink && buttonLink ? Link : "div"

  return (
    <div className={classNames(styles.yearEndWrapper, className)}>
      {image && (
        <MediaComponent to={buttonLink} className={mediaClassName}>
          {image}
        </MediaComponent>
      )}
      <div className={classNames(styles.info, infoClassName)}>
        <div className={classNames(styles.headingWrapper, headerClassName)}>
          {headline}
          {subhead && subhead}
        </div>
        <div className={styles.buttonAreaWrapper}>
          <Button
            to={buttonLink}
            variant="primary"
            size="md"
            className={styles.button}
          >
            {buttonText}
          </Button>
          {buttonSupportingCopy && (
            <div
              className={classNames(
                styles.supportingCopy,
                supportingCopyClassName
              )}
            >
              {buttonSupportingCopy}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
