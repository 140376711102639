import { AudioFragment } from "../../graphql/types"

export const getAudioUrl = (
  audio: AudioFragment | null | undefined
): string | undefined => {
  if (audio?.externalAudioUrl) {
    return audio.externalAudioUrl
  }

  if (audio?.uploadAudio) {
    return audio.uploadAudio.file!.url!
  }

  return undefined
}
