import { navigate } from "gatsby"
import React, { Fragment, FunctionComponent, useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { Event } from "../../../events/event-types"
import { Conference, ConferenceVideoData } from "../../conference-types"
import { PlayerWithPlaylist } from "../../../../components/player-with-playlist/player-with-playlist"
import { EventsList } from "../../../../components/events-list/events-list"
import { ConferenceDetails } from "../conference-details/conference-details"
import { ConferenceHeader } from "../conference-header/conference-header"
import { PathPrefix } from "../../../../core/constants"
import { PlaylistItem } from "../../../../types/playlist-item"
import {
  RelatedProductGroups,
  RelatedProducts
} from "../../../../components/related-products/related-products"
import { PlayerContent } from "../../../../components/player/player"
import { ContentLockServiceProvider } from "../../../../contexts/service-context"
import { ContentLockBootstrapDataKey } from "../../../../machines/contentLockMachine.machine"
import recomStyles from "@components/recommendations/recommendations.module.scss"
import { Recommendations } from "@components/recommendations/recommendations"

export interface ConferencePageProps {
  isConferenceStartPage: boolean
  conference: Conference
  upcomingEvents: Event[]
  relatedProducts: RelatedProductGroups | null
  contentfulId: string
  seriesContentfulId: string
  mediaId?: string
}

export const ConferencePage: FunctionComponent<ConferencePageProps> = ({
  isConferenceStartPage,
  conference,
  upcomingEvents,
  relatedProducts,
  contentfulId,
  seriesContentfulId,
  mediaId
}) => {
  const { formatMessage } = useIntl()

  const items = useMemo(() => {
    return conference.playlist.map((item) => ({
      ...item,
      id: item.mediaId,
      meta: item.meta || ""
    }))
  }, [conference.playlist])

  const onItemClick = useCallback(
    (item: PlaylistItem<ConferenceVideoData>) => {
      const url = `${PathPrefix.Conferences}/${conference.slug}/${item.slug}`

      navigate(url)
    },
    [conference.slug]
  )

  const playerContent: PlayerContent = {
    title: conference.currentVideo.title || "",
    description: conference.metadata.description as string,
    program: conference.title
  }

  const { primaryTopic, teacher } = conference.currentVideo

  return (
    <ContentLockServiceProvider
      contentLockKey={ContentLockBootstrapDataKey.conference}
    >
      <Fragment>
        <ConferenceHeader {...conference} contentfulId={seriesContentfulId} />
        <PlayerWithPlaylist
          mediaId={conference.currentVideo.mediaId}
          items={items}
          onItemClick={onItemClick}
          playerContent={playerContent}
          playlistTitleOnMobile={conference.title}
          image={conference.metadata.openGraphImage}
        />
        <ConferenceDetails
          conference={conference}
          contentfulId={contentfulId}
          isConferenceStartPage={isConferenceStartPage}
        />
        {relatedProducts && (
          <RelatedProducts
            products={relatedProducts}
            renderLabel={(label) =>
              formatMessage({ id: `store-product-productType-${label}` })
            }
          />
        )}
        <EventsList list={upcomingEvents} variant="secondary" />
        <div className={recomStyles.relatedResourcesWrapper}>
          {teacher?.slug && (
            <Recommendations
              recombeeConfig={{
                type: "teacher",
                slug: teacher.slug,
                idsToRemove: mediaId ? [mediaId] : [],
                itemId: conference.currentVideo.contentfulId
              }}
              title={teacher.name}
              overline="More from this teacher"
              body={teacher.shortBio || ""}
              link={`${PathPrefix.Teachers}/${teacher.slug}`}
            />
          )}
          {primaryTopic?.slug && (
            <Recommendations
              recombeeConfig={{
                type: "topic",
                slug: primaryTopic.slug,
                idsToRemove: mediaId ? [mediaId] : [],
                itemId: conference.currentVideo.contentfulId
              }}
              title={primaryTopic.name || ""}
              overline="More on this topic"
              body={primaryTopic.description?.description}
              link={`${PathPrefix.Topics}/${primaryTopic.slug}`}
            />
          )}
        </div>
      </Fragment>
    </ContentLockServiceProvider>
  )
}
