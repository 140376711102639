import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Icon } from "../icon/icon"
import { ListItem, ListItemProps } from "./list-item"
import styles from "./list.module.scss"
import { AvatarList, AvatarListItemProps } from "@components/avatar/avatar-list"
import { DevotionalTeachers } from "@src-types/algolia"
import { Avatar } from "@components/avatar/avatar"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DevotionalTeacherItems extends AvatarListItemProps {}

export interface ListItemDevotionalProps extends ListItemProps {
  image?: string
  source?: string
  time?: string
  title: string
  /* show consider making 'teachers' prop required, but
   * allowed to resolve to 'undefined' in future. Creating
   * optional field ATM to sidestep storybook story mocks
   * */
  teachers?: DevotionalTeachers[] | undefined
}

export const ListItemDevotional: FunctionComponent<ListItemDevotionalProps> = ({
  className,
  disabled = false,
  image,
  teachers,
  time,
  title,
  url,
  source
}) => {
  const singularAvatarProps = {
    image: teachers?.[0].fields?.image?.fields?.file?.url,
    name: teachers?.[0].fields?.shortName
  }

  const pluralAvatarItems = teachers?.map<DevotionalTeacherItems>(
    ({ fields }) => ({
      name: fields?.shortName,
      slug: fields?.slug,
      link: "",
      image: fields?.image?.fields?.file?.url
    })
  )

  const isSingularTeacher = teachers && teachers?.length === 1
  const isPluralTeacher = teachers && teachers?.length > 1

  return (
    <ListItem
      className={classNames(styles.listItemDevotional, className)}
      disabled={disabled}
      url={url}
    >
      <div className={styles.content}>
        <div className={styles.subheader}>
          <Icon variant="12-book" className={styles.icon} />
          {time ? <span className={styles.time}>{time}</span> : null}
        </div>
        <h2 className={styles.header}>{title}</h2>
        {teachers ? (
          <div className={styles.avatar}>
            {isSingularTeacher && (
              <Avatar showName size="xs" {...singularAvatarProps} />
            )}
            {isPluralTeacher && (
              <AvatarList
                avatars={pluralAvatarItems as DevotionalTeacherItems[]}
                size="xs"
                showNames
              />
            )}
          </div>
        ) : (
          source && <div className={styles.scriptureRef}>{source}</div>
        )}
      </div>
      <div className={styles.side}>
        <div
          className={classNames(styles.image, !image && styles.placeholder)}
          style={{
            backgroundImage: `url(${
              image || "/assets/image-placeholders/list-logo.svg"
            })`
          }}
        />
      </div>
    </ListItem>
  )
}
