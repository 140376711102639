import React, {
  FunctionComponent,
  useCallback,
  useRef,
  useState,
  useLayoutEffect
} from "react"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import classNames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"
import { FormattedHTMLMessage } from "../../../../components/formatted-html-message/formatted-html-message"
import { Menu } from "../../../../components/menu/menu"
import { Button } from "../../../../components/button/button"
import { useHeaderNavigation } from "../../../navigation/hooks/use-header-navigation"
import { Link } from "../../../../components/link/link"
import HomePageYearEnd from "../home-page-year-end/home-page-year-end"
import { YearEndContent } from "../../home-page-types"
import styles from "./home-page-header.module.scss"
import { HeaderAuth } from "@components/header/header-auth"
import { HeaderCart } from "@components/header/header-cart"
import { HeaderSearch } from "@components/header/header-search"
import { HeaderDonate } from "@components/header/header-donate-button"

const menuDuration = parseInt(styles.menuDuration, 10)
const mobileMaxWidth = parseInt(styles.mobileMaxWidth, 10)

const scrollThreshold = -50

interface HomePageHeaderProps {
  isInfoIconShown?: boolean
  yearEndContent?: YearEndContent | null
}

export const HomePageHeader: FunctionComponent<HomePageHeaderProps> = ({
  yearEndContent
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isMenuAnimating, setMenuAnimating] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const menuTimeout = useRef(0)

  const { formatMessage } = useIntl()

  useScrollPosition(
    ({ currPos }) => {
      setIsScrolled(currPos.y < scrollThreshold)
    },
    [],
    undefined,
    false,
    50
  )

  useLayoutEffect(() => {
    if (window.scrollY > -1 * scrollThreshold) {
      setIsScrolled(true)
    }
  }, [])

  const { sections: menuSections, subfooter } = useHeaderNavigation()

  const onMenuAnimated = useCallback(() => {
    setMenuAnimating(false)
  }, [])

  const toggleMenu = useCallback(() => {
    setMenuOpen(!isMenuOpen)
    setMenuAnimating(true)
    menuTimeout.current = window.setTimeout(onMenuAnimated, menuDuration)
  }, [isMenuOpen, onMenuAnimated])

  const handleScrollSearchBox = useCallback(() => {
    if (window.innerWidth <= mobileMaxWidth) {
      const searchWrapper = document.getElementById("search-wrapper")

      if (searchWrapper) {
        const isMobileSafari = window.navigator.userAgent.match(/iPhone/i)
        const topOffset = isMobileSafari
          ? searchWrapper.offsetTop
          : searchWrapper.offsetTop - 65

        window.scrollTo({
          left: 0,
          top: topOffset,
          behavior: "smooth"
        })
      }
    }
  }, [])

  return (
    <div
      className={classNames(
        styles.headerWrapper,
        yearEndContent && styles.removeHeight
      )}
    >
      <div
        className={classNames(
          styles.innerWrapper,
          isScrolled && styles.scrolled
        )}
      >
        <header
          className={classNames(
            styles.header,
            styles.homePageHeader,
            isMenuOpen && styles.menuOpen,
            isMenuAnimating && styles.menuAnimating,
            isScrolled && styles.scrolled
          )}
        >
          <div className={styles.container}>
            <Button
              className={styles.menuButton}
              onClick={toggleMenu}
              size="sm"
              variant="secondary"
              dataTestId="HomePageMenuButton"
            >
              <FormattedMessage
                id={isMenuOpen ? "header-close" : "header-menu"}
              />
            </Button>
            <h1>
              <Button className={styles.logoButton} icon="24-logo" to="/">
                <span>
                  Ligonier Ministries The teaching fellowship of R.C. Sproul
                </span>
              </Button>
            </h1>
          </div>
          <div className={styles.container}>
            <div className={styles.topLevelMenuItems}>
              {menuSections.map((section, index) => (
                <Link to={section.url!} key={index}>
                  {section.name}
                </Link>
              ))}
            </div>
          </div>
          <div className={styles.container}>
            <HeaderCart className={styles.cartButton} forceShow={isMenuOpen} />
            <span className={styles.rightButtons}>
              <HeaderAuth
                variant={isScrolled ? "homepage_scrolled" : "homepage"}
              />
              <HeaderDonate className={styles.donateButton} />
            </span>
          </div>
          <div
            className={classNames(
              styles.menuWrapper,
              isMenuOpen && styles.menuOpen,
              isMenuAnimating && styles.menuAnimating
            )}
          >
            <Menu
              className={styles.menu}
              onClickLink={toggleMenu}
              sections={menuSections}
              subfooter={subfooter}
            />
            <div className={styles.tint} onClick={toggleMenu} />
          </div>
        </header>
      </div>
      {!yearEndContent ? (
        <div className={styles.contentWrapper}>
          <h1 className={styles.title}>
            <FormattedHTMLMessage id="homepage-title" />
          </h1>
          <div className={styles.search} id="search-wrapper">
            <HeaderSearch
              dataTestId="HomePageHeaderSearch"
              className={styles.searchBox}
              onKeyDown={handleScrollSearchBox}
              placeholder={formatMessage({ id: "header-search-placeholder" })}
              variant="minimal"
            />
          </div>
        </div>
      ) : (
        <div className={styles.yearEndWrapper}>
          <HomePageYearEnd data={yearEndContent} />
        </div>
      )}
    </div>
  )
}
