import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { useGlobalPromotion } from "../hooks/use-global-promotion"
import styles from "./global-promotion-cta.module.scss"
import { GenericSidebarCTA } from "@components/generic-sidebar-cta/generic-sidebar-cta"

export interface GlobalPromotionCTAProps {
  className?: string
  disableDesktopStyles?: boolean
}

export const GlobalPromotionCTA: FunctionComponent<GlobalPromotionCTAProps> = ({
  className,
  disableDesktopStyles
}) => {
  const globalPromo = useGlobalPromotion()

  return (
    <GenericSidebarCTA
      className={className}
      subHeadingClassName={classNames(styles.subheading, {
        [styles.disableDesktopStyles]: disableDesktopStyles
      })}
      heading={globalPromo?.title.title}
      subheading={globalPromo?.description.description}
      buttonLabel={globalPromo?.linkText.linkText}
      buttonUrl={globalPromo?.linkUrl}
      variant={globalPromo?.useButtonStyle ? "regularButton" : "textButton"}
      image={globalPromo?.image?.fluid}
      disableDesktopStyles={disableDesktopStyles}
    />
  )
}
