import { useFlags } from "launchdarkly-react-client-sdk"
import { useEffect, useState } from "react"
import {
  FeatureFlags,
  FeatureFlagKeys,
  FeatureFlagIndexAccessors
} from "./feature-flag-constants"

const searchInvalidKeys = (keyMap: FeatureFlags): string[] =>
  Object.keys(FeatureFlagIndexAccessors).filter(
    (key) => !Object.keys(keyMap).includes(key)
  )

type FlagType = FeatureFlags[FeatureFlagKeys] | null

/* EXAMPLE:
 * useFeatureFlag("contentLock") // "contentLockOn" | "contentLockOff" | "contentLockMaintenance"
 *
 *  TODO: create `searchInvalidValues` utility function
 */
export const useFeatureFlag = (
  key: FeatureFlagKeys //
): FlagType => {
  const [featureFlag, setFeatureFlag] = useState<FlagType>(null)
  const flags = useFlags() as FeatureFlags

  useEffect(() => {
    if (flags) {
      const invalidKeys = searchInvalidKeys(flags)

      if (invalidKeys.length) {
        console.warn(
          `the following feature flags do not exist in LaunchDarkly: ${invalidKeys}`
        )
      } else {
        setFeatureFlag(flags[key])
      }
    }
  }, [flags, key])

  return featureFlag
}
