import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { Icon, IconVariant } from "../icon/icon"
import { SearchResultType } from "../../types/search"
import { ListItem, ListItemProps } from "./list-item"
import styles from "./list.module.scss"
import { useShopifyProduct } from "@hooks/useShopifyProduct"
import { Skeleton } from "@components/skeleton/skeleton"
import { formatPrice } from "@utils/formatPrice"

export interface ListItemInstantSearchResultProps extends ListItemProps {
  subtitle?: string | JSX.Element
  image?: string
  imageMeta?: string | JSX.Element
  meta: string
  title: string
  handle: string
  type: SearchResultType
  onClick?: MouseEventHandler
}

const typeIcon: {
  [type in SearchResultType]?: IconVariant
} = {
  article: "12-article",
  collection: "12-collection",
  series: "12-video",
  devotional: "12-book",
  product: "12-tag",
  productCollection: "12-tag",
  mediaResource: "12-video",
  event: "12-calendar",
  podcastEpisode: "12-audio",
  podcastShow: "12-audio",
  page: "12-page"
}

export const ListItemInstantSearchResult: FunctionComponent<
  ListItemInstantSearchResultProps
> = ({
  className,
  image,
  imageMeta,
  meta,
  title,
  type,
  subtitle,
  url,
  handle,
  onClick,
  isHighlighted
}) => {
  const { isLoading, error, data } = useShopifyProduct(handle)

  const isProduct = type.toLowerCase() === "product"

  if (isProduct && error) {
    return null
  }

  const disableShadow = data?.product?.disableDropShadow?.value === "true"

  if (!url) {
    return null
  }

  const imageText = isProduct ? `${formatPrice(data?.price || "")}` : imageMeta

  return (
    <ListItem
      className={classNames(
        styles.listItemInstantSearchResult,
        styles[`type${type}`],
        className
      )}
      url={url}
      tag="div"
      onClick={onClick}
      isHighlighted={isHighlighted}
    >
      <div className={styles.content}>
        <div className={styles.subheader}>
          {typeIcon[type] ? (
            <Icon variant={typeIcon[type]!} className={styles.icon} />
          ) : null}
          <span className={styles.source}>{meta}</span>
        </div>
        <h2 className={styles.header}>{title}</h2>
        <div className={styles.subheader}>{subtitle}</div>
      </div>
      <div className={styles.side}>
        {isProduct && image && (
          <img
            className={classNames(styles.productImage, {
              [styles.disableShadow]: disableShadow
            })}
            src={image}
            alt=""
          />
        )}
        <div
          className={classNames(styles.image, !image && styles.placeholder)}
          style={{
            backgroundImage: `url(${
              image || "/assets/image-placeholders/list-logo.svg"
            })`
          }}
        />
        {imageMeta ? (
          isProduct && isLoading ? (
            <Skeleton
              className={styles.priceSkeleton}
              width={`40px`}
              height={`25.2px`}
            />
          ) : (
            <div className={styles.imageMeta}>{imageText}</div>
          )
        ) : null}
      </div>
    </ListItem>
  )
}
