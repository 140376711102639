import React, { FunctionComponent } from "react"
import { useIntl } from "react-intl"

export interface FormattedHTMLMessageProps {
  id: string
  values?: Record<string, string | number>
}

export const FormattedHTMLMessage: FunctionComponent<
  FormattedHTMLMessageProps
> = ({ id, values }) => {
  const { formatMessage } = useIntl()

  return (
    <span dangerouslySetInnerHTML={{ __html: formatMessage({ id }, values) }} />
  )
}
