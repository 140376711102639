import React, {
  forwardRef,
  Fragment,
  useContext,
  useEffect,
  useState
} from "react"
import classNames from "classnames"
import { Icon } from "../icon/icon"
import { Avatar } from "../avatar/avatar"
import { Progress } from "../progress/progress"
import { clamp } from "../../core/utils"
import { AvatarList, AvatarListItemProps } from "../avatar/avatar-list"
import {
  ContentLockBootstrapDataKey,
  ContentLockData,
  ContentLockMachineProps
} from "../../machines/contentLockMachine.machine"
import { ListItem, ListItemProps } from "./list-item"
import styles from "./list.module.scss"
import {
  IServiceContext,
  useContentLockServiceContext
} from "@contexts/service-context"
import buildImageUrl from "@utils/imageUrl"
import { ContentLockValues } from "@features/feature-flag-constants"
import { useFeatureFlag } from "@features/use-feature-flags"
import { Spinner } from "@components/spinner/spinner"

interface SimpleTeacher {
  name: string
  image?: string
}

export interface ListItemVideoProps extends Omit<ListItemProps, "onClick"> {
  teacher: SimpleTeacher
  teachers?: SimpleTeacher[]
  id: string
  image?: string
  isPlaying?: boolean
  onClick?: (id: string) => void
  progress?: number
  meta: string
  itemNumber: number
  title: string
  ligCode: string | undefined
  videoId?: string
  audioUrl?: string
  isFirstItem: boolean
}

export const ListItemVideo = forwardRef<HTMLDivElement, ListItemVideoProps>(
  (
    {
      teacher,
      teachers,
      className,
      id,
      image,
      isPlaying,
      onClick,
      progress = 0,
      meta,
      itemNumber,
      title,
      videoId,
      audioUrl,
      isFirstItem
    },
    forwardedRef
  ) => {
    const { ContentLockContext, contextKey } = useContentLockServiceContext()
    const {
      contentLockService,
      isLoading,
      canViewPremiumSeries,
      alwaysUnlocked
    } = useContext(ContentLockContext as React.Context<IServiceContext>)

    const [contentLockContext, setContentLockContext] =
      useState<ContentLockData | null>(null)

    useEffect(() => {
      if (contentLockService) {
        const contentLockSubscription = contentLockService.subscribe(
          (state: { context: ContentLockMachineProps }) => {
            setContentLockContext(
              state.context[contextKey as ContentLockBootstrapDataKey]
            )
          }
        )

        return () => {
          contentLockSubscription.unsubscribe()
        }
      }
    }, [contentLockService])

    const progressValue = clamp(progress, 0, 1)

    const handleClick = () => {
      onClick?.(id)
    }

    const imageUrl = buildImageUrl(image || "", { height: 100 })
    const hasMedia = Boolean(videoId || audioUrl)
    const metaInfo = hasMedia ? meta : "Not Available"

    const contentLockedState = useFeatureFlag("contentLock")
    const isContentUnlocked =
      contentLockedState === ContentLockValues.contentLockOff ||
      canViewPremiumSeries

    const isLockIconVisible =
      !alwaysUnlocked && !isFirstItem && !isContentUnlocked && hasMedia
    const isContentViewable =
      (isContentUnlocked || isFirstItem || alwaysUnlocked) &&
      !contentLockContext?.isMaintenanceMode
    // alwaysUnlocked and isFirstItem aren't async vars so we don't care about isLoading
    // if those are true, we don't want to show a loading spinner if the content is going
    // to show anyway
    const showLoading = !alwaysUnlocked && !isFirstItem && isLoading

    return (
      <ListItem
        className={classNames(
          styles.listItemVideo,
          isPlaying && styles.isPlaying,
          className
        )}
        onClick={handleClick}
        dataTestId={`ListItemVideo-${itemNumber}`}
      >
        <div className={styles.content} ref={forwardedRef}>
          <div className={styles.subheader}>
            <Icon variant="12-video" className={styles.icon} />
            {/* leaving meta container here for now. We may need it later...*/}
            {metaInfo && <span className={styles.meta}>{metaInfo}</span>}
          </div>
          <div className={classNames(styles.header, "esv-crossref-ignore")}>
            {`${itemNumber}. ${title}`}
          </div>
          <div className={styles.subheader}>
            {teachers && teachers?.length > 1 ? (
              <AvatarList
                avatars={teachers as AvatarListItemProps[]}
                size="xs"
              />
            ) : (
              <Avatar
                image={teacher.image}
                name={teacher.name}
                showName
                size="xs"
              />
            )}
          </div>
        </div>
        <div className={styles.side}>
          <div
            className={classNames(
              styles.image,
              !imageUrl && styles.placeholder
            )}
            style={{
              backgroundImage: `url(${
                imageUrl || "/assets/image-placeholders/list-logo.svg"
              })`
            }}
          >
            {isPlaying && hasMedia && isContentViewable && !showLoading && (
              <Fragment>
                <Icon variant="16-play" />
                {Boolean(progress) && (
                  <span className={styles.progressWrapper}>
                    <Progress
                      value={progressValue}
                      className={styles.progress}
                    />
                  </span>
                )}
              </Fragment>
            )}
            {showLoading ? (
              <Spinner />
            ) : (
              isLockIconVisible && <Icon variant="16-lock" />
            )}
          </div>
        </div>
      </ListItem>
    )
  }
)

ListItemVideo.displayName = "ListItemVideo"
