import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Link } from "../link/link"
import styles from "./breadcrumbs.module.scss"

export interface BreadcrumbResource {
  label: string
  link?: string
}

export type BreadcrumbSize = "sm" | "md"

export interface BreadcrumbsProps {
  className?: string
  items: BreadcrumbResource[]
  size?: BreadcrumbSize
}

export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({
  className,
  items,
  size = "md"
}) => {
  const containerClassNames = classNames(
    styles.breadcrumbs,
    styles[size],
    className
  )

  return (
    <div className={containerClassNames}>
      {items.map(({ link, label }, index) => (
        <span className={styles.breadcrumb} key={index}>
          {link ? <Link to={link}>{label}</Link> : label}
        </span>
      ))}
    </div>
  )
}
