import { truncate, formatTime } from "../../../core/utils"
import {
  mapFirstTeacher,
  mapTeachers
} from "../../../core/mappers/map-teachers"
import { mapMetadata } from "../../../core/mappers/map-metadata"
import { mapTopics, TopicNode } from "../../../core/mappers/map-topics"
import {
  ConferenceDetailPageQuery,
  ContentfulTeacher
} from "../../../../graphql/types"
import { Conference, ConferenceVideoData } from "../conference-types"
import { PlaylistItem } from "../../../types/playlist-item"
import { getPageTitle } from "@utils/get-page-title"
import { getAudioUrl } from "@utils/media"

export const mapSourceConferenceToView = (
  query: ConferenceDetailPageQuery,
  currentMediaId: string | null
): Conference | null => {
  const details = query.contentfulSeries!

  const topics = mapTopics(
    query.allContentfulTopic.nodes as TopicNode[],
    details.primaryTopic! as TopicNode
  )
  const teachers = mapTeachers(details.teachers as Partial<ContentfulTeacher>[])

  const mediaResources = query.contentfulSeries!.mediaResources!

  const playlist: PlaylistItem<ConferenceVideoData>[] = mediaResources.map(
    (item): PlaylistItem<ConferenceVideoData> => ({
      teacher: mapFirstTeacher(item!.teachers as Partial<ContentfulTeacher>[]),
      teachers: mapTeachers(item!.teachers as Partial<ContentfulTeacher>[]),
      mediaId: item?.id || "",
      contentfulId: item?.contentful_id || "",
      primaryTopic: item!.primaryTopic,
      videoId: item!.video?.brightcoveId || "",
      audioUrl: getAudioUrl(item?.audio),
      ligCode: item!.ligCode as string,
      image: item!.video?.image?.fluid?.src || details.image?.fluid?.src || "",
      title: item!.name!,
      slug: item!.slug!,
      meta: formatTime(item!.video?.videoDuration),
      additionalData: {
        transcript:
          item!.messageTranscript?.childMarkdownRemark?.rawMarkdownBody || "",
        description:
          item!.description?.childMarkdownRemark?.rawMarkdownBody || "",
        topics: item!.relatedTopics
          ? mapTopics(
              item!.relatedTopics as TopicNode[],
              item!.primaryTopic as TopicNode
            )
          : []
      }
    })
  )

  const currentVideo = currentMediaId
    ? playlist.find((item) => item!.mediaId === currentMediaId)!
    : playlist[0]!

  const title = details.name!
  const descriptionHTML =
    details.description?.childMarkdownRemark?.rawMarkdownBody || ""
  const descriptionText =
    details.description?.childMarkdownRemark?.plainText || ""
  const ligCode = details.ligCode as string

  let metaDescription = null

  if (descriptionText) {
    metaDescription = truncate(descriptionText)
  }

  const metadata = mapMetadata(
    details.metadata,
    {
      title,
      description: metaDescription,
      image: details.image?.fixed?.src
    },
    {
      title: getPageTitle(details, currentMediaId || "") || details.pageTitle
    }
  )

  return {
    id: details.id,
    contentfulId: details.contentful_id || "",
    about: descriptionHTML,
    ligCode,
    topics,
    currentVideo,
    playlist,
    slug: details.slug!,
    teachers,
    title,
    subtitle: details.subtitle || "",
    metadata
  }
}
