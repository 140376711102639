import {
  mapFirstTeacher,
  mapTeachers
} from "../../../core/mappers/map-teachers"
import { mapTopics, TopicNode } from "../../../core/mappers/map-topics"
import {
  TeachingSeriePageQuery,
  ContentfulTeacher
} from "../../../../graphql/types"
import {
  TeachingSeries,
  TeachingSeriesVideoData
} from "../teaching-series-types"
import { PlaylistItem } from "../../../types/playlist-item"
import { mapMetadata } from "../../../core/mappers/map-metadata"
import { truncate, formatTime } from "../../../core/utils"
import { getPageTitle } from "@utils/get-page-title"
import { getAudioUrl } from "@utils/media"

export const mapSourceTeachingSerieToView = (
  query: TeachingSeriePageQuery,
  currentMediaId: string | null
): TeachingSeries | null => {
  const details = query.contentfulSeries!

  const topics = mapTopics(
    query.allContentfulTopic.nodes as TopicNode[],
    details.primaryTopic! as TopicNode
  )
  const teachers = mapTeachers(details.teachers as Partial<ContentfulTeacher>[])

  const mediaResources = details.mediaResources!

  const playlist: PlaylistItem<TeachingSeriesVideoData>[] = mediaResources.map(
    (item): PlaylistItem<TeachingSeriesVideoData> => ({
      mediaId: item?.id || "",
      contentfulId: item?.contentful_id || "",
      primaryTopic: item!.primaryTopic,
      teacher: mapFirstTeacher(item!.teachers as Partial<ContentfulTeacher>[]),
      videoId: item!.video?.brightcoveId || "",
      audioUrl: getAudioUrl(item!.audio),
      referenceId: item!.video?.referenceId || undefined,
      ligCode: item!.ligCode as string,
      image: item!.video?.image?.fluid?.src || details.image?.fluid?.src || "",
      title: item!.name!,
      slug: item!.slug!,
      meta: formatTime(item!.video?.videoDuration),
      additionalData: {
        transcript:
          item!.messageTranscript?.childMarkdownRemark?.rawMarkdownBody || "",
        description:
          item!.description?.childMarkdownRemark?.rawMarkdownBody || "",
        topics: item!.relatedTopics
          ? mapTopics(
              item!.relatedTopics as TopicNode[],
              item!.primaryTopic as TopicNode
            )
          : []
      }
    })
  )

  const currentVideo = currentMediaId
    ? playlist.find((item) => item!.mediaId === currentMediaId)!
    : playlist[0]!

  const descriptionHTML =
    details.description?.childMarkdownRemark?.rawMarkdownBody || ""
  const descriptionText =
    details.description?.childMarkdownRemark?.plainText || ""
  const metaDescription = truncate(descriptionText) || null
  const metadata = mapMetadata(
    details.metadata,
    {
      title: details.name,
      description: metaDescription,
      image: details.image?.fixed?.src
    },
    {
      title: getPageTitle(details, currentMediaId || "") || details.pageTitle
    }
  )

  return {
    contentfulId: details.contentful_id,
    about: descriptionHTML,
    courseImage:
      details.ligonierConnectCourseImage?.fluid?.src ||
      "/assets/image-placeholders/course-fallback-image.jpg",
    courseLink: details.courseLink || "",
    currentVideo,
    freeStream: details.freeStream || false,
    image: details.image?.fluid?.src || "",
    ligCode: details.ligCode as string,
    metadata,
    playlist,
    slug: details.slug!,
    subtitle: details.subtitle || "",
    teachers,
    title: details.name!,
    topics
  }
}
