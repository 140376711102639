import classNames from "classnames"
import Image, { FluidObject } from "gatsby-image"
import React from "react"
import styles from "./polaroid-card.module.scss"

interface PolaroidCardProps {
  image: FluidObject
  className?: string
}

export default function PolaroidCard({ image, className }: PolaroidCardProps) {
  return (
    <div className={classNames(styles.filmFrame, className)}>
      <Image fluid={{ ...image, aspectRatio: 1 }} className={styles.image} />
    </div>
  )
}
