import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Avatar } from "../avatar/avatar"
import { PathPrefix } from "../../core/constants"
import { Link } from "../link/link"
import { Teacher } from "../../types/teacher"
import styles from "./author-link.module.scss"

export interface AuthorLinkProps extends Teacher {
  className?: string
}

export const AuthorLink: FunctionComponent<AuthorLinkProps> = ({
  className,
  slug,
  name,
  image
}) => {
  const teacherLink = `${PathPrefix.Teachers}/${slug}`

  return (
    <Link to={teacherLink} className={classNames(styles.author, className)}>
      {image && <Avatar image={image} className={styles.avatar} />}
      <div>
        <span className={styles.name}>{name}</span>
      </div>
    </Link>
  )
}
