import React, { FunctionComponent, Fragment } from "react"
import { useIntl } from "react-intl"
import { DailyVideo } from "../../daily-video-types"
import { ShareAndLikeButton } from "../../../../components/share-and-like-button/share-and-like-button"
import { Breadcrumbs } from "../../../../components/breadcrumbs/breadcrumbs"
import { Tabs, TabItem } from "../../../../components/tabs/tabs"
import { AuthorLink } from "../../../../components/author-link/author-link"
import styles from "./daily-video-details.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface DailyVideoDetailsProps {
  videoContentfulId: string
  dailyVideo: DailyVideo
}

export const DailyVideoDetails: FunctionComponent<DailyVideoDetailsProps> = ({
  videoContentfulId,
  dailyVideo
}) => {
  const { formatMessage } = useIntl()

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <Breadcrumbs items={dailyVideo.topics} className={styles.breadcrumbs} />
        <div className={styles.details}>
          <AuthorLink
            {...dailyVideo.video.teacher}
            className={styles.desktopAuthor}
          />
          <div className={styles.titleWrapper}>
            <h2 className={styles.title}>{dailyVideo.video.title}</h2>
            <ShareAndLikeButton
              id={videoContentfulId}
              contentType="mediaResource"
            />
          </div>
          <Tabs
            contentClassName={styles.tabContent}
            tabSpacing="md"
            variant="alternative"
          >
            <TabItem label={formatMessage({ id: "daily-video-description" })}>
              <Fragment>
                <AuthorLink
                  {...dailyVideo.video.teacher}
                  className={styles.mobileAuthor}
                />
                <Markdown content={dailyVideo.description} />
              </Fragment>
            </TabItem>
            {dailyVideo.transcript && (
              <TabItem label={formatMessage({ id: "daily-video-transcript" })}>
                <Markdown content={dailyVideo.transcript} />
              </TabItem>
            )}
          </Tabs>
        </div>
      </div>
    </section>
  )
}
