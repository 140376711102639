import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { Icon, IconVariant } from "../icon/icon"
import styles from "./media-type-switch.module.scss"

export type MediaType = "audio" | "video"

const otherType: Record<MediaType, MediaType> = {
  audio: "video",
  video: "audio"
}

const typeIcon: Record<MediaType, IconVariant> = {
  audio: "12-audio",
  video: "12-video"
}

export interface MediaTypeSwitchProps {
  className?: string
  type: MediaType
  onClick: (newType: MediaType) => void
}

export const MediaTypeSwitch: FunctionComponent<MediaTypeSwitchProps> = ({
  className,
  onClick,
  type
}) => {
  const oppositeType = otherType[type]
  const icon = typeIcon[oppositeType]

  const handleClick = () => {
    onClick(oppositeType)
  }

  return (
    <button
      className={classNames(styles.customSwitch, className)}
      onClick={handleClick}
    >
      <span className={styles.desktop}>
        <FormattedMessage
          id={`teaching-series-switch-to-${oppositeType}-desktop`}
        />
        <Icon variant={icon} />
      </span>
      <span className={styles.mobile}>
        <FormattedMessage
          id={`teaching-series-switch-to-${oppositeType}-mobile`}
        />
      </span>
    </button>
  )
}
