import React, { FunctionComponent } from "react"
import Image from "gatsby-image"
import classNames from "classnames"
import { Link } from "../link/link"
import styles from "./product.module.scss"
import { getFluidGatsbyImage } from "@utils/gatsbyImage"
import { formatAuthorNames } from "@utils/author-names"
import { useShopifyProduct } from "@hooks/useShopifyProduct"
import { showCompareAtPrice } from "@core/prices"
import { TeacherBase } from "@features/teachers/teacher-types"
import Skeleton from "@components/skeleton/skeleton"
import { formatPrice } from "@utils/formatPrice"

export type ProductSize = "sm" | "md" | "lg"
export type ProductVariant = "flat" | "shadow"

interface ElementOrAnchorProps {
  className: string
  to: string
}

export interface ProductProps {
  author: string
  teachers?: Array<TeacherBase> | TeacherBase
  authorLink?: string
  className?: string
  image: string
  size?: ProductSize
  title: string
  format?: string | null | undefined
  url?: string
  handle: string
  variant?: ProductVariant
  disableDropShadow?: boolean
}

export const Product: FunctionComponent<ProductProps> = ({
  author,
  teachers,
  authorLink,
  className,
  image,
  size = "lg",
  title,
  format,
  url,
  handle,
  variant = "shadow"
}) => {
  const productClassName = classNames(
    styles.product,
    styles[size],
    styles[variant],
    url && styles.interactive,
    className
  )
  const isTeacherArray = teachers && Array.isArray(teachers)

  /* ---------- Product author ------------ */

  const AuthorContainer = author ? Link : "a"

  const authorProps = {
    className: styles.author
  }

  if (authorLink && (isTeacherArray ? teachers.length <= 1 : !!author)) {
    Object.defineProperty(authorProps, "to", {
      value: authorLink,
      enumerable: true
    })
  }

  const AuthorElement: React.FunctionComponent = () => (
    <AuthorContainer {...(authorProps as ElementOrAnchorProps)}>
      {isTeacherArray
        ? formatAuthorNames(teachers)
        : author
        ? author
        : `\u2014`}
    </AuthorContainer>
  )

  /* ---------- Product image ------------ */

  const fluid = getFluidGatsbyImage(image, { maxWidth: 600 })

  const ImageContainer = url ? Link : "div"

  const imageProps = {
    className: styles.cover
  }

  if (url) {
    Object.defineProperty(imageProps, "to", {
      value: url,
      enumerable: true
    })
  }

  const ImageElement: React.FunctionComponent = () => (
    <ImageContainer {...(imageProps as ElementOrAnchorProps)}>
      {fluid ? (
        <Image fluid={fluid} />
      ) : (
        <div className={styles.imagePlaceholder} />
      )}
    </ImageContainer>
  )

  /* ---------- Product price ------------ */

  const { isLoading, data } = useShopifyProduct(handle)

  const price = data?.price || ""
  const compareAtPrice = data?.compareAtPrice || ""

  const priceClassName = classNames(
    styles.originalPrice,
    compareAtPrice && styles.discounted
  )

  return isLoading ? (
    <Skeleton />
  ) : (
    <div data-testid={`Product:${handle}`} className={productClassName}>
      {format && <span className={styles.format}>{format}</span>}
      <ImageElement />
      <AuthorElement />
      {url ? (
        <Link className={styles.titleLink} to={url}>
          <h2 className={styles.title}>{title}</h2>
        </Link>
      ) : (
        <h2 className={styles.title}>{title}</h2>
      )}
      <div className={styles.prices}>
        {showCompareAtPrice(price, compareAtPrice) && (
          <span className={priceClassName}>{formatPrice(compareAtPrice)}</span>
        )}
        <span>{formatPrice(price)}</span>
      </div>
    </div>
  )
}
