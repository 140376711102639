import React, { FunctionComponent } from "react"
import classNames from "classnames"
import Image from "gatsby-image"
import { FormattedMessage } from "react-intl"
import { Button } from "../../../../components/button/button"
import styles from "./teaching-course.module.scss"
import { getFluidGatsbyImage } from "@utils/gatsbyImage"

export interface TeachingCourseProps {
  className?: string
  courseLink: string
  image?: string
}

export const TeachingCourse: FunctionComponent<TeachingCourseProps> = ({
  className,
  courseLink,
  image
}) => {
  const imageFluid = getFluidGatsbyImage(image || "", {
    maxWidth: 1200,
    sizes: "(max-width: 768px) 1200px, 500px"
  })

  return (
    <section className={classNames(styles.coursePreview, className)}>
      {imageFluid && (
        <div className={styles.image}>
          <Image fluid={imageFluid} />
        </div>
      )}
      <div className={styles.content}>
        <h4>
          <FormattedMessage id="teaching-series-dig-deeper" />
        </h4>
        <p className={styles.text}>
          <FormattedMessage id="teaching-series-available-on-connect" />
        </p>
        <Button variant="primary" size="md" to={courseLink}>
          <FormattedMessage id="teaching-series-go-connect-button" />
        </Button>
      </div>
    </section>
  )
}
