import {
  ContentfulTeacher,
  DailyVideoPageQuery,
  DailyVideoFragment
} from "../../../../graphql/types"
import { truncate } from "../../../core/utils"
import { mapFirstTeacher } from "../../../core/mappers/map-teachers"
import { mapTopics, TopicNode } from "../../../core/mappers/map-topics"
import { DailyVideo, DailyVideoData } from "../daily-video-types"
import { PlaylistItem } from "../../../types/playlist-item"
import { mapMetadata } from "../../../core/mappers/map-metadata"

export const mapDailyVideoToPlaylistItem = (
  data: DailyVideoFragment
): PlaylistItem<DailyVideoData> => {
  const video = data.video!
  const teacher = mapFirstTeacher(
    video.teachers as Partial<ContentfulTeacher>[]
  )
  const ligCode = video?.ligCode as string

  return {
    additionalData: {
      date: data!.date,
      dateISO: data.dateISO,
      expirationDateISO: data.expirationDateISO,
      formattedDate: data!.formattedDate
    },
    image: video.video?.image?.fluid?.src || undefined,
    meta: data!.formattedDate,
    slug: video.slug!,
    teacher,
    title: video.name!,
    mediaId: video.video?.brightcoveId!,
    videoId: video.video?.brightcoveId!,
    seriesName: video?.series?.name || "",
    seriesSlug: video?.series?.slug || "",
    seriesImage: video?.series?.image?.fixed?.src || "",
    ligCode
  }
}

export const mapSourceDailyVideoToView = (
  query: DailyVideoPageQuery,
  recentVideos: PlaylistItem<DailyVideoData>[]
): DailyVideo => {
  const videoData = query.contentfulDailyVideo!.video!
  const video = mapDailyVideoToPlaylistItem(query.contentfulDailyVideo!)

  const transcript =
    videoData.messageTranscript?.childMarkdownRemark?.rawMarkdownBody || ""
  const description =
    videoData.description?.childMarkdownRemark?.rawMarkdownBody || ""

  const descriptionText =
    videoData.description?.childMarkdownRemark?.plainText || ""

  const metaDescription = truncate(descriptionText) || null

  const metaImage =
    videoData.series?.image?.fixed?.src || videoData.video?.image?.fixed?.src
  const metadata = mapMetadata(
    query.contentfulDailyVideo?.metadata,
    {
      title: videoData.name,
      description: metaDescription,
      image: metaImage
    },
    {
      title: videoData.pageTitle
    }
  )

  const topics = mapTopics(
    query.allContentfulTopic.nodes as TopicNode[],
    videoData.series!.primaryTopic! as TopicNode
  )

  return {
    video,
    topics,
    description,
    transcript,
    playlist: recentVideos,
    metadata
  }
}
