import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { Event } from "../../features/events/event-types"
import { AvatarList } from "../avatar/avatar-list"
import { Link } from "../link/link"
import { EventListVariant } from "./events-list"
import styles from "./events-list-item.module.scss"

export interface EventsListItemProps {
  item: Event
  variant: EventListVariant
}

export const EventsListItem: FunctionComponent<EventsListItemProps> = ({
  item,
  variant
}) => {
  const ariaProps = {
    "aria-label": `Learn more about ${item.title}`
  }

  return (
    <div className={classNames(styles.event, styles[variant])}>
      <div className={styles.content}>
        <div className={styles.date}>
          <Link to={item.url} target={"_self"}>
            {item.date}
          </Link>
        </div>
        <div className={styles.text}>
          <h2 className={styles.title}>
            <Link to={item.url} target={"_self"}>
              {item.title}
            </Link>
          </h2>
          <p>{item.location}</p>
        </div>
        <div className={styles.speakers}>
          <AvatarList avatars={item.speakers} size="sm" limit={8} />
        </div>
        <div className={styles.actions}>
          {item.url && (
            <Link to={item.url} target={"_self"} {...ariaProps}>
              <FormattedMessage id="events-register-button" />
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}
