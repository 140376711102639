import React, { Children, FunctionComponent } from "react"
import classNames from "classnames"
import styles from "./carousel.module.scss"

export interface CarouselProps {
  className?: string
  itemClassName?: string
  separator?: JSX.Element
}

export const Carousel: FunctionComponent<CarouselProps> = ({
  className,
  itemClassName,
  children,
  separator
}) => (
  <div className={classNames(styles.carousel, className)}>
    {Children.map(children, (child, index) => (
      <React.Fragment key={index}>
        <div className={classNames(styles.childWrapper, itemClassName)}>
          {child}
        </div>
        {Boolean(separator && index < Children.count(children) - 1) && (
          <div className={styles.childWrapper}>{separator}</div>
        )}
      </React.Fragment>
    ))}
  </div>
)
