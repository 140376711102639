import React, { Fragment, FunctionComponent } from "react"
import { DailyVideoHeader } from "../daily-video-header/daily-video-header"
import { DailyVideoPlayer } from "../daily-video-player/daily-video-player"
import { DailyVideoDetails } from "../daily-video-details/daily-video-details"
import { DailyVideo } from "../../daily-video-types"
import { ContentLockServiceProvider } from "../../../../contexts/service-context"
import { ContentLockBootstrapDataKey } from "../../../../machines/contentLockMachine.machine"
import styles from "./daily-video-page.module.scss"
import { Attribution } from "@components/attribution/attribution"
import { GlobalPromotionCTA } from "@features/global-promotions/components/global-promotion-cta"
import { useGlobalPromotion } from "@features/global-promotions/hooks/use-global-promotion"

export interface DailyVideoPageProps {
  contentfulId: string
  videoContentfulId: string
  dailyVideo: DailyVideo
}

export const DailyVideoPage: FunctionComponent<DailyVideoPageProps> = ({
  contentfulId,
  videoContentfulId,
  dailyVideo
}) => {
  // Filter out videos scheduled after current date client-side
  // The Gatsby build includes video for tomorrow
  // This is to compensate for the next day starting before the next build
  // However, we don't want to show tomorrow's video before that happens
  // For the same reasons, the build may include videos that expired yesterday,
  // so those need to be filtered out client-side as well
  const filteredPlaylist = dailyVideo.playlist.filter((item) => {
    const today = new Date()
    const itemDate = new Date(item.additionalData.dateISO)
    const itemExpirationDate = new Date(item.additionalData.expirationDateISO)

    return itemDate <= today && itemExpirationDate >= today
  })

  const contentfulSeries = dailyVideo.video
  const contenfulSeriesImagePath = contentfulSeries.seriesImage
  const contentfulTeacherName = contentfulSeries.teacher.name
  const hasGlobalPromotion = Boolean(useGlobalPromotion())

  //Attribution component is being passed gfm for body.
  return (
    <ContentLockServiceProvider
      contentLockKey={ContentLockBootstrapDataKey.dailyVideo}
    >
      <Fragment>
        <DailyVideoHeader
          date={dailyVideo.video.additionalData.formattedDate}
          contentfulId={contentfulId}
        />
        <DailyVideoPlayer
          videoId={dailyVideo.video.videoId}
          playlist={filteredPlaylist}
        />
        <DailyVideoDetails
          dailyVideo={dailyVideo}
          videoContentfulId={videoContentfulId}
        />
        <Attribution
          imageSrc={contenfulSeriesImagePath || ""}
          body={
            "This message is from [" +
            contentfulSeries?.seriesName +
            "](/learn/series/" +
            contentfulSeries?.seriesSlug +
            ") by " +
            contentfulTeacherName
          }
          buttonLink={`/learn/series/${contentfulSeries?.seriesSlug}`}
          buttonText={"View Series"}
        />
        {hasGlobalPromotion && (
          <div className={styles.wrapperOuter}>
            <div className={styles.wrapperInner}>
              <GlobalPromotionCTA disableDesktopStyles />
            </div>
          </div>
        )}
      </Fragment>
    </ContentLockServiceProvider>
  )
}
