import React, { FunctionComponent } from "react"
import {
  BreadcrumbResource,
  Breadcrumbs
} from "../../../../components/breadcrumbs/breadcrumbs"
import { ShareAndLikeButton } from "../../../../components/share-and-like-button/share-and-like-button"
import { Teacher } from "../../../../types/teacher"
import { PathPrefix } from "../../../../core/constants"
import { AvatarList } from "../../../../components/avatar/avatar-list"
import styles from "./conference-header.module.scss"

export interface ConferenceHeaderProps {
  contentfulId: string
  title: string
  subtitle: string
  topics: BreadcrumbResource[]
  teachers: Teacher[]
  slug: string
}

export const ConferenceHeader: FunctionComponent<ConferenceHeaderProps> = ({
  contentfulId,
  title,
  subtitle,
  topics,
  teachers,
  slug
}) => (
  <header className={styles.header}>
    <div className={styles.content}>
      <Breadcrumbs items={topics} size="sm" />
      <h1 className={styles.title}>{title}</h1>
      <h5 className={styles.subtitle}>{subtitle}</h5>
    </div>
    <div className={styles.teachers}>
      <AvatarList listClassName={styles.avatars} avatars={teachers} size="sm" />
      <ShareAndLikeButton
        shareUrl={`${PathPrefix.Conferences}/${slug}`}
        className={styles.icons}
        id={contentfulId}
        contentType="series"
      />
    </div>
  </header>
)
