import { IntlShape } from "react-intl"
import { TranslateableString, IntlFunc } from "../types/intl"

export const getLocalizer =
  (messageId: string): IntlFunc =>
  (intl: IntlShape): string =>
    intl.formatMessage({ id: messageId })

export const getStringTranslator =
  (intl: IntlShape) =>
  (input: TranslateableString | TranslateableString[]): string | string[] => {
    const translateString = (str: TranslateableString) => {
      if (typeof str === "function") {
        return str(intl)
      }

      return str
    }

    if (Array.isArray(input)) {
      return input.map(translateString)
    }

    return translateString(input)
  }
