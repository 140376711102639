import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { ShareAndLikeButton } from "../../../../components/share-and-like-button/share-and-like-button"
import styles from "./daily-video-header.module.scss"

interface DailyVideoHeaderProps {
  contentfulId: string
  date: string
}

export const DailyVideoHeader: FunctionComponent<DailyVideoHeaderProps> = ({
  contentfulId,
  date
}) => (
  <header className={styles.header}>
    <div className={styles.content}>
      <h1 className={styles.title}>
        <FormattedMessage id="daily-video-title" />
      </h1>
      <h3 className={styles.date}>{date}</h3>
    </div>
    <div className={styles.side}>
      <ShareAndLikeButton
        className={styles.share}
        id={contentfulId}
        contentType="mediaResource"
      />
    </div>
  </header>
)
