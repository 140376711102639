import { useEffect } from "react"
import { dispatchWindowEvent } from "../core/utils"

export const crossRefEvent = "esv-crossref.trigger-linkify"

export const crossRefOptions = {
  // Header
  header_background_color: "FFFFFF",
  header_font_color: "647A1C",
  header_font_family: "Avenir",
  header_font_size: 16,
  // Body
  body_background_color: "FFFFFF",
  body_font_color: "333333",
  body_font_size: 14,
  // Footer
  footer_background_color: "FFFFFF",
  footer_font_color: "959595",
  footer_font_size: 12
}

export function useCrossReference(): void {
  useEffect(() => {
    window.ESV_CROSSREF_OPTIONS = crossRefOptions
    dispatchWindowEvent(crossRefEvent)
  }, [])
}
