import { AvatarListItemProps } from "../../../../components/avatar/avatar-list"
import {
  SearchResultTeacher,
  PodcastEpisodeFields
} from "../../../../types/algolia"
import { TeacherBase } from "../../../teachers/teacher-types"
import {
  formatScriptureReference,
  mapScriptureReference
} from "@features/scriptures/mappers/scripture-reference-mapper"
import { identity } from "@core/utils"

const dummyTeacher: TeacherBase = {
  firstName: "",
  lastName: "",
  shortName: "",
  image: ""
}

const mapSingleTeacher = (teacher: SearchResultTeacher): TeacherBase => {
  return {
    firstName: teacher.fields.firstName,
    lastName: teacher.fields.lastName,
    shortName: teacher.fields.shortName,
    image: teacher.fields.image?.fields?.file.url || ""
  }
}

export const mapTeachers = (teachers: SearchResultTeacher[]): TeacherBase[] => {
  return (teachers || []).map(mapSingleTeacher)
}

export const mapAuthor = (teachers: SearchResultTeacher[]): TeacherBase => {
  return (teachers || []).length ? mapSingleTeacher(teachers[0]) : dummyTeacher
}

export const mapAvatars = (teachers: TeacherBase[]): AvatarListItemProps[] => {
  return (teachers || []).map((teacher) => ({
    name: `${teacher.shortName}`,
    image: teacher.image
  }))
}

export const mapScriptures = (episode: PodcastEpisodeFields) => {
  const scriptures = []

  if (
    episode?.primaryScriptureReference &&
    episode.primaryScriptureReference.start.book
  ) {
    scriptures.push(episode.primaryScriptureReference)
  }

  if (episode?.secondaryScriptureReferences) {
    scriptures.push(...episode.secondaryScriptureReferences)
  }

  return scriptures.map(mapScriptureReference)
}

export const mapPodcastEpisodeFieldsMeta = (
  episode: PodcastEpisodeFields,
  showSlug: string
): string => {
  const defaultMeta = episode?.primaryTopic?.fields.name
  const primaryScriptureReference = mapScriptures(episode)[0]

  // Based on the slug for the parent show, we want to display different meta types
  switch (showSlug) {
    case "askligonier":
      return mapTeachers(episode?.teachers)[0].shortName || defaultMeta
    case "westminster-shorter-catechism":
      return primaryScriptureReference
        ? formatScriptureReference(primaryScriptureReference, identity)
        : defaultMeta
    default:
      return defaultMeta
  }
}
