import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { Icon } from "../icon/icon"
import { ResourceType, resourceIconVariant } from "../../types/resource"
import { castArray } from "../../core/utils"
import { ListItem, ListItemProps } from "./list-item"
import styles from "./list.module.scss"

export interface ListItemGenericProps extends ListItemProps {
  imageURL?: string
  meta: string | string[]
  title: string
  url: string
  type?: ResourceType
  onClick?: MouseEventHandler
}

export const ListItemGeneric: FunctionComponent<ListItemGenericProps> = ({
  className,
  disabled = false,
  imageURL,
  meta,
  onClick,
  title,
  type = "article",
  url
}) => (
  <ListItem
    className={classNames(styles.listItemGeneric, className)}
    disabled={disabled}
    onClick={onClick}
    url={url}
  >
    <div className={styles.content}>
      <div className={styles.header}>
        <span>{title}</span>
      </div>
      <div className={styles.subheader}>
        {castArray(meta).map((metaItem, index) => (
          <span className={styles.metaItem} key={index}>
            {metaItem}
          </span>
        ))}
      </div>
    </div>
    <div className={styles.side}>
      <Icon variant={resourceIconVariant[type]} className={styles.icon} />
    </div>
    {imageURL && (
      <div
        className={styles.image}
        style={{ backgroundImage: `url("${imageURL}")` }}
      />
    )}
  </ListItem>
)
