import { useEffect, useState } from "react"

const second = 1000
const minute = second * 60
const hour = minute * 60
const day = hour * 24

const getRemainingTime = (dateStr: string) => {
  const date = new Date(dateStr).getTime()
  const difference = date - Date.now()

  return Math.max(difference, 0)
}

export function useDateCountdown(targetDateStr: string) {
  const [timeRemaining, setTimeRemaining] = useState(
    getRemainingTime(targetDateStr)
  )

  useEffect(() => {
    let timer = 0

    const update = () => {
      const timeLeft = getRemainingTime(targetDateStr)

      if (timeLeft >= 0) {
        setTimeRemaining(timeLeft)
      } else {
        window.clearInterval(timer)
      }
    }

    update()

    timer = window.setInterval(update, second)

    return () => {
      window.clearInterval(timer)
    }
  }, [targetDateStr])

  const daysRemaining = Math.floor(timeRemaining / day)
  const hoursRemaining = Math.floor((timeRemaining % day) / hour)
  const minutesRemaining = Math.floor((timeRemaining % hour) / minute)
  const secondsRemaining = Math.floor((timeRemaining % minute) / second)

  return [daysRemaining, hoursRemaining, minutesRemaining, secondsRemaining]
}
