import React, { Fragment, FunctionComponent } from "react"
import { useIntl } from "react-intl"
import { Breadcrumbs } from "../../../../components/breadcrumbs/breadcrumbs"
import { Conference } from "../../conference-types"
import { Tabs, TabItem } from "../../../../components/tabs/tabs"
import { AuthorLink } from "../../../../components/author-link/author-link"
import { ShareAndLikeButton } from "../../../../components/share-and-like-button/share-and-like-button"
import {
  AvatarList,
  AvatarListItemProps
} from "../../../../components/avatar/avatar-list"
import styles from "./conference-details.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface ConferenceDetailsProps {
  isConferenceStartPage: boolean
  contentfulId: string
  conference: Conference
}

export const ConferenceDetails: FunctionComponent<ConferenceDetailsProps> = ({
  conference,
  contentfulId,
  isConferenceStartPage
}) => {
  const { formatMessage } = useIntl()

  const topics = conference.currentVideo.additionalData.topics || null

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.details}>
          {topics && (
            <Breadcrumbs
              items={topics}
              size="sm"
              className={styles.videoTopics}
            />
          )}
          {conference.currentVideo.teachers &&
          conference.currentVideo.teachers.length > 1 ? (
            <AvatarList
              listClassName={styles.desktopAuthor}
              size="md"
              avatars={
                conference.currentVideo.teachers as AvatarListItemProps[]
              }
            />
          ) : (
            <AuthorLink
              {...conference.currentVideo.teacher}
              className={styles.desktopAuthor}
            />
          )}
          <div className={styles.titleWrapper}>
            <h2 className={styles.title}>{conference.currentVideo.title}</h2>
            <ShareAndLikeButton id={contentfulId} contentType="mediaResource" />
          </div>
          <Tabs
            className={styles.tabs}
            contentClassName={styles.tabContent}
            initialTab={isConferenceStartPage ? 2 : 0}
            tabSpacing="md"
            variant="alternative"
          >
            <TabItem label={formatMessage({ id: "conference-description" })}>
              <Fragment>
                <AuthorLink
                  {...conference.currentVideo.teacher}
                  className={styles.mobileAuthor}
                />
                <Markdown
                  content={conference.currentVideo.additionalData.description}
                />
              </Fragment>
            </TabItem>
            {conference.currentVideo.additionalData.transcript && (
              <TabItem label={formatMessage({ id: "conference-transcript" })}>
                <Markdown
                  content={conference.currentVideo.additionalData.transcript}
                />
              </TabItem>
            )}
            <TabItem label={formatMessage({ id: "conference-about" })}>
              <Markdown content={conference.about} />
            </TabItem>
          </Tabs>
        </div>
      </div>
    </section>
  )
}
