import React, { FunctionComponent, CSSProperties } from "react"
import { FluidObject } from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import classnames from "classnames"
import styles from "./item-image.module.scss"

export interface ItemImageProps {
  className?: string
  style?: CSSProperties
  src?: string
  fluid?: FluidObject
}

export const ItemImage: FunctionComponent<ItemImageProps> = ({
  className,
  style,
  src,
  fluid
}) => {
  if (fluid) {
    return (
      <div className={classnames(styles.itemImage, className)} style={style}>
        <BackgroundImage fluid={fluid} />
      </div>
    )
  }

  return (
    <div
      className={classnames(styles.itemImage, className)}
      data-src={src}
      style={style}
    >
      <div style={src ? { backgroundImage: `url(${src})` } : undefined}>
        {!src && (
          <img
            data-src="/assets/image-placeholders/card-logo.svg"
            className="lazyload"
          />
        )}
      </div>
    </div>
  )
}
