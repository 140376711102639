import React, { FunctionComponent } from "react"
import classNames from "classnames"
import Image from "gatsby-image"
import { FormattedMessage } from "react-intl"
import { Event } from "../../features/events/event-types"
import { AvatarList } from "../avatar/avatar-list"
import { Button } from "../button/button"
import { Link } from "../link/link"
import { EventListVariant } from "./events-list"
import styles from "./featured-event.module.scss"

export interface FeaturedEventProps {
  item: Event
  variant: EventListVariant
  className?: string
}

export const FeaturedEvent: FunctionComponent<FeaturedEventProps> = ({
  item,
  variant,
  className
}) => (
  <div className={classNames(styles.event, styles[variant], className)}>
    {item.image && (
      <div className={styles.image}>
        <Link className={styles.imageLink} to={item.url} target={"_self"}>
          <Image fluid={item.imageFluid} />
        </Link>
      </div>
    )}
    <div className={styles.content}>
      <div className={styles.label}>
        <FormattedMessage id="events-featured-event" />
      </div>
      <h2 className={styles.title}>
        <Link to={item.url} target={"_self"}>
          {item.title}
        </Link>
      </h2>
      <div className={styles.speakers}>
        <AvatarList avatars={item.speakers} size="sm" limit={8} />
      </div>
      <div className={styles.meta}>
        <p>{item.date}</p>
        <p>{item.location}</p>
      </div>
      <div className={styles.actions}>
        {item.url && (
          <Button
            className={styles.button}
            to={item.url}
            target={"_self"}
            variant="primary"
            size="md"
            ariaLabel={`Learn more about ${item.title}`}
          >
            <FormattedMessage id="events-register-button" />
          </Button>
        )}
      </div>
    </div>
  </div>
)
