import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { Avatar } from "../avatar/avatar"
import { AvatarList } from "../avatar/avatar-list"
import { Icon } from "../icon/icon"
import { TeacherBase } from "../../features/teachers/teacher-types"
import styles from "./list.module.scss"
import { ListItem, ListItemProps } from "@components/list/list-item"

export interface ListItemArticleProps extends ListItemProps {
  image?: string
  authors: TeacherBase[]
  avatar?: string
  time: number
  title: string
  onClick?: MouseEventHandler
}

export const ListItemArticle: FunctionComponent<ListItemArticleProps> = ({
  authors,
  className,
  disabled = false,
  image,
  time,
  title,
  url,
  onClick
}) => (
  <ListItem
    className={classNames(styles.listItemArticle, className)}
    disabled={disabled}
    url={url}
    onClick={onClick}
  >
    <div className={styles.content}>
      <div className={styles.subheader}>
        <Icon variant="12-article" className={styles.icon} />
        <span className={styles.time}>{time}MIN</span>
      </div>
      <h2 className={styles.header}>{title}</h2>
      <div className={styles.subheader}>
        {authors?.length === 1 && (
          <Avatar
            image={authors[0].image}
            name={authors[0].shortName}
            showName
            size="xs"
          />
        )}
        {authors?.length > 1 && (
          <AvatarList avatars={authors} size="xs" showNames />
        )}
      </div>
    </div>
    <div className={styles.side}>
      <div
        className={classNames(styles.image, !image && styles.placeholder)}
        style={{
          backgroundImage: `url(${
            image || "/assets/image-placeholders/list-logo.svg"
          })`
        }}
      />
    </div>
  </ListItem>
)
