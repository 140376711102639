import React, { FunctionComponent, CSSProperties } from "react"
import classNames from "classnames"
import styles from "./background-image.module.scss"
import { DynamicProps } from "@src-types/utils"
import { useImage } from "@hooks/use-image"

interface BackgroundImageProps extends DynamicProps {
  src: string
  wrapperStyle?: CSSProperties
  innerStyle?: CSSProperties
  wrapperClassName?: string
  innerClassName?: string
}

export const BackgroundImage: FunctionComponent<BackgroundImageProps> = ({
  src,
  wrapperStyle,
  innerStyle,
  wrapperClassName,
  innerClassName,
  children,
  ...otherProps
}) => {
  const { loading, lazyRef } = useImage(src, true)

  return (
    <div
      ref={lazyRef}
      {...otherProps}
      className={classNames(wrapperClassName, styles.wrapper)}
      style={wrapperStyle}
    >
      <div
        className={classNames(styles.innerImage, innerClassName, {
          [styles.loading]: loading
        })}
        style={{
          ...innerStyle,
          ...(!loading && { backgroundImage: `url("${src}")` })
        }}
      />
      {children}
    </div>
  )
}
