import React, { FunctionComponent, useMemo, useCallback } from "react"
import { navigate } from "gatsby"
import { PlayerWithPlaylist } from "../../../../components/player-with-playlist/player-with-playlist"
import { DailyVideoData } from "../../daily-video-types"
import { PathPrefix } from "../../../../core/constants"
import { PlaylistItem } from "../../../../types/playlist-item"

export interface DailyVideoPlayerProps {
  videoId: string
  playlist: PlaylistItem<DailyVideoData>[]
}

export const DailyVideoPlayer: FunctionComponent<DailyVideoPlayerProps> = ({
  videoId,
  playlist
}) => {
  const items = useMemo(() => {
    return playlist.map((item) => ({
      ...item,
      mediaId: item.videoId,
      meta: item.additionalData.formattedDate!
    }))
  }, [playlist])

  const onItemClick = useCallback((item: PlaylistItem<DailyVideoData>) => {
    const url = `${PathPrefix.DailyVideo}/${item.additionalData.date}/${item.slug}`

    navigate(url)
  }, [])

  return (
    <PlayerWithPlaylist
      mediaId={videoId}
      items={items}
      onItemClick={onItemClick}
      playlistTitleOnMobile="Daily Video"
    />
  )
}
