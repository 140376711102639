export const formatVariantTitle = (title: string): string => {
  return title.replace(/\|/g, " & ")
}

type possibleNull<T> = T | null | undefined

export const formatColorLanguage = (
  format: possibleNull<string>,
  color: possibleNull<string>,
  language: possibleNull<string>
): string => {
  const isValid = (value: possibleNull<string>) => {
    const invalidTerms = ["english"]
    const isAllowed = !invalidTerms.includes(value?.toLowerCase() || "")

    return Boolean(value && isAllowed)
  }

  return [format, color, language].filter(isValid).join(` \u2013 `)
}

export const formatDate = (
  dateString: possibleNull<string>
): possibleNull<string> => {
  if (!dateString) return dateString

  const dateConstructorInput = dateString.match(/[^\d]/)
    ? dateString
    : Number(dateString)

  const date = new Date(dateConstructorInput)

  const day = date.getUTCDate()
  const month = date.getUTCMonth()
  const year = date.getUTCFullYear()

  return new Date(year, month, day).toLocaleDateString()
}

export const formatLink = (link: string) =>
  link.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").replace(/\/.*$/i, "")
