import React, { FunctionComponent } from "react"
import { useIntl } from "react-intl"
import { Helmet } from "react-helmet"
import { PartialMetadata } from "../../types/metadata"
import { getStringTranslator } from "../../i18n/helpers"
import { defaultMetadata, defaultTitleTemplate } from "../../core/constants"

export type MetadataProps = PartialMetadata

export const Metadata: FunctionComponent<MetadataProps> = (props) => {
  const {
    description,
    keywords,
    lang,
    openGraphImage,
    openGraphSiteName,
    openGraphType,
    openGraphUrl,
    title,
    twitterCard,
    twitterSite,
    useTitleTemplate,
    noIndex
  } = {
    ...defaultMetadata,
    ...props
  }
  const intl = useIntl()
  const translateString = getStringTranslator(intl)
  const translatedTitle = translateString(title) as string
  const titleTemplate = intl.formatMessage(
    {
      id: "metadata-title-template",
      defaultMessage: defaultTitleTemplate
    },
    { title: translatedTitle }
  )
  const titleValue =
    useTitleTemplate && titleTemplate ? titleTemplate : translatedTitle
  const translatedDescription = translateString(description) as string

  // Sometimes our graph images come with query parameters. These are not necessary
  // and only serve to create formatting issues when the links are entity encoded
  // e.g www.link.com?h=30&w=30 becomes www.link.com&#63h=40&ampw=30
  const removeQueryParams = (url: string): string => url.split("?")[0]

  const transformedOpenGraphImageLink = removeQueryParams(
    openGraphImage?.startsWith("//")
      ? `https:${openGraphImage}`
      : openGraphImage || defaultMetadata.openGraphImage
  )

  return (
    <Helmet
      meta={[
        {
          name: "apple-itunes-app",
          content: "app-id=371390152"
        },
        {
          property: "og:type",
          content: openGraphType
        },
        {
          property: "og:site_name",
          content: openGraphSiteName
        },
        {
          property: "og:title",
          content: titleValue
        },
        {
          property: "og:description",
          content: translatedDescription
        },
        {
          property: "og:image",
          content: transformedOpenGraphImageLink
        },
        {
          property: "og:image:secure_url",
          content: transformedOpenGraphImageLink
        },
        {
          property: "og:type",
          content: openGraphType
        },
        {
          name: "twitter:card",
          content: twitterCard
        },
        {
          name: "twitter:site",
          content: twitterSite
        },
        {
          name: "twitter:title",
          content: titleValue
        },
        {
          name: "twitter:image",
          content: transformedOpenGraphImageLink
        },
        {
          name: "twitter:description",
          content: translatedDescription
        },
        {
          name: "description",
          content: translatedDescription
        },
        {
          name: "keywords",
          content: keywords.join(", ")
        },
        {
          name: "referrer",
          content: "origin"
        },
        {
          property: "fb:pages",
          content: "7434613114"
        }
      ]
        .concat(
          openGraphUrl
            ? {
                property: "og:url",
                content: openGraphUrl || ""
              }
            : []
        )
        .concat(
          noIndex
            ? {
                name: "robots",
                content: "noindex"
              }
            : []
        )}
    >
      <html lang={lang} />
      <title>{titleValue}</title>
    </Helmet>
  )
}
