import React, { FunctionComponent, useEffect, useState, useRef } from "react"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"
import { useScript } from "../../hooks/use-script"
import { Button } from "../button/button"
import { Progress } from "../progress/progress"
import { useSegmentEvent } from "../../hooks/use-segment-event"
import { formatTime, getAudioPlayerState } from "./helpers"
import styles from "./audio.module.scss"

export const audioSessionId = uuidv4()

export interface AudioProps {
  audioId: string
  className?: string
  src: string
  title: string
}

const playerUrl =
  process.env.GATSBY_JWPLAYER_URL ||
  "https://cdn.jwplayer.com/libraries/W2QDm6Sy.js"

const playerId = "jwPlayer"

export const Audio: FunctionComponent<AudioProps> = ({
  audioId,
  className,
  src,
  title
}) => {
  const [player, setPlayer] = useState<jwplayer.JWPlayer>()
  const [time, setTime] = useState(0)
  const [duration, setDuration] = useState<number>(0)
  const [progress, setProgress] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const initializedRef = useRef(false)

  const isLoaded = useScript(playerUrl)

  const { triggerEvent } = useSegmentEvent()

  useEffect(() => {
    if (!isLoaded || player) {
      return
    }

    const jwPlayer = window.jwplayer?.(playerId) as jwplayer.JWPlayer

    if (jwPlayer.setup) {
      jwPlayer.setup({
        playlist: [
          {
            file: src
          }
        ]
      })

      setPlayer(jwPlayer)
    }
  }, [isLoaded, player, src])

  // there's an error in type definition - duration does not exist in MetadataParam type
  const handleMetadataEvent = (param: jwplayer.MetadataParam) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setDuration((param as any).duration)
  }

  const handleTimeEvent = (timeParams: jwplayer.TimeParam) => {
    const currentTime = Math.floor(timeParams.position)

    setProgress(timeParams.position / timeParams.duration)
    setTime(currentTime)
  }

  const handleFirstFrame = () => {
    if (player) {
      triggerEvent(
        {
          ...getAudioPlayerState(player),
          content_asset_id: audioId,
          title
        },
        "Audio Started"
      )
    }
  }

  const handlePauseEvent = () => {
    setIsPlaying(false)

    if (player) {
      triggerEvent(
        {
          ...getAudioPlayerState(player),
          content_asset_id: audioId,
          title
        },
        "Audio Paused"
      )
    }
  }

  const handlePlayEvent = () => {
    setIsPlaying(true)

    if (player) {
      const playerIsAtBeginning = player.getPosition() === 0

      // we already have an event for when the player starts, probably don't
      // want to send an event for resume when we are just "starting"
      if (!playerIsAtBeginning) {
        triggerEvent(
          {
            ...getAudioPlayerState(player),
            content_asset_id: audioId,
            title
          },
          "Audio Resumed"
        )
      }
    }
  }

  const handleCompletePlayback = () => {
    if (player) {
      triggerEvent(
        {
          ...getAudioPlayerState(player),
          content_asset_id: audioId,
          title
        },
        "Audio Completed"
      )
    }
  }

  if (!initializedRef.current && isLoaded && player) {
    player.once("firstFrame", handleFirstFrame)
    player.on("meta", handleMetadataEvent)
    player.on("time", handleTimeEvent)
    player.on("play", handlePlayEvent)
    player.on("pause", handlePauseEvent)
    player.once("complete", handleCompletePlayback)

    initializedRef.current = true
  }

  const handlePlayButtonClick = () => {
    player?.play()
  }

  const handlePauseButtonClick = () => {
    player?.pause()
  }

  const handleProgressBarClick = (value: number) => {
    if (player && duration) {
      player.seek(value * duration)
    }
  }

  return (
    <div className={classNames(styles.audio, className)}>
      <div className={styles.player}>
        <div id={playerId} />
      </div>
      <div className={styles.controls}>
        {isPlaying ? (
          <Button
            className={styles.button}
            icon="16-pause"
            onClick={handlePauseButtonClick}
          />
        ) : (
          <Button
            className={styles.button}
            disabled={!isLoaded}
            icon="16-play"
            onClick={handlePlayButtonClick}
          />
        )}
        <Progress
          className={styles.progress}
          value={progress}
          onClick={handleProgressBarClick}
        />
        <div className={styles.time}>{formatTime(time)}</div>
        <span className={styles.durationDivider}>{"/"}</span>
        <div className={styles.duration}>{formatTime(duration)}</div>
      </div>
    </div>
  )
}
