import React from "react"
import classNames from "classnames"
import { FluidObject } from "gatsby-image"
import BaseLayout from "./base-layout"
import commonStyles from "./common-styles.module.scss"
import styles from "./year-end-countdown.module.scss"
import { useDateCountdown } from "@hooks/use-date-countdown"
import { Markdown } from "@components/markdown/markdown"
import PolaroidCard from "@components/PolaroidCard/polaroid-card"

export interface YearEndCountdownProps {
  headline: string
  buttonText: string
  buttonLink: string
  targetDate: string
  imageFluid?: FluidObject
}

const formatPart = (val: number, unit: string) =>
  `${val} ${unit}` + (val !== 1 ? "s" : "")

export default function YearEndCountdown(props: YearEndCountdownProps) {
  const { headline, buttonText, buttonLink, targetDate, imageFluid } = props

  const countdownParts = useDateCountdown(targetDate)

  const [days, hours, minutes] = countdownParts
  const isZero = ![days, hours, minutes].some(Boolean)

  const countdownText =
    [
      formatPart(days, "day"),
      formatPart(hours, "hr"),
      formatPart(minutes, "min")
    ].join(", ") + " remaining"

  const buttonSupportingCopy = <>{isZero ? "" : countdownText}</>

  const reactMarkdownProps = {
    disallowedElements: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
    unwrapDisallowed: true
  }

  return (
    <BaseLayout
      className={classNames(commonStyles.largeWrapper, styles.container)}
      infoClassName={classNames(
        commonStyles.largeInfo,
        !imageFluid && styles.infoWOImage
      )}
      supportingCopyClassName={
        !imageFluid ? styles.supportingCopyWOImage : undefined
      }
      mediaClassName={commonStyles.largeMediaWrapper}
      image={
        imageFluid && (
          <PolaroidCard image={imageFluid} className={styles.polariodImage} />
        )
      }
      headline={
        <h2 className={classNames(commonStyles.title, commonStyles.largeTitle)}>
          <Markdown content={headline} {...reactMarkdownProps} />
        </h2>
      }
      buttonLink={buttonLink}
      buttonText={buttonText}
      buttonSupportingCopy={buttonSupportingCopy}
    />
  )
}
