import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { ListItem, ListItemProps } from "./list-item"
import styles from "./list.module.scss"
import { PageType } from "@components/card/card-constants"
import { Icon } from "@components/icon/icon"
import { formatLink } from "@features/store/store-helpers"
import { Markdown } from "@components/markdown/markdown"

export interface ListItemPageProps extends ListItemProps {
  pageType: PageType
  title: string
  description: string
  image?: string
  onClick?: MouseEventHandler
}

export const ListItemPage: FunctionComponent<ListItemPageProps> = ({
  className,
  disabled = false,
  url,
  title,
  description,
  image,
  onClick,
  pageType
}) => {
  const formattedLink = formatLink(url || "")

  const metaInformation = pageType === "externalLink" ? formattedLink : "Page"

  const IconVariant = pageType === "externalLink" ? "12-link" : "12-page"

  return (
    <ListItem
      className={classNames(styles.listItemArticle, className)}
      disabled={disabled}
      url={url}
      onClick={onClick}
    >
      <div className={styles.content}>
        <div className={styles.subheader}>
          <Icon variant={IconVariant} className={styles.icon} />
          <span className={styles.time}>{metaInformation}</span>
        </div>
        <h2 className={styles.header}>{title}</h2>
        <div className={styles.description}>
          <Markdown content={description} />
        </div>
      </div>
      <div className={styles.side}>
        <div
          className={classNames(styles.image, !image && styles.placeholder)}
          style={{
            backgroundImage: `url(${
              image || "/assets/image-placeholders/list-logo.svg"
            })`
          }}
        />
      </div>
    </ListItem>
  )
}
