import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Skeleton } from "../skeleton/skeleton"
import { AvatarSkeleton } from "../avatar/avatar-skeleton"
import skeletonStyles from "../skeleton/skeleton.module.scss"
import styles from "./card-recomendations-skeleton.module.scss"

export const CardRecomendationsSkeleton: FunctionComponent = () => {
  return (
    <div className={styles.cardResource}>
      <div className={styles.content}>
        <div className={styles.title}>
          <Skeleton widthRandomness={0.5} />
        </div>
        <div className={styles.meta}>
          <div className={styles.subtitle}>
            <Skeleton
              widthRandomness={1}
              width={"200px"}
              heightRandomness={1}
            />
          </div>
        </div>
      </div>
      <div className={classNames(styles.image, skeletonStyles.background)} />
      <AvatarSkeleton
        className={classNames(styles.avatar, styles.skeleton)}
        outline
        size="sm"
      />
    </div>
  )
}
