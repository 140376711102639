import React, { FunctionComponent } from "react"
import classNames from "classnames"
import styles from "./skeleton.module.scss"

export interface SkeletonProps {
  width?: string
  height?: string
  widthRandomness?: number
  heightRandomness?: number
  borderRadius?: string
  color?: string
  count?: number
  animated?: boolean
  dark?: boolean
  className?: string
}

export const Skeleton: FunctionComponent<SkeletonProps> = ({
  width = "150px",
  height = "100%",
  widthRandomness = 0.25,
  heightRandomness = 0,
  borderRadius = "0px",
  count = 1,
  animated = true,
  className,
  dark = false
}) => {
  const w = parseFloat(width)
  const h = parseFloat(height)
  const wm = width.toString().replace(/\d+/g, "")
  const hm = height.toString().replace(/\d+/g, "")
  const elements = []

  for (let i = 0; i < count; i++) {
    const width = `${w - Math.random() * w * widthRandomness}${wm}`
    const height = `${h - Math.random() * h * heightRandomness}${hm}`

    elements.push(
      <span
        className={classNames(styles.skeletonLoad, styles.background, {
          [styles.disableAnimation]: !animated,
          [styles.dark]: dark
        })}
        key={i}
        style={{
          width,
          height,
          borderRadius
        }}
      >
        &zwnj;
      </span>
    )

    if (i !== count - 1) {
      elements.push(<br key={`br-${i}`} />)
    }
  }

  return <span className={className}>{elements}</span>
}

export default Skeleton
