import React, { FunctionComponent } from "react"
import { InstantSearch, Configure } from "react-instantsearch-core"
import { SearchContext } from "./search-context"
import { searchClient } from "./search-constants"
import { useSearchState } from "./hooks/use-search-state"
import { useSearchSuggestions } from "./hooks/use-search-suggestions"
import { getSearchIndexValue } from "./search-helpers"
import { SearchIndexes } from "./search-types"

export interface SearchProviderProps {
  location: Location
  searchIndexId: string
  allowedIndexes?: SearchIndexes
}

export const SearchProvider: FunctionComponent<SearchProviderProps> = ({
  children,
  location,
  searchIndexId,
  allowedIndexes
}) => {
  const { handleSearchStateChange, searchState, setSearchState } =
    useSearchState(location, searchIndexId, allowedIndexes)

  const { suggestions, querySuggestions, fetchSuggestions, clearSuggestions } =
    useSearchSuggestions()

  const searchContext: SearchContext = {
    clearSuggestions,
    fetchSuggestions,
    searchState,
    setSearchState,
    suggestions,
    querySuggestions
  }

  return (
    <SearchContext.Provider value={searchContext}>
      <InstantSearch
        searchClient={searchClient}
        searchState={searchState}
        indexName={getSearchIndexValue(searchIndexId)}
        onSearchStateChange={handleSearchStateChange}
      >
        <Configure {...searchState.configure} clickAnalytics />
        {children}
      </InstantSearch>
    </SearchContext.Provider>
  )
}
