import { Maybe, ProductVariantsOnlyFragment } from "../../../../graphql/types"
import { BaseProductVariant } from "../../store/store-types"

export const mapPaidContentVariants = (
  product: Maybe<ProductVariantsOnlyFragment> | null | undefined
): BaseProductVariant[] | null => {
  if (!product || !product.variants || product.variants.length === 0) {
    return null
  }

  return product.variants.map((variant) => {
    const price = variant!.price || ""
    const compareAtPrice = variant?.compareAtPrice || ""
    const title = variant!.title || ""

    return {
      id: variant!.realVariantId!,
      storefrontId: variant!.storefrontId || "",
      sku: variant!.sku || "",
      title,
      price,
      handle: variant?.handle || "",
      compareAtPrice
    }
  })
}
