import React, {
  FunctionComponent,
  MouseEventHandler,
  useContext,
  useEffect,
  useState
} from "react"
import { FormattedMessage } from "react-intl"
import classNames from "classnames"
import { useSelector } from "@xstate/react"
import { isFridayEST } from "../../core/dates"
import { NavigationSection } from "../../features/navigation/navigation-types"
import { Callouts } from "../callouts/callouts"
import { Link } from "../link/link"
import { MenuGroup, MenuGroupRow } from "./menu-group"
import styles from "./menu.module.scss"
import { ContentfulSubfooter, Maybe } from "graphql/types"
import { Store, StoreCollection } from "@core/constants"
import { AuthContext } from "@contexts/authContext"
import { CalloutsMP } from "@components/callouts-mp/callouts-mp"

export interface MenuProps {
  className?: string
  onClickLink?: MouseEventHandler<HTMLAnchorElement>
  sections: NavigationSection[]
  forceFriday?: boolean
  subfooter: Maybe<ContentfulSubfooter>
}

const promoUrl = `${StoreCollection}/5-friday`

export const Menu: FunctionComponent<MenuProps> = ({
  className,
  onClickLink,
  sections,
  forceFriday = false,
  subfooter
}) => {
  const { service: authMachine } = useContext(AuthContext)
  const isMinistryPartner = useSelector(authMachine, ({ context }) => {
    return context.isMinistryPartner
  })

  const [dynamicSections, setDynamicSections] =
    useState<NavigationSection[]>(sections)

  useEffect(() => {
    if (!isFridayEST() || forceFriday) {
      const newSections = sections.reduce<NavigationSection[]>(
        (list, section) => {
          list.push({
            ...section,
            links: section.links.filter((link) => link.url !== promoUrl)
          })

          return list
        },
        []
      )

      setDynamicSections(newSections)
    }
  }, [forceFriday, sections])

  return (
    <nav className={classNames(styles.menu, className)}>
      <div className={styles.groups}>
        {dynamicSections.map((section) => {
          return (
            <MenuGroup
              key={section.name}
              title={section.name}
              defaultOpen={section.isDefault}
              url={section.url}
              onClickLink={onClickLink}
            >
              {section.mobileName && section.url && (
                <MenuGroupRow className={styles.mobileSectionLink}>
                  <Link
                    className={styles.link}
                    to={section.url}
                    onClick={onClickLink}
                  >
                    {section.mobileName}
                  </Link>
                </MenuGroupRow>
              )}
              {section.links.map((link) => (
                <MenuGroupRow key={link.name} divider={link.divider}>
                  <Link
                    className={classNames(styles.link, {
                      [styles.withMeta]: !!link.meta
                    })}
                    to={link.url}
                    onClick={onClickLink}
                  >
                    {link.meta && (
                      <div className={styles.meta}>{link.meta}</div>
                    )}
                    {link.name}
                  </Link>
                </MenuGroupRow>
              ))}
              {section.promotionProduct && (
                <div className={styles.promo}>
                  <div className={styles.bodyText}>
                    {section.promotionTitle ? (
                      section.promotionTitle
                    ) : (
                      <FormattedMessage id="header-promotion" />
                    )}
                  </div>
                  <div className={styles.title}>
                    {section.promotionProduct?.handle && (
                      <Link to={`${Store}/${section.promotionProduct?.handle}`}>
                        {section.promotionProduct?.product?.title}
                      </Link>
                    )}
                  </div>
                  <div className={styles.bodyText}>
                    {section.promotionSubtitle}
                  </div>
                </div>
              )}
            </MenuGroup>
          )
        })}
      </div>
      {isMinistryPartner ? (
        <CalloutsMP
          variant="cream"
          heading={subfooter?.partnerCalloutHeading || ""}
          subheading={subfooter?.partnerCalloutSubheading || ""}
          buttonText={subfooter?.partnerCalloutButtonText || ""}
          link={subfooter?.partnerCalloutMenuButtonLink || ""}
        />
      ) : (
        <Callouts variant="cream" />
      )}
    </nav>
  )
}
