import React, { FunctionComponent } from "react"
import { IntlProvider } from "react-intl"
import { Modal } from "../modal/modal"
import { CopyToClipboard } from "../copy-to-clipboard/copy-to-clipboard"
import { SocialButton, SocialButtonVariant } from "../button/social-button"
import { getShareUrl } from "./share-helpers"
import styles from "./share.module.scss"

export type ShareSocialMedia = Extract<
  SocialButtonVariant,
  "facebook" | "twitter" | "linkedin"
>

export interface ShareProps {
  image?: string
  onClose: () => void
  text?: string
  title: string
  url: string
}

export const Share: FunctionComponent<ShareProps> = ({
  image,
  onClose,
  title,
  text,
  url
}) => {
  const facebookUrl = getShareUrl(url, "facebook")
  const twitterUrl = getShareUrl(url, "twitter", text)
  const emailUrl = `mailto:?body=${url}`
  const linkedinUrl = getShareUrl(url, "linkedin")

  return (
    <IntlProvider locale="en-US">
      <Modal
        onClose={onClose}
        className={styles.shareModal}
        overlayClassName={styles.shareModalOverlay}
      >
        <div className={styles.content}>
          <img src={image} alt="" />
          <div className={styles.title}>{title}</div>
          <div className={styles.copyWrapper}>
            <CopyToClipboard text={url} />
          </div>
          <div className={styles.buttons}>
            <SocialButton variant="facebook" to={facebookUrl} />
            <SocialButton variant="twitter" to={twitterUrl} />
            <SocialButton variant="linkedin" to={linkedinUrl} />
            <SocialButton variant="email" to={emailUrl} />
          </div>
        </div>
      </Modal>
    </IntlProvider>
  )
}
