import React from "react"
import classNames from "classnames"
import { Link } from "gatsby"
import { Highlight } from "../highlight/highlight"
import { Icon } from "../icon/icon"
import { List } from "../list/list"
import {
  QuerySuggestion,
  SearchResult,
  Suggestion,
  RenderSuggestion
} from "./search-box-types"
import { iconMap } from "./search-box-constants"
import styles from "./search-box.module.scss"

export const isSearchResult = (
  suggestion: Suggestion
): suggestion is SearchResult => (suggestion as SearchResult).type !== undefined

export const renderQuerySuggestion: RenderSuggestion<QuerySuggestion> = (
  { text, url },
  { query, isHighlighted }
) => (
  <Link
    to={url}
    className={classNames(styles.querySuggestion, {
      [styles.highlighted]: isHighlighted
    })}
  >
    <Highlight
      className={styles.highlight}
      pattern={query}
      text={text}
      firstOnly
    />
  </Link>
)

export const renderSearchResult: RenderSuggestion<SearchResult> = (
  suggestion,
  { query, isHighlighted }
) => {
  const { component, ...restSuggestion } = suggestion

  const suggestionProps = { isHighlighted, ...restSuggestion }

  if (component) {
    const Component = component

    if (suggestionProps.type === "podcastShow" && !suggestionProps.meta) {
      suggestionProps.meta = "podcast"
    }

    return (
      <List variant="results">
        <Component {...suggestionProps} />
      </List>
    )
  }

  return (
    <div
      className={classNames(styles.searchResult, {
        [styles.highlighted]: isHighlighted,
        [styles.disableHover]: isHighlighted === false
      })}
    >
      <h4 className={styles.heading}>
        <Highlight
          className={styles.highlight}
          pattern={query}
          text={suggestion.title || ""}
          firstOnly
        />
      </h4>
      <div className={styles.content}>
        <div className={styles.type}>{suggestion.type}</div>
        {suggestion.meta && (
          <div className={styles.meta}>{suggestion.meta}</div>
        )}
        {suggestion.type && (
          <Icon className={styles.icon} variant={iconMap[suggestion.type]} />
        )}
      </div>
    </div>
  )
}

export const renderSuggestion: RenderSuggestion<Suggestion> = (
  suggestion,
  params
) =>
  isSearchResult(suggestion)
    ? renderSearchResult(suggestion, params)
    : renderQuerySuggestion(suggestion, params)
