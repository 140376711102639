import React from "react"
import ReactMarkdown from "react-markdown"
import { FluidObject } from "gatsby-image"
import classNames from "classnames"
import BaseLayout from "./base-layout"
import styles from "./year-end-testimonials.module.scss"
import commonStyles from "./common-styles.module.scss"
import Testimonials from "@components/Testimonials/testimonials"

export interface YearEndTestimonialsProps {
  headline: string
  subhead?: string
  buttonText: string
  buttonLink: string
  quote1Text: string
  quote1Attribution: string
  quote1Image: FluidObject
  quote2Text: string
  quote2Attribution: string
  quote2Image: FluidObject
  quote3Text: string
  quote3Attribution: string
  quote3Image: FluidObject
}

export default function YearEndTestimonials(props: YearEndTestimonialsProps) {
  const {
    headline,
    subhead,
    buttonText,
    buttonLink,
    quote1Text,
    quote1Attribution,
    quote1Image,
    quote2Text,
    quote2Attribution,
    quote2Image,
    quote3Text,
    quote3Attribution,
    quote3Image
  } = props

  const reactMarkdownProps = {
    disallowedElements: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
    unwrapDisallowed: true
  }

  return (
    <div className={styles.testimonial}>
      <BaseLayout
        className={classNames(
          commonStyles.mediumWrapper,
          styles.topContent,
          !subhead && styles.topContentWOSubhead
        )}
        infoClassName={classNames(commonStyles.mediumInfo, styles.topInfo)}
        mediaClassName={commonStyles.mediumMediaWrapper}
        headline={
          <h2 className={classNames(commonStyles.title, styles.title)}>
            <ReactMarkdown {...reactMarkdownProps}>{headline}</ReactMarkdown>
          </h2>
        }
        subhead={
          subhead ? (
            <h3 className={classNames(commonStyles.subtitle, styles.subtitle)}>
              <ReactMarkdown {...reactMarkdownProps}>{subhead}</ReactMarkdown>
            </h3>
          ) : undefined
        }
        buttonLink={buttonLink}
        buttonText={buttonText}
      />
      <Testimonials
        testimonials={[
          {
            image: quote1Image,
            quote: quote1Text,
            attribution: quote1Attribution
          },
          {
            image: quote2Image,
            quote: quote2Text,
            attribution: quote2Attribution
          },
          {
            image: quote3Image,
            quote: quote3Text,
            attribution: quote3Attribution
          }
        ]}
      />
    </div>
  )
}
