import React, { forwardRef, PropsWithChildren } from "react"
import classNames from "classnames"
import { Tint } from "../tint/tint"
import styles from "./modal.module.scss"

export interface ModalOverlayProps {
  className?: string
  onClick: () => void
  onTintClick: () => void
  onTintHide: () => void
  withTint: boolean
  dismissable?: boolean
  fullscreenMobile?: boolean
}

export const ModalOverlay = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ModalOverlayProps>
>(
  (
    {
      children,
      className,
      onClick,
      onTintClick,
      onTintHide,
      withTint,
      dismissable = true,
      fullscreenMobile = true
    },
    forwardedRef
  ) => (
    <div
      className={classNames(styles.overlay, className, {
        [styles.forceWindowStyle]: !fullscreenMobile
      })}
      onClick={onClick}
    >
      {withTint && (
        <Tint
          className={styles.tint}
          ref={forwardedRef}
          onClick={onTintClick}
          onHide={onTintHide}
          dismissable={dismissable}
        />
      )}
      {children}
    </div>
  )
)

ModalOverlay.displayName = "ModalOverlay"
