import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "../link/link"
import { FooterGroupRow } from "./footer-group"
import styles from "./footer.module.scss"

const ViewAllEvents: FunctionComponent = () => (
  <FooterGroupRow>
    <Link className={styles.capsLink} to="/events">
      <FormattedMessage id="events-view-all" />
    </Link>
  </FooterGroupRow>
)

export const footerSectionAdditions: Record<string, FunctionComponent> = {
  Events: ViewAllEvents
}
