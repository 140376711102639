import { useEffect, useState, useCallback } from "react"

// 54 test broke when switching to the other script so keeping this one in for now

const useScript = (
  url: string,
  keepScriptTag = false,
  onUnmount?: () => void
): boolean => {
  const [isLoaded, setIsLoaded] = useState(false)

  const handleScriptLoad = useCallback(() => {
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    if (document.querySelector(`script[src="${url}"]`)) {
      setIsLoaded(true)

      return
    }

    if (url && !isLoaded) {
      const script = document.createElement("script")

      script.src = url
      script.defer = true
      script.onload = handleScriptLoad

      document.body.appendChild(script)

      return (): void => {
        if (!keepScriptTag) {
          document.body.removeChild(script)
        }

        script.onload = null
        onUnmount?.()
      }
    }
  }, [handleScriptLoad, isLoaded, keepScriptTag, onUnmount, url])

  return isLoaded
}

// Hook
function useScriptTwo(src: string, id?: string, keepScriptTag = false) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? "loading" : "idle")

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus("idle")

        return
      }

      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script: HTMLScriptElement | null = document.querySelector(
        `script[src="${src}"]`
      )

      if (!script) {
        // Create script
        script = document.createElement("script")
        if (id) script.id = id
        script.src = src
        script.async = true
        script.setAttribute("data-status", "loading")
        // Add script to document body
        document.body.appendChild(script)

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event: any) => {
          script?.setAttribute(
            "data-status",
            event.type === "load" ? "ready" : "error"
          )
        }

        script.addEventListener("load", setAttributeFromEvent)
        script.addEventListener("error", setAttributeFromEvent)
      } else {
        // Grab existing script status from attribute and set to state.
        const status = script.getAttribute("data-status") || "loading"

        setStatus(status)
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event: any) => {
        setStatus(event.type === "load" ? "ready" : "error")
      }

      // Add event listeners
      script.addEventListener("load", setStateFromEvent)
      script.addEventListener("error", setStateFromEvent)

      // Remove event listeners on cleanup
      return () => {
        if (!keepScriptTag && script) {
          document.body.removeChild(script)
        }

        if (script) {
          script.removeEventListener("load", setStateFromEvent)
          script.removeEventListener("error", setStateFromEvent)
        }
      }
    },
    [src, keepScriptTag, id] // Only re-run effect if script src changes
  )

  return status
}

export { useScript, useScriptTwo }
