import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Skeleton } from "../skeleton/skeleton"
import skeletonStyles from "../skeleton/skeleton.module.scss"
import { ListItem, ListItemProps } from "./list-item"
import { ListItemProductVariant } from "./list-item-product"
import styles from "./list.module.scss"
import { SearchResultsVariant } from "@features/search/components/search-results/search-results"

export interface ListItemProductSkeletonProps extends ListItemProps {
  variant?: ListItemProductVariant | SearchResultsVariant
}

export const ListItemProductSkeleton: FunctionComponent<
  ListItemProductSkeletonProps
> = ({ variant = "regular", className }) => {
  const applicableVariant = variant.includes("card") ? "card" : "regular"

  return (
    <ListItem
      className={classNames(
        styles.listItemProduct,
        className,
        styles[applicableVariant]
      )}
    >
      <div className={styles.content}>
        <div className={styles.author}>
          <Skeleton />
        </div>
        <h2 className={styles.header}>
          <Skeleton />
        </h2>
        <div className={styles.subheader}>
          <Skeleton />
        </div>
        <div className={styles.productPrices}>
          <Skeleton />
        </div>
        {applicableVariant === "card" && (
          <div className={styles.priceWithCartButtonDesktop}>
            <Skeleton width="3rem" />
          </div>
        )}
      </div>
      <div
        className={classNames(styles.side, {
          [styles.fixed]: applicableVariant === "card"
        })}
      >
        <div
          className={classNames(
            styles.image,
            styles.skeleton,
            skeletonStyles.background
          )}
        />
        <span className={styles.price}>
          <Skeleton />
        </span>
      </div>
    </ListItem>
  )
}
