import { Link, SocialLink } from "./footer-types"

export const phoneNumber = "800-435-4343"

export const chat = "https://lc.chat/now/7423931/"

export const email = "mailto:service@ligonier.org"

export const tel = `tel:+${phoneNumber.replace(/\./g, "-")}`

export const map = "https://goo.gl/maps/Qw5HqYkZNMjyoCESA"

export const legalLinks: Link[] = [
  {
    key: "footer-faq",
    url: "/faqs"
  },
  {
    key: "footer-terms-of-use",
    url: "/terms"
  },
  {
    key: "footer-copyright-policy",
    url: "/copyright-policy"
  },
  {
    key: "footer-privacy-policy",
    url: "/privacy-policy"
  }
]

export const socialLinks: SocialLink[] = [
  {
    text: "Facebook",
    icon: "20-facebook",
    key: "facebook",
    url: "http://www.facebook.com/Ligonier"
  },
  {
    text: "X",
    icon: "20-x-twitter",
    key: "twitter",
    url: "http://twitter.com/ligonier"
  },
  {
    text: "YouTube",
    icon: "20-youtube",
    key: "youtube",
    url: "http://www.youtube.com/LigonierMinistries"
  },
  {
    text: "Instagram",
    icon: "20-instagram",
    key: "instagram",
    url: "http://instagram.com/ligonier"
  },
  {
    text: "Linkedin",
    icon: "20-linkedin-no-circle",
    key: "linkedin",
    url: "https://www.linkedin.com/company/ligonier-ministries/"
  }
]
