export const removeOverflow = () => {
  if (document.body.style.overflow.length > 0) {
    document.body.style.overflow = "visible"
  }
}

export const addOverflowHidden = () => {
  if (document.body.style.overflow !== "hidden") {
    document.body.style.overflow = "hidden"
  }
}
