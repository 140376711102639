import { RelatedProduct } from "../../../types/related-product"
import { ProductDetails } from "../store-types"
import { Teacher } from "../../../types/teacher"

export const mapProductDetailsToRelatedProduct = (
  productDetails: ProductDetails
): RelatedProduct => ({
  image: productDetails.activeVariant.image || productDetails.image || "",
  author: (productDetails.teachers.length
    ? productDetails.teachers[0]
    : undefined) as Teacher | undefined,
  description: productDetails.description,
  compareAtPrice: productDetails.activeVariant.compareAtPrice || "",
  price: productDetails.activeVariant.price || "",
  handle: productDetails.handle,
  link: productDetails.link || "",
  title: productDetails.title,
  type: productDetails.type || "",
  format: productDetails.productInfo?.format
})
