import { AudioEventProps } from "../../core/segment/types"
import { audioSessionId } from "./audio"

export const getAudioPlayerState = (
  player: jwplayer.JWPlayer
): Pick<
  AudioEventProps,
  "session_id" | "position" | "total_length" | "percent_complete"
> => {
  const position = Math.round(player.getPosition())
  const total_length = Math.round(player.getDuration())
  const percent_complete = `${
    position === 0 ? 0 : Math.round((position / total_length) * 100)
  }%`

  return {
    session_id: audioSessionId,
    position,
    total_length,
    percent_complete
  }
}

export const formatTime = (timeInSeconds: number): string => {
  const roundedTime = Math.round(timeInSeconds)
  const minutes = Math.floor(roundedTime / 60)
  const seconds = roundedTime % 60

  return [`${minutes}`.padStart(2, "0"), `${seconds}`.padStart(2, "0")].join(
    ":"
  )
}
