export interface FormatPriceParams {
  forceShowCents?: boolean
  showDollarSign?: boolean
}

export const formatPrice = (
  value: string,
  params?: FormatPriceParams
): string => {
  const { forceShowCents = true, showDollarSign = true } = params || {}

  const valueAsFloat = parseFloat(value)

  // Return empty string if number couldn't be parsed from input (i.e. is NaN)
  if (!valueAsFloat && valueAsFloat !== 0) return ""

  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }

  // Hide decimal places for whole numbers unless forceShowCents === true
  if (valueAsFloat % 1 === 0 && !forceShowCents) {
    options.minimumFractionDigits = 0
  }

  // Use currency formatting (i.e. dollar sign) if showDollarSign === true
  if (showDollarSign) {
    options.style = "currency"
    options.currency = "USD"
  }

  return valueAsFloat.toLocaleString("en-US", options)
}
